.fortiphish_block {
    margin-top: 25px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 26px;

    ul,
    li {
        list-style: none;
    }

    .ant-empty-footer {
        .ant-btn {
            margin: auto;
            display: flex;
            align-items: center;
        }
    }

    .chart_title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .anticon {
            color: rgba(0, 0, 0, 0.45);
        }
    }

    .ant-spin {
        display: block;
    }

    .head_bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        .date_range {
            display: flex;
            align-items: center;

            > span {
                margin-right: 14px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.35);
            }

            .date_range_selector {
                display: inline-block;
            }
        }

        .fortiphish_nav_btn {
            margin-right: 1rem;
            border-radius: 6px;
            height: 32px;
            padding: 0 15px;
            display: flex;
            align-items: center;
        }
    }

    .status_wrap {
        padding-top: 25px;
        border-top: none;
    }

    .status_list {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            flex: 1;
            display: flex;
            padding: 8px 13px;
            justify-content: center;
            min-width: 190px;

            @media screen and (max-width: 992px) {
                justify-content: left;
            }

            &:not(:last-child) {
                border-right: 1px solid #ececee;
            }
        }

        .status_title,
        .status_percentage {
            font-size: 14px;
            line-height: 16px;
            color: #6a6a6a;
        }

        .status_num {
            font-size: 18px;
            line-height: 21px;
            color: #15192c;
            font-weight: 500;
        }

        .icon_wrap {
            width: 57px;
            height: 57px;
            max-width: 57px;
            background: #e9e9e9;
            opacity: 0.65;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 11px;
            flex: 1;

            .anticon {
                color: #130f26;
                font-size: 22px;
            }

            &.delivered {
                background: #48d5974d;
            }

            &.opened {
                background: #37d1f34d;
            }

            &.clicked {
                background: #7b54f34d;
            }

            &.submitted {
                background: #f03e264d;
            }

            &.reported {
                background: #5de36a4d;
            }
        }
    }

    .chart_container {
        margin-top: 55px;
        display: flex;

        @media screen and (max-width: 1200px) {
            flex-wrap: wrap;
            justify-content: center;

            .bar_chart {
                margin-bottom: 15px;
                box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
            }

            .pie_chart {
                max-width: inherit !important;

                .chart_wrap {
                    max-width: 400px;
                }
            }

            > .chart_member {
                flex: auto !important;
                width: 100%;
            }
        }

        .chart_wrap {
            margin: 20px auto;
            width: 100%;

            > canvas {
                width: 100% !important;
                max-width: 800px;
                margin: 0 auto;
            }
        }

        > .chart_member {
            flex: 1;
            padding: 20px 25px;
            border: 1px solid rgba(0, 0, 0, 0.06);
            border-radius: 2px;
        }

        .bar_chart {
            margin-right: 18px;
            flex: 1;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 767px) {
                .chart_wrap {
                    margin: 20px auto;
                }
            }
        }

        .pie_chart {
            max-width: 400px;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
        }

        .bar_chart_head {
            .status_checkboxes {
                display: flex;
                font-size: 14px;
                line-height: 22px;

                li {
                    flex: 1;
                }
            }
        }
    }
}
