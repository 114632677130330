#onboarding-widget {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    font-family: Roboto, sans-serif;

    .fixed-button {
        background-color: var(--button, #1890FF);
        color: #FFFFFF;
        border: none;
        outline: none;
        position: fixed;
        bottom: 0;
        right: 40px;
        width: 177px;
        padding: 9px 0;
        margin-bottom: 40px;
        border-radius: 50px;
        display: flex;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        align-items: center;

        &:hover {
            text-decoration: none;
        }

        &.hide {
            display: none;
        }

        span {
            padding-left: 4.41px;
            margin-left: 0;

            &.anticon-bulb {
                padding-left: 10.32px;
                height: 18px;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        a {
            background: transparent;
            border: none;
            cursor: pointer;
            align-items: center;
            display: flex;
            padding-left: 6.31px;

            .anticon-close {
                margin-left: 0;
                padding-left: 0;

                svg {
                    width: 16px;
                    height: 16px;
                    color: rgba(224, 224, 224, 0.85);
                }
            }
        }
    }
}

.user-tour-drawer {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;

    &.ant-drawer-right {
        &.ant-drawer-open {
            &.no-mask {
                transform: translate(1px, 60px);
            }
        }
    }

    .tour-progress-bar {
        margin-bottom: 0;
        margin-top: -35px;
        z-index: 8;
    }

    .tour-menu {
        margin-top: -5px;
        z-index: 7;
    }

    .anticon-check-circle {
        color: #DFE1E5;
        font-size: 21.88px;

        &.completed {
            color: #5DB082;
        }
    }

    .ant-drawer-header {
        background: url('https://res.cloudinary.com/dx55oi3py/image/upload/v1639512587/infosec/quick_start.png');
        text-align: center;
        padding: 44.5px 24px;
        z-index: 5;

        .ant-drawer-title {
            font-size: 24px;
            line-height: 22px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;

            .anticon-bulb {
                vertical-align: middle;
                margin-right: 12.33px;
            }

        }

        .ant-drawer-close {
            padding-top: 12px;
            padding-right: 9px;
            position: absolute;
            top: 0;
            right: 0;

            .anticon-shrink {
                font-size: 18px;
                color: #FFFFFF;
                stroke: #FFFFFF;
                stroke-width: 30px;
            }
        }
    }

    .quickstartmenu {
        margin: 0;

        .quickstartsubmenu-text-title {
            font-size: 16px;
        }

        .ant-menu-submenu-title {
            font-size: 15px;
            line-height: 120%;
            color: #000000;
            height: 47px;
            border-bottom: 1px solid #EBECF0;
            margin: 0 2px 5px 0;
            width: 100%;

            &:hover {
                text-decoration: underline;
                cursor: pointer;

                .ant-menu-submenu-arrow {
                    color: #000;
                }
            }


            .ant-menu-submenu-arrow {
                &::before, &::after {
                    width: 7px;
                }
            }
        }

        ul {
            background: transparent;
            margin-top: 12.58px;
        }

        &.ant-menu-submenu-open {
            border-bottom: 1px solid #EBECF0;

            &:last-child {
                border-bottom: none;

                .ant-menu-submenu-title {
                    margin-bottom: 0;
                }
            }
        }
    }

    ul.ant-menu-sub > li.ant-menu-item {
        height: auto;
        align-items: start;
        display: block;
        padding-left: 10px !important;
        width: 100%;
        margin: 0;
        pointer-events: none;

        .anticon {
            display: flex;
            padding-left: 26px;
            font-size: 15px;
            color: #DFE1E5;
            position: relative;
            top: 2px;

            svg {
                height: 12px;
            }

            &.completed {
                color: #5DB082;
            }
        }

        .ant-menu-title-content {
            display: flex;
            margin-left: 16px;

            .quickstartsubmenu-text {
                margin-left: 10px;
                font-size: 15px;
                line-height: 120%;
                color: rgba(0, 0, 0, 0.85);

                h4 {
                    position: absolute;
                    top: 0;
                    padding-left: 30px;
                    margin-top: 0;
                    font-style: normal;
                    font-weight: 500 !important;
                    font-size: 15px;
                    line-height: 120%;

                    &.no-heading {
                        display: none;
                    }
                }

                p {
                    margin-top: 12px;
                    margin-bottom: 18.82px;
                    white-space: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 120%;
                    color: rgba(0, 0, 0, 0.7);


                    &.no-heading {
                        margin-top: 0;
                        margin-bottom: 10px;
                    }
                }

            }
        }

        &.ant-menu-item-selected {
            background: none;

            &::after {
                content: none;
            }
        }
    }

    .completed-onboarding {
        align-items: flex-start;
        margin-top: 13px;
        height: 47px;
        width: 100%;
        background-color: #FFFFFF;

        p {
            font-size: 15px;
            line-height: 120%;
            color: #000000;
            margin-bottom: 0;

            &:first-child {
                margin-top: 2px;
                font-weight: 500;
            }

            &:nth-child(2) {
                margin-top: 6px;
                text-decoration: underline;
            }
        }
    }

    .ant-drawer-body {
        margin-top: -44px;
        background: #F4F5F7;
        border-radius: 5px;
        padding: 24px 30px 0;
        overflow: hidden;

        > .ant-menu {
            z-index: 6;
            position: relative;
            height: 100%;
            overflow: auto;
            border: none;
            margin-top: -8px;

            &::after {
                content: '';
                display: block;
                margin-bottom: 15px;
            }

            .ant-menu-item, .ant-menu-submenu {
                background: transparent;

                &:hover {
                    background: transparent;
                }

                .ant-menu-sub {
                    padding: 12px 0;
                }

                .ant-menu-submenu-title {
                    background: transparent;
                    color: #000000;

                    &:hover {
                        background: transparent;
                        text-decoration: underline;
                    }
                }

                .anticon-check-circle {
                    color: #DFE1E5;
                    font-size: 21.88px;

                    &.completed {
                        color: #5DB082;
                    }
                }

                .showme_link {
                    color: #1890ff;

                    &:hover {
                        text-decoration: underline;
                    }

                    span {
                        pointer-events: auto;
                        color: #1890ff;
                    }
                }
            }
        }
    }

    .video-button {
        border: none;
        width: 100%;
        font-size: 15px;
        line-height: 120%;
        color: #000000;
        height: 47px;
        padding: 13px 0 9px;
        background: transparent;
        justify-content: left;

        &:hover {
            span {
                text-decoration: underline;
                color: #000000;
            }
        }

        span {
            padding-top: 6px;
            font-size: 15px;
            line-height: 120%;
            color: #000000;
            font-weight: 500;
            padding-bottom: 9px;

        }

        &::after {
            content: none;
        }

        .anticon-check-circle {
            color: #DFE1E5;
            font-size: 21.88px;

            &.completed {
                color: #5DB082;
            }
        }
    }

    .ant-drawer-footer {
        display: flex;
        justify-content: space-between;
        background: #F4F5F7;
        border-radius: 5px;
        border: none;
        padding: 10px 30px 28px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.7);

        a {
            color: rgba(0, 0, 0, 0.7);
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;

            .anticon {
                margin: 0 10px 0 0;
                font-size: 18px;

                svg {
                    height: 14px;
                }
            }
        }

        button {
            background: transparent;
            border: none;
            cursor: pointer;
            font-size: 14px;
            line-height: 22px;
            color: var(--primary, #1890FF);
            box-shadow: 0 0 0;
        }
    }

    .showme_link {

        &:hover {
            text-decoration: underline;
        }

        span {
            pointer-events: auto;
            color: #1890ff;
        }
    }
}

.onboarding-video-modal {
    font-family: Roboto, sans-serif;
    font-style: normal;

    .ant-modal-content {
        background: #F8FAFC;

        .ant-modal-body {
            padding: 20px 20px 0;

            .onboarding-modal-text {
                text-align: center;
                font-family: Roboto, sans-serif;

                .player-wrap {
                    display: flex;
                    justify-content: center;
                    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.15));
                }

                h3 {
                    font-weight: 500;
                    font-size: 25px;
                    line-height: 29px;
                    color: #303134;
                    padding-top: 20px;
                    padding-bottom: 20.5px;
                    margin: 0;
                }

                p {
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(48, 49, 52, 0.8);
                    margin: 0 0 28.19px 0;
                }

                iframe {
                    width: 514.25px;
                    height: 311.47px;
                    background: rgba(0, 0, 0, 0.25);
                }
            }
        }

        .ant-modal-footer {
            justify-content: center;

            .ant-btn {
                font-family: Roboto, sans-serif;
                margin: 0;
                font-size: 14px;
                line-height: 22px;
                text-align: center;

                // prevent btn styles have been overwritten
                &.take-the-tour-btn {
                    margin-right: 16px;
                }
            }
        }
    }
}

.ant-tour .ant-tour-content {
    .ant-tour-buttons {
        display: flex;

        > button {
            padding: 6px 16px;
            height: 32px;
            display: flex;
            min-width: 60px;
            border-radius: 6px;
        }
    }

    .ant-tour-footer {
        padding: 8px 32px 16px;
    }

    .ant-tour-close {
        font-size: 16px;
        font-weight: bold;
        top: 10px;
        right: 10px;
    }

    .ant-tour-description {
        max-width: 95%;
        margin: 15px;
    }
}
