.profile_modal_wrap {
    padding: 20px 0 30px;

    .avatar_wrap {
        text-align: center;
        margin-bottom: 28px;

        .ant-avatar {
            background: #E2E8F0;

            span {
                color: #000;
                font-size: 28px;
            }
        }
    }

    .form_wrap {
        max-width: 600px;
        margin: 0 auto 0 -20px;

        .ant-form-item {
            .ant-form-item-label {
                white-space: normal;
            }
        }
    }

    .default {
        width: 100%;
    }

    .ant-btn {
        margin-top: 10px;
    }
}

.header {
    .avatar {
        .ant-badge {
            display: none;
        }

        .help-action {
            >a {
                color: rgba(0, 0, 0, 0.88);

                .anticon-question-circle {
                    font-size: 22px;
                    margin: 0 31px 0 0;
                }
            }
        }
    }
}

/* CustomMenu.css */

.service-header-menu, .support-header-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    color: #666;
    font-family: Lato, sans-serif;
    cursor: pointer;

    &:hover {
        color: #666;
        text-decoration: none;
    }

    .service_icon, .support_icon {
        width: 16px;
        display: flex;
        align-items: center;
        margin-right: 5px;

        svg {
            width: 16px;
        }
    }

    .anticon-caret-down {
        display: flex;
        align-items: center;
        margin-left: 15px;
    }
}

.support-header-menu {
    margin-left: 65px;
}

.dropdown-portal {
    .dropdown-content {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, .2);
        box-sizing: border-box;
        color: #535353;
        display: block;
        font-size: 13px;
        font-weight: 400;
        opacity: 1;
        text-align: center;
        transition: all 2s ease 2s;
        white-space: nowrap;
        z-index: 99999999;
        position: absolute;

        @media screen and (max-width: 1499px) {
            max-width: 62vw;
            overflow: auto;
        }

        @include desktop {
            overflow: auto;
            max-width: 60vw;
            max-height: 75vh;
        }

        @include tablet {
            overflow: auto;
            max-width: 40vw;
            max-height: 80vh;
        }

        @include mobile {
            overflow: auto;
            max-width: 38vw;
            max-height: 50vh;
        }

        .portal_col {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(2, 1fr);
            padding: 35px 30px;
            position: relative;
            text-align: left;
            vertical-align: top;
            white-space: nowrap;

            &::before {
                border: 6px solid transparent;
                border-bottom-color: #666;
                content: "";
                display: block;
                height: 0;
                left: 87.5px;
                position: absolute;
                top: -13px;
                width: 0;
            }

            .right-col {
                border-left: 1px dashed #dde1ea;
                margin-left: 15px;
                padding-left: 30px;
            }

            a {
                h6:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            h6 {
                clear: both;
                color: #a0a9b8;
                font-size: 12.7px;
                font-weight: 400;
                letter-spacing: .2rem;
                margin: 0 0 20px;
                padding: 0;
                position: relative;
                text-transform: uppercase;
            }

            .cloud_management {
                h6 {
                    border-top: 1px dashed #dde1ea;
                    margin-top: 25px;
                    padding-top: 25px;
                }
            }

            .list-container {
                display: grid;
                gap: 6px;
                grid-template-columns: repeat(2, 230px);

                .list-item {
                    > a {
                        align-items: center;
                        border-radius: 0;
                        box-shadow: none;
                        color: #535353;
                        display: flex;
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: .01em;
                        padding-right: 15px;
                        text-decoration: none;
                        transition: all .2s;

                        &[disabled] {
                            color: #e1e1e1;

                            &:hover {
                                span {
                                    text-decoration: none;
                                }
                            }

                            img {
                                opacity: 0.15;
                            }
                        }

                        &:hover {
                            span {
                                text-decoration: underline;
                            }
                        }

                        img {
                            box-sizing: border-box;
                            height: auto;
                            margin-right: 10px;
                            position: relative;
                            vertical-align: middle;
                            width: 16px;
                        }

                        .menu-name {
                            font-family: Lato, sans-serif;
                            font-size: 11.33px;
                            font-weight: 400;
                            letter-spacing: .02em;
                        }
                    }
                }
            }

            .show-more-option {
                button {
                    padding-left: 0;

                    &:hover {
                        text-decoration: underline;
                    }

                    > span {
                        font-size: 13px;
                        align-items: center;
                        color: #2764c1;
                        cursor: pointer;
                        display: flex;
                        font-weight: 600;
                        gap: 4px;
                        margin-top: 10px;
                    }

                    .anticon {
                        font-size: 14px;
                        position: relative;
                        top: 1px;
                    }
                }
            }

            &.support_col {
                grid-template-columns: repeat(3, 1fr);

                &::before {
                    left: 86px;
                }

                .list-header {
                    font-family: Lato, sans-serif;
                    color: #8099b4;
                    cursor: pointer;
                    font-size: 12.7px;
                    font-weight: 400;
                    letter-spacing: 0.2rem;
                }

                .list-item {
                    margin-bottom: 6px;

                    > a {
                        white-space: nowrap;

                        .menu-name {
                            white-space: nowrap;
                        }
                    }
                }

                .downloads-container {
                    .list-container {
                        display: block;
                    }
                }

                .resources-container {
                    border-left: 1px dashed #dde1ea;
                    padding-left: 30px;
                }

                .forticare-group-container {
                    border-left: 1px dashed #dde1ea;
                    padding-left: 30px;

                    .forticare-container {
                        border-top: 1px dashed #dde1ea;
                        margin-top: 20px;
                        padding-top: 20px;
                    }
                }
            }
        }
    }
}

.profile_dropdown {
    min-width: 170px !important;
    position: sticky;

    li.ant-dropdown-menu-item {
        padding: 9px 12px;

        &:last-child {
            border-top: 1px solid #D9D9D9;
            border-radius: 0;

            .ant-dropdown-menu-title-content {
                min-height: 30px;
            }
        }
    }

    .ant-dropdown-menu-title-content {
        position: relative;
    }

    .ant-dropdown-menu-title-content, .ant-dropdown-menu-title-content > div, .ant-dropdown-menu-title-content > a {
        display: flex;
        align-items: center;
    }

    .anticon {
        margin-right: 9px;
    }
}

.adminprofile-menu {
    .adminprofile-dropdown-content {
        position: absolute;
        left: auto;
        right: -10px;
        display: flex;
        align-items: center;
        background-color: #fff;
        border: none;
        border-radius: 0;
        box-shadow: 0 4px 20px rgba(0, 0, 0, .2);
        box-sizing: border-box;
        color: #535353;
        font-size: 13px;
        font-weight: 400;
        min-width: 255px;
        opacity: 1;
        transition: all .2s ease 0s;
        white-space: nowrap;
        z-index: 99999999;

        .dropdown-list-container {
            display: flex;
            margin: 0;
            min-width: 0;

            .account-overview-section {
                border-right: 1px solid #dee2eb;
                max-width: 300px;
                min-width: 256px;
                padding-left: 25px;
                padding-right: 25px;

                .empty_user_display_info {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }

                .account-list {
                    border-bottom: 1px dashed #d7dbe4;
                    color: #3d5379;
                    cursor: default;
                    padding: 8px 0 11px;

                    &:first-child {
                        padding-top: 25px;
                    }

                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 25px;
                    }

                    .dname {
                        color: #a0a9b8;
                        display: block;
                        font-size: 9px;
                        font-weight: 400;
                        letter-spacing: .065em;
                        text-transform: uppercase;
                    }

                    .dvalue {
                        color: #3d5379;
                        display: block;
                        font-size: 13px;
                        line-height: 100%;
                        white-space: pre-wrap;
                        word-break: break-word;
                    }
                }
            }

            .iconed-list-section {
                .forticloud-item {
                    border: none;
                    border-radius: 0;
                    color: #3d5379;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    letter-spacing: .025em;
                    min-width: 255px;
                    padding: 7px 25px;
                    text-align: left;
                    width: auto;

                    &:first-child {
                        padding-top: 25px;
                    }

                    &:last-child {
                        padding-bottom: 25px;
                    }

                    &:hover {
                        background: rgba(57, 161, 255, .08);
                        border: none;
                        text-decoration: none;
                    }

                    img {
                        height: auto;
                        margin-right: 7px;
                        opacity: .7;
                        vertical-align: middle;
                        width: 19px;

                        &[alt="Logout"] {
                            margin-left: 2px;
                        }
                    }

                    .anticon {
                        margin-right: 7px;
                        font-size: 16px;
                        margin-left: 2px;
                    }
                }
            }
        }
    }
}
