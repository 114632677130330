/**
 ------------------------------------------------------------------------------------
 --------- The mixin file that saves time and makes adding prefixes easier ----------
 ------------------------------------------------------------------------------------
 */

/**
 *  some responsive variables is required from ./_variables.scss file
 *  $grid_breakpoints_sm: 575.98px;
 *  $grid_breakpoints_md: 767.98px;
 *  $grid_breakpoints_lg: 991.98px;
 *  $grid_breakpoints_xl: 1199.98px;
 */

//-------------------- Responsive --------------------//
@mixin desktop {
    @media (max-width: #{$grid_breakpoints_xl}) {
        /* For less than 1199.98px */
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$grid_breakpoints_lg}) {
        /* For less than 991.98px */
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$grid_breakpoints_md}) {
        /* For less than 767.98px */
        @content;
    }
}

@mixin extra-small-mobile {
    @media (max-width: #{$grid_breakpoints_sm}) {
        /* For less than 575.98px */
        @content;
    }
}

//-------------------- Reverse --------------------//
@mixin _desktop {
    @media (min-width: #{$grid_breakpoints_xl}) {
        /* For less than 1199.98px */
        @content;
    }
}

@mixin _tablet {
    @media (min-width: #{$grid_breakpoints_lg}) {
        /* For less than 991.98px */
        @content;
    }
}

@mixin _mobile {
    @media (min-width: #{$grid_breakpoints_md}) {
        /* For less than 767.98px */
        @content;
    }
}

@mixin _extra-small-mobile {
    @media (min-width: #{$grid_breakpoints_sm}) {
        /* For less than 575.98px */
        @content;
    }
}

//--------------------  Center vertically and/or horizontally --------------------//
@mixin absolute-center($xy:xy) {
    @if $xy == xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        @include transform(translateX(-50%) translateY(-50%));
    } @else if $xy == x {
        left: 50%;
        right: auto;
        @include transform(translateX(-50%));
    } @else if $xy == y {
        top: 50%;
        bottom: auto;
        @include transform(translateY(-50%));
    }
}

@mixin flex-center() {
    display: flex;
    align-items: center;
    justify-content: center;
}

//--------------------  Add more if necessary --------------------//