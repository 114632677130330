.not_found_page, .page_not_found {
    height: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;

    .container {
        text-align: center;
    }

    img {
        margin-bottom: 2rem;
    }
}

.page_not_found {
    font-family: Inter, sans-serif;
    background-color: #F7F9FB;

    .container {
        background-color: #FFFFFF;
        width: auto;
        min-width: 700px;
        padding: 68px 85px 115px;
        h1 {
            font-family: Inter, sans-serif;
            font-size: 40px;
            font-weight: 600;
            line-height: 120%;
            letter-spacing: 0.8px;
        }
        p {
            font-family: Inter, sans-serif;
            margin: 24px 0;
        }
        button {
            border-radius: 6px;
        }
    }
}