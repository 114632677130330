$ftnt_red: #da291c;
$ftnt_green: #58a55c;
$ftnt_blue: #1890ff;

#campaign_table_container {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 22px 0;

    .mobile-column {
        display: none;
    }
}

.ant-table-pagination.ant-pagination {
    margin: 16px 16px 0;
}

.training_overview_panel {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 20px 28px 32px;
    margin-bottom: 26px;

    .info_card_container {
        padding: 7px;
    }

    .info_card {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
        border-radius: 2px;
        margin-bottom: 0;
        min-height: 266px;
        height: 100%;
        padding: 24px;
        display: flex;
        flex-flow: column;

        @media screen and (min-width: 1200px) and (max-width: 1520px) {
            //padding-right: 0; // joey: break chart_wrap location

            .chart_wrap {
                //align-self: start; // joey: break chart_wrap location
            }

            .info_item_desc_list {
                //display: block; // joey did

                li {
                    min-width: 85px;
                    float: left;

                    &.overdue {
                        //margin-top: 10px; joey: why overdue has a margintop?
                    }
                }
            }
        }
    }

    .chart_wrap {
        flex: 1;
        //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        height: 155px;
        width: 155px;
        align-self: center;

        &.completion-pie-chart {
            border-bottom: none;
        }
    }

    .pie_chart_wrap {
        position: relative;

        .pie_chart_text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 28px;
            font-weight: bold;

            > div {
                letter-spacing: 2px;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: normal;
            }
        }

        canvas {
            position: relative;
            z-index: 1;
        }
    }

    .info_item_title {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .info_item_text {
        font-size: 30px;
        line-height: 38px;
        color: #1890ff;
        font-weight: 600;
    }

    .info_item_desc {
        font-size: 14px;
        line-height: 22px;
        margin: 30px 0 0;
    }

    .info_item_desc_list {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        list-style: none;
        padding: 0;
        margin: 23px 0 0 15px;
        font-size: 12px;
        line-height: 16px;
        justify-content: space-between;

        li {
            position: relative;

            &:before {
                content: "";
                width: 10px;
                height: 10px;
                display: inline-block;
                position: absolute;
                border-radius: 50%;
                top: 3px;
                left: -1rem;
                background-color: transparent;
            }

            &.completed:before {
                background: #58a55c;
            }

            &.inprogress:before {
                background: #f1be42;
            }

            &.notstarted:before {
                background: #cfd8dc;
            }

            &.overdue:before {
                background: #ff6361;
            }
        }
    }

    .training-detail-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px;
    }
}

#campaign_table_header {
    .table-title {
        line-height: 80px;
        font-size: 20px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        margin-left: 24px;
    }

    .filter-section {
        display: flex;
        align-items: center;
        gap: 12px;

        .ant-select-selector {
            padding: 0 4px;
            height: 40px;
        }

        .filter-dept {
            margin-right: 21px;

            .ant-select-selector {
                padding: 0 11px;
            }
        }

        .ant-select-clear {
            display: flex;
            align-items: center;
        }
    }

    .table-selector-container {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;

        .campaign-detail-title {
            font-size: 20px;
            padding: 0 16px;
        }

        .table-filters {
            margin-right: 16px;
        }

        .table-selector {
            display: inline-block;

            .table-selector-title {
                margin-right: 12px;
            }

            .table-selector-element {
                width: 200px;
            }
        }
    }
}

.dashboard-page .ant-table-wrapper {
    .progress-icons-container {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: rgb(101, 112, 141);
        margin-left: 10px;

        .progress-icon {
            display: inline-block;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #65708d;
            margin-left: 8px;

            .icon-dot {
                width: 12px;
                height: 10px;
                display: inline-block;
                border-radius: 3px;
                margin-right: 7px;

                &.completed-icon-dot {
                    background: $ftnt_green;
                }

                &.in-progress-icon-icon-dot {
                    background: repeating-linear-gradient(50deg, #f1be42, #f1be42 5px, #fff 0, #fff 6px);
                }

                &.not-started-icon-icon-dot {
                    background: #cfd8dc;
                }

                &.overdue-icon-icon-dot {
                    background: #ff6361;
                }
            }
        }
    }

    &.campaign-table-content {
        .ant-table-row-expand-icon-cell {
            border: none;

            button.ant-table-row-expand-icon {
                border: 1px solid #fff;
                box-shadow: none;
            }

        }
    }

    .ant-table-row-expand-icon-cell {
        border-right: 1px solid rgb(240, 240, 240);
    }
}

#campaign_table_container {
    #campaign_table_main {
        .ant-pagination {
            margin: 16px 20px;
        }

        .ant-table-wrapper {
            padding: 0;
            margin: 0;
        }

        .ant-table-cell {
            .status {
                display: flex;
                align-items: center;
                gap: 8px;

                .status-icon {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #cfd8dc;

                    &.ended {
                        background-color: #2979F2;
                    }

                    &.ongoing {
                        background-color: #52C41A;
                    }

                    &.completed {
                        background-color: $ftnt_green;
                    }
                }


            }
            .ant-table-row-expand-icon {
                border-radius: 0;
            }

            &.ant-table-column-sort {
                background: #fff;

                &.ant-table-column-has-sorters {
                    background: #fafafa;

                    &:hover {
                        background: #f5f5f5;
                    }
                }
            }
        }

        .ant-table-expanded-row {
            > td.ant-table-cell {
                padding: 16px 0;

                .expanded-row {
                    margin-left: 50px;
                }
            }

            .ant-table {
                margin-left: 0;
                padding-left: 0;
                background-color: #fafafa;
            }


            > td.ant-table-cell[colspan="6"] {
                padding-left: 0;

                .ant-table-wrapper {
                    .ant-table {
                        padding: 0;
                        margin-left: 0;
                        border-left: 2px solid #1890ff;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 993px) {
    #campaign_table_container {
        .mobile-column {
            width: 100%;
            display: table-cell;
        }

        .ant-table-row-expand-icon-cell,
        .ant-table-expanded-row {
            display: none;
        }

        #campaign_table_header {
            .table-title {
                margin-left: 0;
                line-height: 24px;
            }

            .table-selector-container {
                line-height: 30px;
                width: 100%;
                margin: 0 0 30px 0;

                .table-filters {
                    display: block;
                    margin-bottom: 8px;
                }

                .table-update-date-text {
                    display: block;
                    margin: 15px 0 8px 0;
                }

                .table-selector {
                    display: block;

                    .table-selector-element {
                        width: 100%;
                    }
                }
            }
        }

        #campaign_table_main {
            .ant-table {
                .ant-table-content {
                    border: 1px solid #efefef;

                    .ant-table-thead {
                        .ant-table-cell {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 22px;
                            color: rgba(0, 0, 0, 0.85);
                            padding: 16px;
                            background-color: #fafafa;
                        }
                    }

                    .ant-table-cell {
                        padding: 0;
                        border-bottom: 1px solid #efefef;

                        .ant-collapse {
                            background-color: transparent;
                            border: none;

                            .ant-collapse-item {
                                border: none;

                                .ant-collapse-header {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 22px;
                                    color: rgba(0, 0, 0, 0.85);
                                }

                                .ant-collapse-content {
                                    background-color: #fbfbfb;

                                    .row {
                                        display: flex;
                                    }

                                    .column {
                                        flex: 50%;
                                        padding: 16px;

                                        p {
                                            font-style: normal;
                                            font-weight: normal;
                                            line-height: 22px;
                                            color: rgba(0, 0, 0, 0.85);
                                            margin: 0;
                                        }

                                        &.column-title {
                                            p {
                                                font-size: 12px;
                                            }
                                        }

                                        &.column-value {
                                            text-align: left;
                                            flex: inherit;
                                            max-width: 151px;
                                            line-height: 14px;

                                            p {
                                                font-size: 14px;
                                            }

                                            .progress-data {
                                                display: inline-block;
                                                font-style: normal;
                                                font-weight: 400;
                                                font-size: 12px;
                                                line-height: 14px;
                                                color: #65708d;

                                                .progress-icon {
                                                    width: 10px;
                                                    height: 7px;
                                                    display: inline-block;
                                                    border-radius: 3px;
                                                    margin-right: 10px;

                                                    &.completed-icon {
                                                        background-color: $ftnt_green;
                                                    }

                                                    &.in-progress-icon {
                                                        background: repeating-linear-gradient(50deg, #f1be42, #f1be42 5px, #fff 0, #fff 6px);
                                                    }

                                                    &.not-started-icon {
                                                        background-color: #cfd8dc;
                                                    }
                                                }

                                                .progress-percentage {
                                                    display: inherit;
                                                    text-align: right;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .progress-icons-container {
            margin: 0;
            font-size: 18px;
            line-height: 24px;

            .progress-icon {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

.ant-empty-footer {
    .ant-btn-primary {
        color: #fff;
        background: #1890ff;
        border-radius: 6px;
        border: 1px solid #1890ff;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);

        &:hover {
            color: #1890ff;
            background: #fff;
        }

        > span {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
}

#page-my-index {
    #campaign_table_container {
        .ant-table-container {
            td.ant-table-cell {
                > a {
                    color: rgba(0, 0, 0, 0.85);
                }
            }

            .ant-table-expanded-row {
                .ant-table-tbody {
                    tr {
                        td.ant-table-cell:nth-child(2) {
                            @media screen and (max-width: 1550px) {
                                padding-left: 23px;
                            }
                        }
                    }
                }
            }
        }
    }
}

