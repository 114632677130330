.campaign-module-selector {
    font-family: Inter, sans-serif;
    container-type: inline-size;
    margin: 24px 0;

    .campaign-module-selector-label {
        margin-bottom: 10px;
    }

    .campaign-module-controls {
        display: flex;
        gap: 16px;
        justify-content: space-between;
    }

    .campaign-module-options {
        //display: flex;
        //flex-wrap: wrap;
        //gap: 13px 11px;
        //align-content: stretch;
        display: grid;
        grid-auto-rows: 1fr;
        // grid-template-columns: repeat(3, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(270px, 270px));
        gap: 13px 11px;
        max-height: 780px;
        overflow-y: scroll;
        margin-top: 24px;

        .option-card {
            color: rgba(0, 0, 0, 0.6);
            //width: 270px;
            border: 1px solid #D9D9D9;
            border-left: 6px solid #D9D9D9;
            border-radius: 6px;
            padding: 24px 20px 24px 16px;
            position: relative;
            overflow-wrap: break-word;
            transition: 0.3s ease-in-out border;
            box-sizing: content-box;

            &:not(.disabled) {
                &:hover {
                    cursor: pointer;
                    border: 6px solid rgba(24, 144, 255, 0.3);

                    .view-detail > button {
                        opacity: 1;
                    }
                }

                &.selected {
                    border: 6px solid #1890FF;
                    padding: 19px 15px 19px 16px;

                    .option-check-icon {
                        right: 4px;
                        top: 4px;

                        svg {
                            color: #1890FF;
                        }
                    }
                }
            }

            &.selected {
                border: 6px solid #949597;
                padding: 19px 15px 19px 16px;

                .option-check-icon {
                    right: 4px;
                    top: 4px;

                    svg {
                        color: #949597;
                    }
                }
            }

            .option-check-icon {
                position: absolute;
                right: 9px;
                top: 9px;
                //transition: 0.4s;

                svg {
                    color: #F5F5F5;
                    width: 32px;
                    height: 32px;
                }
            }

            .option-card-details {
                display: flex;
                flex-direction: column;
                height: 100%;

                .module-summary {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                }

                .module-title {
                    h5 {
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 18px;
                        font-weight: 500;
                        font-family: Inter, sans-serif;
                        line-height: 26px;
                        letter-spacing: 0.02em;
                    }
                }

                .module-description {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                    margin-top: 16px;
                }

                .view-detail {
                    margin-top: auto;

                    button {
                        opacity: 0;
                        margin-top: 32px;
                        height: 32px;
                        transition: ease-in-out .3s;
                    }
                }
            }
        }
    }
}

//@container (max-width: 600px) {
//    .campaign-module-options {
//        display: inline-grid;
//        grid-auto-rows: 1fr;
//        grid-template-columns: repeat(2, 1fr);
//        gap: 13px 11px;
//    }
//}
