.campaign-detail-page,
.campaign-learners-page {
    background-color: white;
    //box-shadow: inset -1px 0px 0px #D9D9D9, inset 0px 1px 0px #D9D9D9, inset 0px -1px 0px #D9D9D9;

    .button-group {
        background: #fff;
        box-sizing: border-box;
        //box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px 0 0 2px;

        &.active {
            color: rgba(24, 144, 255, 1);
            border: 1px solid #1890ff;
        }
    }

    .page-title {
        margin-bottom: 20px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.02em;

        .page-title-editable {
            font-size: 24px;

            .anticon {
                display: flex;
                align-items: flex-end;
                font-size: 14px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

    }
}

.actions-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;

    .search-bar {
        flex: 1;

        .button-group {
            margin-right: 8px;
        }
    }

    button.primary {
        color: #fff;
        background: var(--button-color, #1890FF);
        border: 1px solid var(--button-color, #1890FF);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
        border-radius: 6px;
        padding: 5px 16px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        margin: 0;
        display: flex;
        min-height: 45px;

        &:hover {
            color: var(--button-color, #1890FF);
            border: 1px solid var(--button-color, #1890FF);
            background: #fff;
        }
    }
}

.campaign-list-page-head {
    .button-group {
        .ant-btn.active {
            color: var(--button-color, #1890FF);
            border-color: var(--button-color, #1890FF);
        }
    }
}

.campaign-list-container {
    margin-bottom: 20px;

    .ant-tabs > .ant-tabs-nav {
        width: fit-content; //to highlight only buttons
    }

    .campaign-list-inner {
        padding: 20px 0;
        margin-bottom: 36px;
        background: rgba(248, 250, 252, 1);

        .ant-empty-normal {
            padding: 20px;
            border: 0.8px dashed rgba(0, 0, 0, 0.3);
            margin: 0 auto;
            background: #fff;
        }

        .campaign-items {
            display: flex;
            flex-direction: column;
        }

        .campaign-item {
            margin-bottom: 17px;
            position: relative;
            border-left: 5px solid #c3c3c3;
            padding: 19px 29px 35px;
            background: #fff;
        }

        .campaign-content > h5 {
            margin-bottom: 25px;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #303134;
            word-break: break-word;
            padding-right: 160px;
        }

        .info-list {
            display: flex;
            width: 100%;
            align-items: center;
        }

        .info-item {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #818181;
            padding-right: 33px;
            flex: 1 1 20%;
            max-width: 200px;

            &.date-info, &.program-info {
                // date info flex basis 250px
                flex: 1 1 250px;
                max-width: 300px;
            }

            .disabled {
                color: rgba(0, 0, 0, .25);
                text-shadow: none;
                box-shadow: none;
                pointer-events: none;

                button {
                    color: #818181;
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 400;
                }
            }

            > span {
                display: flex;
                align-items: center;

                &.anticon {
                    padding-right: 12px;
                }

                + span {
                    padding-right: 0;
                }
            }

            &.module-info {
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;

                button {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 400;

                    span {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .ant-btn-link,
            a {
                display: flex;
                align-items: center;

                button {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 400;
                }

                .anticon {
                    margin-right: 0;
                }

                .anticon-play-square {
                    top: -2px;
                    position: relative;
                }
            }
        }

        .campaign-actions {
            position: absolute;
            right: 60px;
            top: 18px;
            display: flex;

            .actions-dropdown {
                position: relative;
                top: -2px;

                .anticon {
                    color: rgba(0, 0, 0, 0.9);
                }
            }

            .status {
                margin-right: 30px;
                color: #909090;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                min-width: 100px;

                span {
                    display: flex;
                    align-items: center;
                }

                &.launched {
                    color: #52c41a;
                }

                .anticon {
                    margin-right: 10px;
                    font-size: 15px;

                    &.anticon-field-time {
                        position: relative;
                        top: -2px;
                    }
                }
            }
        }

        .campaign-item {
            &.Launched, &.Scheduled {
                border-left: 5px solid #09bd3c;

                .campaign-actions .status {
                    color: #52c41a;
                }
            }

            &.Ended {
                border-left: 5px solid #2979f2;

                .campaign-actions .status {
                    color: #2979f2;
                }
            }
        }
    }
}

.pagination {
    display: flex;
    justify-content: right;
    padding: 0 31px;
}

.ant-empty-footer {
    .ant-btn-primary {
        color: #fff;
        background: var(--button-color, #1890FF);
        border-radius: 6px;
        border: 1px solid var(--button-color, #1890FF);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);

        &:hover {
            color: var(--button-color, #1890FF);
            border: 1px solid var(--button-color, #1890FF);
            background: #fff;
        }
    }
}

label.ant-form-item-required {
    &[title="report-radio"],
    &[title="select-audience"] {
        display: none;
    }
}

.actions-dropdown-menu {
    .ant-dropdown-menu-title-content {
        .disabled {
            pointer-events: none;
        }
    }
}

.additional_dropdown_item {
    cursor: pointer;
    color: #1890FF;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 20px;

    &:hover {
        color: #4b97de;
        background: #f6f6f6;
    }
}

.filter-cards {
    > button {
        &:first-of-type {
            border-radius: 4px 0 0 4px;
        }

        &:last-of-type {
            border-radius: 0 4px 4px 0;
        }
    }
}


.modules-update-panel {
    .ant-form-item-control-input > .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
    }
}
