//override antd css
body {
    width: auto !important;
    font-size: initial;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.btn_wrap {
    .ant-btn-primary {
        color: #fff;
        background: var(--button-color, #1890ff);
        border: 1px solid var(--button-color, #1890ff);

        &:hover {
            color: var(--button-color, #1890ff);
            border: 1px solid var(--button-color, #1890ff);
            background: #fff;
        }
    }
}

.pre-account-table {
    display: flex;
    justify-content: space-between;

    .sub-heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
}

.account-table {
    .status-pill {
        padding: 4px 8px;
        position: static;
        width: 57px;
        height: 23px;
        left: 10px;
        top: 10px;
        border-radius: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #fff;

        &.green-pill {
            background: #52c41a;
        }

        &.red-pill {
            background: #ff7875;
        }
    }

    .sub-account-delete-btn {
        background: transparent;
        border: none;
        box-shadow: none;
        color: var(--links-color, #40a9ff);
        transition: 0.4s;

        &:hover {
            color: var(--links-color, #40a9ff);
            box-shadow: none;

            span {
                text-decoration: underline;
            }
        }
    }

    .sub-account-login-btn {
        background: #fff;
        color: var(--button-color, #1890ff);
        border: 1px solid var(--button-color, #1890ff);

        &:hover {
            background: var(--button-color, #1890ff);
            color: #fff;
            border: 1px solid var(--button-color, #1890ff);
        }
    }
}


.ant-modal-content {
    .ant-modal-body {
        .ant-input-group-addon {
            background: transparent;
            border: none;
            display: block;
            padding-left: 0;
            margin-bottom: 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-alert.ant-alert-warning {
            align-items: baseline;
            padding: 18px 0 0;
            background: transparent;
            border: none;
        }
    }
}


