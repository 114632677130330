.admin-page.campaign-learners-page {
    background-color: transparent;

    .campaign-learners-page-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
    }

    .campaign-learners-container {
        .campaign-learners-inner {
            padding: 20px 12px 0 0;
            margin-bottom: 36px;
            background-color: #F8FAFC;

            .campaign-learners-inner-container {
                padding: 16px 0;

                .campaign-learner-filter-cards {
                    display: flex;
                    gap: 8px;
                    margin: 12px 0px;
                    cursor: pointer;

                    .ant-checkbox-wrapper {
                        align-items: flex-start;
                        margin-right: -34px;

                        .ant-checkbox {
                            order: 2;
                            position: relative;
                            right: 35px;
                            top: 10px;
                        }
                    }

                    .filter-card {
                        color: #6D7D93;
                        font-size: 16px;
                        line-height: 24px;
                        padding: 20px 25px;
                        min-height: 118px;
                        min-width: 200px;
                        border-radius: 10px;
                        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
                        cursor: pointer;

                        .label {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                        }

                        .stats {
                            font-size: 28px;
                            font-weight: 700;
                            line-height: 24px;
                            margin-top: 16px;
                        }

                        &.active {
                            color: #FFFFFF;
                            background-color: #1890FF;
                            box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15);

                            .ant-spin-dot-item {
                                background-color: #FFFFFF;
                            }
                        }
                    }
                }

                .campaign-learner-actions {
                    margin: 0;
                    padding: 24px 12px;
                    background: #FFFFFF;

                    #learners-filter-form {
                        display: flex;
                        justify-content: space-between;

                        .ant-form-item {
                            margin-bottom: 0;

                            .ant-select-clear {
                                .anticon {
                                    vertical-align: top;
                                }
                            }
                        }

                        .generate-csv {
                            padding: 0;

                            > a {
                                padding: 10px 16px;

                                &:hover {
                                    color: var(--primary-color);
                                    text-decoration: none;
                                }
                            }

                            &[disabled] {
                                height: 48px;
                                > a {
                                    pointer-events: none;

                                    &:hover {
                                        color: rgba(0, 0, 0, 0.25);
                                    }
                                }
                            }
                        }
                    }

                    .ant-select-selection-item-remove {
                        display: flex;
                        align-items: center;
                    }

                    .enrol-learner {
                        > button {
                            display: flex;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    .ant-input, .ant-picker {
                        margin: 0 !important;
                        padding: 0 !important;
                        border-radius: 2px !important;
                        height: auto;
                    }

                    .ant-btn.ant-input-search-button {
                        width: auto;
                        padding: 4px 15px;

                        svg {
                            color: #1890FF;
                        }
                    }
                }

                .campaign-learner-table {
                    margin: 24px 0;

                    .training-status {
                        font-size: 14px;
                        font-weight: 500;
                        padding: 4px;
                        border-radius: 10px;
                        min-width: 90px;
                        text-align: center;
                        border: none;

                        &.not-started {
                            color: #D1CFCF;
                            background-color: #D9D9D91A;

                        }

                        &.in-progress {
                            color: #296FC8;
                            background-color: #307FE21A;

                        }

                        &.overdue {
                            color: #FF7875;
                            background-color: #FF78751A;

                        }

                        &.completed {
                            color: #46A716;
                            background-color: #52C41A1A;

                        }
                    }
                }
            }
        }
    }
}

.enrol-learner-modal {
    .ant-modal-content {
        .ant-modal-header {
            padding-top: 0;
            padding-bottom: 20px;
        }

        .ant-modal-close {
            top: 5%;
        }

        .ant-modal-body {
            padding: 12px 24px;

            .ant-select {
                div.ant-select-selector {
                    padding: 6px 12px !important;

                    .ant-select-selection-item-remove {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .ant-modal-footer {
        border: none;
        display: flex;
        padding: 20px 16px 0px;

        .ant-btn-primary {
            margin: 0 8px !important;
            padding: 4px 24px;
            width: 130px;
            background: var(--button-color, #1890FF);
            border-radius: 2px;
            border: 1px solid var(--button-color, #1890FF);
            margin-left: auto !important;
            margin-right: 8px !important;

            &:hover {
                color: var(--button-color, #1890FF);
                border: 1px solid var(--button-color, #1890FF);
                background: #fff;
            }
        }
    }

    .ant-select-multiple .ant-select-selector {
        height: auto;
    }
}

.remove-learner-modal {
    .ant-modal-content {
        padding: 10px;

        .ant-modal-close {
            top: 5%;
        }
    }

    .ant-modal-body {
        padding: 0 !important;

        .ant-modal-confirm-body {
            .anticon-exclamation-circle {
                display: none;
            }

            .ant-modal-confirm-title {
                padding-bottom: 21px;
                box-shadow: inset 0px -1px 0px #f0f0f0;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.85);
            }

            .ant-modal-confirm-content {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
                margin: 12px 0 0 !important;
            }
        }

        .ant-modal-confirm-btns {
            display: flex;

            .ant-btn-primary {
                margin-right: 8px !important;
                padding: 5px 16px !important;
            }
        }
    }
}
