@import "variables";
@import "mixins";
@import "shared.scss";
/**
Admin pages
 */
@import "userpage.scss";
@import "apprpage.scss";
@import "ldap.scss";
@import "account_mgmt";
@import "reportspage.scss";
@import "remediationpage.scss";
@import "usertourwidget";
@import "domainspage.scss";
@import "authentication.scss";
@import "campaign/index";
@import "assetspage.scss";
@import "fcblockagepage";

// move to global admin style
#root .body_outer_admin {
    background: #F7F9FB;
    width: 100%;
    overflow-x: auto;

    .body {
        // admin pages do not support responsive as it's hard to manage content
        min-width: calc(100vw - 230px);

        background: inherit;
        padding: 0;

        &.open-tour {
            pointer-events: none;
        }
    }

    footer {
        min-width: calc(100vw - 230px);
    }
}

.admin-page {
    padding: 30px 35px;
}

.admin-page-wrap {
    height: 100%;

    > .admin-container {
        height: 100%;

        > .admin-page {
            height: 100%;
        }
    }

    .ant-btn {
        display: inline-flex;
        font-weight: 400;
    }
}
