.admin-page.campaign-detail-page {
    padding: 20px 0;
    background-color: #F7F9FB;

    /**
    campaign detail panel
     */
    .detail-panel {
        width: 100%;
        max-width: 100%;

        .group-assignment,
        .date-selector-wrap {
            .campaign-date-section, {
                width: 100%;

                > div:first-of-type {
                    display: flex;
                    gap: 24px;
                    width: 100%;

                    > div {
                        width: 33%;
                        margin-bottom: 0;

                        .ant-form-item-control-input-content button {
                            padding: 5px;

                            > span {
                                vertical-align: text-bottom;

                                svg {
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }

                .campaign-date-label {
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;
                }

                .timezone-link-wrap {
                    display: flex;
                    align-items: center;
                    margin-bottom: 24px;
                }

                .ant-picker {
                    width: 100%;
                    min-height: 48px;
                    border: 1px solid #D7D7D7;

                    .ant-picker-suffix {
                        font-size: 20px;
                        color: #1890FF;
                    }

                    .ant-picker-clear {
                        font-size: 20px;
                    }
                }
            }
        }

        .ant-space {
            width: 100%;

            > div {
                flex: 1;
            }
        }

        .module-update-btn {
            button {
                height: $general_admin_btn_height;
                background: #1890FF;
                border: 1px solid #1890FF;
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);

                &:disabled {
                    color: rgba(0, 0, 0, .25);
                    border-color: #d9d9d9;
                    background: #f5f5f5;
                    text-shadow: none;
                    box-shadow: none;
                }
            }
        }

        .ant-select-selection-item-remove {
            display: flex;
            align-items: center;
        }

        .campgn-desc-txtarea {
            min-height: 123px;
            border: 1px solid #D9D9D9;
            margin: 8px 0 16px;
        }

        .program_creation_wrap {
            display: block;
            border-left: 0;
        }
    }

    .campaign-list-wrap {
        .ant-tabs-nav {
            margin-bottom: 0;
            padding-bottom: 10px;
            margin: 0 40px;

            &::before {
                border-bottom: 1px solid #E6E6E6;
            }
        }
    }

    .ant-tabs-content {
        padding: 24px 35px;
        height: 100%;

        > .ant-tabs-tabpane {
            //background: #fff;
            //padding: 25px 40px;
        }
    }

    .campaign-detail-form {
        //max-width: 1000px;
        margin-left: 0;

        .drag-container {
            max-height: 750px;
            padding-top: 5px;
            margin: 20px 0;
            padding-bottom: 20px;
            overflow: auto;

            .campaign-module-table {
                margin: 0;
                padding: 0;

                .campaign-module-index {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #EFEFEF;
                    border: none;
                    box-sizing: border-box;
                    box-shadow: none;
                    border-radius: 6px;
                    min-height: 48px;
                    min-width: 48px;
                    line-height: 30px;

                    &:hover {
                        outline: none;
                        border: none;
                        color: rgba(0, 0, 0, 0.88);
                    }
                }

                .anticon-holder {
                    font-size: 20px;
                    color: rgba(0, 0, 0, 0.5);
                    font-weight: 700;
                    vertical-align: middle;
                    cursor: move;
                    touch-action: none;
                    display: inline-block;
                    // make it a bigger area for easy drag-drop
                    width: 100%;
                    align-items: center;
                    height: calc(100% + 16px);

                    &.disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    .ant-collapse-item {
        .ant-select-selector {
            height: inherit;
        }
    }

    .ant-select-selector {
        border: 1px solid #D7D7D7;

        .ant-select-selection-item {
            display: flex;
            align-items: center;
        }
    }

    .ant-select-arrow {
        font-size: 20px;
    }

    .report-switch-wrap,
    .campaign-switch-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &:last-child {
            padding-bottom: 20px;
        }

        .switch-wrap {
            flex: 1;
            font-size: 16px;
            line-height: 24px;
            color: #303134;

            > .ant-form-item {
                flex-direction: row;
                margin-bottom: 0;

                .ant-form-item-row {
                    flex-direction: row;

                    .ant-form-item-control {
                        order: 1;
                        flex-grow: 0;
                        min-width: 80px;
                        width: auto;

                        .ant-switch {
                            margin-right: 15px;
                        }
                    }

                    .ant-form-item-label {
                        order: 2;
                        padding-bottom: 0;
                        line-height: 2.2;
                    }
                }
            }
        }

        .anticon-arrows-alt {
            position: relative;
            top: -3px;
        }

        .ant-btn-link {
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
        }
    }

    .campaign-switch-wrap {
        display: inherit;

        .switch-wrap {
            width: 100%;

            > .ant-form-item {
                .ant-form-item-row {
                    flex-direction: row-reverse;
                    gap: 15px;
                    justify-content: space-between;

                    .ant-form-item-control {
                        min-width: auto;
                        width: auto;

                        .ant-switch {
                            margin: 0;
                        }
                    }

                    .ant-form-item-label {
                        font-family: "Inter", sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0.28px;
                    }
                }
            }
        }
    }

    .add_individual_block {
        margin: 1rem 0 2.5rem;

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #717579;
        }

        .ant-btn {
            display: flex;
            align-items: center;
            justify-content: left;
            min-width: 332px;
            min-height: 40px;
            background: #F5F5F5;
            border: 1px solid #D9D9D9;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
            border-radius: 2px;
        }
    }

    .detail-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: #303134;
        margin-bottom: 22px;
    }

    .campaign-detail-email-template {
        .modal-head {
            display: flex;
            align-items: flex-start;
            font-family: 'Inter', sans-serif;

            .anticon {
                margin-right: 12px;
                position: relative;
                top: 10px;
            }

            h2 {
                margin-bottom: 0;
            }

            p {
                font-size: 14px;
            }
        }
    }

    /**
        Campaign Report
     */
    .campaign-detail-container {
        .ant-alert-warning {
            .ant-alert-close-icon {
                font-size: 20px;
            }
        }

        .detail-panel {
            margin-bottom: 1rem;
            background-color: #ffffff;
            padding: 25px 40px;

            .detail-title {
                font-weight: 600;
                font-family: 'Inter', sans-serif;
                font-size: 24px;
                line-height: 150%;
                letter-spacing: 0.48px;
                color: #303134;
                margin-bottom: 22px;
            }

            .assign-user-groups {
                margin-bottom: 20px;
            }

            .input_group {
                $selector_offset: 15px;

                .ant-select-selector {
                    min-height: 50px;
                    border: 1px solid #D7D7D7;
                    padding: 0 $selector_offset;
                }

                .ant-select-multiple .ant-select-selection-placeholder {
                    inset-inline-start: $selector_offset + 8px;
                }

                .ant-select-disabled {
                    .ant-select-selector {
                        background: #F0F0F0;
                    }
                }
            }

            &.form-content {
                .form-content-inner {
                    max-width: 1000px;

                    .cmpgn-selctor {
                        .ant-select-selector {
                            min-height: 48px;
                        }
                    }
                }
            }
        }

        .ant-select {
            width: 100%;
            max-width: 700px;
        }

        .report-format-group {
            display: flex;
            align-items: center;

            .form-group {
                flex: 1;
            }

            .report-download {
                color: #1890ff;
                font-size: 16px;
                line-height: 21px;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                top: 5px;

                &:hover {
                    text-decoration: underline;
                }

                .anticon {
                    margin-left: 12px;
                }
            }
        }

        .campaign-report-form {
            .detail-panel {
                margin-bottom: 0;

                .button.ant-btn {
                    float: right;
                }
            }

            .detail-title {
                margin-bottom: 22px;
                font-weight: bold;
                font-size: 20px;
                line-height: 23px;
                color: #303134;
            }

            .report-switch {
                display: inline-flex;
                align-items: center;

                .report-switch-wrap {
                    padding: 0;
                    margin-left: 10px;
                    margin-bottom: 22px !important;
                }

                p {
                    max-width: 556px;
                    line-height: 140%;
                    color: rgba(48, 49, 52, 0.8);
                }
            }

            .ant-btn-primary {
                margin-left: 0 !important;
            }

            .campaign-report-audience-note {
                display: inline-flex;
                align-items: center;

                span {
                    margin-left: 5px;
                }
            }

            .ant-select-selector {
                .ant-select-arrow {
                    width: 15px;
                    height: 24px;
                    top: 39%;

                    .anticon-right {
                        width: 15px;
                        height: 24px;
                        color: rgba(0, 0, 0, 0.45);
                    }
                }
            }
        }

        .input-label {
            margin-bottom: 15px;
        }

        .btn_wrap {
            display: flex;
            margin-bottom: 1rem;
            margin-top: 1rem;

            > button {
                max-width: 450px;
            }

            .ant-btn-primary {
                //width: 50%;
                margin-right: 5px;
                min-height: 44px;
            }

            .ant-btn-secondary {
                //width: 50%;
                margin-left: 5px;
                min-height: 44px;
            }
        }
    }

    .campaign-detail-page-head {
        margin: 0 40px;

        .back-to-overview {
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 14px;
            font-size: 14px;

            > a {
                //color: rgba(0, 0, 0, 0.85);
                color: #00000073;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .ant-tabs-nav-list {
            .ant-tabs-tab {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }

    .modules-update-panel {
        margin-bottom: 1rem;

        .extra-module-info {
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-collapse-arrow {
            position: relative;
            top: -1px;
        }

        .campaign-modules-form-item {
            .ant-form-item-control-input-content {
                display: flex;
                align-items: center;
            }
        }
    }

    /**
    remediation
     */
    .remediation-detail-container {
        .remediation-title {
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 32px;
        }

        .btn_wrap {
            .ant-btn-primary {
                margin-left: 0 !important;
            }
        }
    }
}

.local-campaign-wrap {
    .ant-modal-wrap {
        z-index: 1042; //To override the nav livks' priority
    }

    .ant-notification.ant-notification-topRight {
        margin-top: 64px;
    }

    .campaign-list-inner {
        .campaign-item {
            .info-list {
                .info-item {
                    > a {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    > button {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    > span {
                        .anticon {
                            &.anticon-user {
                                padding-right: 8px;
                                position: relative;
                                top: -1px;
                            }
                        }
                    }

                    > .ant-btn-link {
                        .anticon {
                            &.anticon-play-square {
                                padding-right: 8px;
                                position: relative;
                                top: -1px;
                            }
                        }
                    }
                }
            }
        }

        .ant-empty .ant-empty-description {
            max-width: 500px;
            margin: 0 auto;
        }
    }
}

.message_modal {
    .ant-modal-content {
        .ant-modal-body {
            padding: 25px 50px;
        }

        > .ant-modal-close {
            top: 20px;
        }
    }

    .ant-modal-confirm-body {
        .ant-modal-confirm-title {
            font-size: 28px !important;
            padding: 16px 24px 16px 10px;
        }

        .ant-modal-confirm-content {
            padding: 16px 24px 16px 44px !important;

            ul {
                margin-inline-start: 14px;

                li {
                    list-style: disc;
                }
            }
        }

        > .anticon {
            display: inline-block !important;
            margin-right: 0;

            &.anticon-check-circle {
                color: #5DB082;
            }
        }
    }

    &.leave_modal, &.leave_tousers_modal {
        .ant-modal-confirm-body {
            .anticon {
                color: #faad14 !important;
                top: 4px;
            }
        }

        .ant-modal-confirm-btns {
            flex-direction: row-reverse;

            .ant-btn-primary {
                margin-right: 15px;
                margin-left: 0 !important;
            }
        }
    }

    &.save_modal {
        .ant-modal-confirm-content {
            padding: 0;
        }

        .ant-modal-confirm-btns {
            padding: 0;
        }
    }

    &.launch_modal {
        .ant-modal-confirm-title {
            font-size: 28px;
        }

        .ant-modal-confirm-btns {
            flex-direction: row-reverse;

            .ant-btn-primary {
                float: left;
                margin-left: 0 !important;
                margin-right: 15px;
            }
        }
    }

    &.warning {
        .ant-modal-confirm-btns {
            .ant-btn.ant-btn-primary {
                margin-right: 15px;
                margin-left: 2.5rem;
            }
        }

        .anticon-exclamation-circle {
            svg {
                background: #faad14;
                border-radius: 50%;
                color: #FFFFFF;

                path:first-child {
                    color: #faad14;
                }
            }
        }
    }
}

.template-module-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 24px 0;

    .template-module {
        width: 100%;
        background-color: #F7F9FB;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;

        .left-side {
            display: flex;
            flex-direction: row;

            .name {
                .ant-btn-link {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #307FE2;
                    letter-spacing: 0.32px;
                    display: flex;
                    gap: 5px;
                }
            }

            .icon {
                margin: auto 0;

                svg {
                    width: 26px;
                    height: 26px;
                    color: #949597;
                }
            }
        }

        .right-side {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.24px;
            color: #949597;
            display: flex;
            gap: 3px;
        }
    }
}

.campaign-module-detail-modal {
    width: 836px;

    &.with-video-preview {
        .ant-modal-content {
            .ant-modal-body {
                padding: 0;
            }
        }
    }

    .ant-modal-content {
        button.ant-modal-close {
            background-color: #00000080;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            top: 20px;
            right: 24px;

            .ant-modal-close-x {
                .anticon.ant-modal-close-icon {
                    svg {
                        fill: #ffffff;
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }

        .ant-modal-body {
            .campaign-module-video-preview {
                video {
                    width: 836px;
                }

                img {
                    position: relative;
                    top: -18px;
                    left: -24px;
                }
            }

            .template-module-detail-modal {
                margin: 22px 48px;

                .module-title {
                    display: flex;
                    align-items: center;
                    gap: 14px;
                    margin-bottom: 6px;

                    > span {
                        margin: auto 0;

                        h5 {
                            margin: auto 0;
                            font-family: "Inter", sans-serif;
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 150%;
                            letter-spacing: 0.48px;
                        }
                    }
                }

                .module-details,
                .module-summary,
                .available-languages {
                    font-size: 16px;
                    font-family: "Inter", sans-serif;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.32px;

                    .strong {
                        font-weight: 500;
                    }

                    h6 {
                        font-family: "Inter", sans-serif;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 150%;
                        letter-spacing: 0.4px;
                    }

                    p {
                        font-size: 16px;
                        font-family: "Inter", sans-serif;
                        line-height: 24px;
                    }

                    ul {
                        li {
                            list-style: disc;
                            margin-left: 28px;
                        }
                    }
                }

                .module-summary {
                    color: #949597;
                    margin-bottom: 10px;
                    display: flex;
                    gap: 16px;

                    span {
                        margin: auto 0;
                    }

                    .separator {
                        width: 5px;
                        height: 5px;
                        background-color: #949597;
                        border-radius: 50%;
                    }
                }

                .available-languages {
                    margin-top: 24px;
                }
            }
        }
    }
}

.action-bottons {
    display: flex;
    margin: 32px 0;
    justify-content: space-between;

    span {
        display: flex;
        gap: 16px;
    }
}

.form-container-template-selector {
    display: flex;
    gap: 16px;

    .btn-edit-template {
        display: flex;
        align-items: center;
        gap: 16px;

        button {
            padding: 0;

            &:hover {
                span {
                    text-decoration: underline;
                }
            }

            &.create-new {
                display: flex;
            }
        }
    }

    .template-selection-wrapper {
        width: 66%;

        .custom-template-selection {
            position: absolute;
            z-index: 3;
            width: 66%;

            .template-select-collapse {
                min-height: 48px;
                max-height: 250px;
                overflow-y: scroll;
                background-color: #FFFFFF;
                border-radius: 2px;
                border: none;
                box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
                font-family: 'Inter', sans-serif;
                font-style: normal;
                font-weight: 400;
                min-width: 400px;

                .ant-collapse-item {
                    border: none;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.04);
                    }

                    .ant-collapse-header {
                        &[aria-expanded="true"] {
                            background-color: rgba(0, 0, 0, 0.04);
                        }

                        .ant-collapse-header-text {
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.02em;
                            color: #313232;
                        }
                    }

                    .ant-collapse-content {
                        border: none;

                        .ant-form-item {
                            margin-bottom: 0;
                        }

                        .ant-collapse-content-box {
                            padding: 0;

                            .select-collapse-content-btn {
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0.02em;
                                color: #313232;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                box-shadow: none;
                                width: 100%;
                                padding: 12.83px 24px 12.83px 41px;
                                background: rgba(255, 255, 255, 1e-05);
                                border: none;
                                border-bottom: 1px solid #D9D9D9;
                                border-radius: 0;
                                justify-content: space-between;

                                &:hover {
                                    color: #1890FF;
                                    background-color: rgba(0, 0, 0, 0.04);
                                }

                                &.selected {
                                    color: #1890FF;
                                }
                            }
                        }
                    }
                }
            }
        }

        .select-collapse-btn {
            width: 100%;
            min-width: 400px;

            &button, &.ant-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 16px;
                gap: 8px;
                height: 48px;
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 6px;
                margin-right: 0;
                text-align: left;

                &[disabled] {
                    background: #F5F5F5;
                    border: 1px solid #D9D9D9;
                    border-radius: 6px;
                    cursor: not-allowed;
                }

                span {
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                    letter-spacing: 0.02em;
                    color: #313232;
                }
            }
        }
    }
}

