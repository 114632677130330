$text_dark: #292929;
$text_default: #495057;
$text_light: #35383c;
$text_lighter: #707070;

$separator_color: rgba(0, 0, 0, .125);
$separator_color_light: #ededed;

$primary_blue: #5aa3de;
$primary_blue_hover: #4296DA;

$default_grey: #e9f0f3;
$default_grey_hover: #dde2e4;

$default_setting_title_color: #687489;
$default_warning_color: #ff0000;
$default_correct_color: #52C41A;

$danger_color_light: #FAAD14;
$danger_color: #d45231;
$danger_color_deep: #DE350B;
$danger_color_deeper: #BF2600;
$danger_color_bg: #FFEBE6;
$success_color: #36B37E;

// responsive break points vars
$grid_breakpoints_sm: 575.98px;
$grid_breakpoints_md: 767.98px;
$grid_breakpoints_lg: 991.98px;
$grid_breakpoints_xl: 1199.98px;

/**
branding items color
 */
$header_bg: white;
$light_grey: #E6E6E6;
$bg_grey: #EFEFEF;
$neutral_500: #687489;
$neutral_400: #94A3BB;
$marketing_blue: #307FE2;
$marketing_purple: #9063CD;
$success_color: #52C41A;
$grey_txt: #79869F;

$icon_color: #94A3BB;

$grey_bg: #F5F5F5;
$default_primary: #307FE2;
$default_primary_hover: #468CE3;
$default_primary_active: #245EAB;

/**
Height/Width
 */
$general_btn_radius: 6px;
$general_admin_btn_height: 40px;
$general_admin_sm_btn_height: 32px;
$primary_admin_btn_height: 48px;
$general_admin_input_height: 40px;
$general_admin_input_lg_height: 48px;
