.program_detail {
    .banner {
        background: #FAFAFA;
        margin-top: -35px;
        margin-left: -35px;
        margin-right: -35px;
        padding: 30px 40px 15px;

        .banner_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .content_text {
            max-width: 610px;
            justify-self: flex-start;

            h4 {
                font-family: Inter, sans-serif;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.02em;

                @include mobile {
                    font-size: 20px;
                    line-height: 30px;
                }

            }

            .content_desc {
                margin-bottom: 8px;
                font-size: 14px;
                color: rgb(0, 0, 0, 0.8);
                line-height: 20px;
                letter-spacing: 0.02em;
            }

            .download_certificate_link {
                font-size: 14px;
                line-height: 22px;
            }
        }

        .context_svg {
            flex: 1;
            text-align: center;
        }

        .program_card_img {
            background-image: url("/assets/images/program/banner3.png");;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            height: 160px;
            width: 160px;

            &.program_0 {
                background-image: url("/assets/images/program/banner.png");;
            }

            &.program_1 {
                background-image: url("/assets/images/program/banner1.png");;
            }

            &.program_2 {
                background-image: url("/assets/images/program/banner2.png");;
            }

            &.program_3 {
                background-image: url("/assets/images/program/banner3.png");;
            }

            &.program_4 {
                background-image: url("/assets/images/program/banner4.png");;
            }

            &.program_5 {
                background-image: url("/assets/images/program/banner5.png");;
            }

            &.program_6 {
                background-image: url("/assets/images/program/banner6.png");;
            }

            &.program_7 {
                background-image: url("/assets/images/program/banner7.png");;
            }

            &.program_8 {
                background-image: url("/assets/images/program/banner8.png");;
            }

            &.program_9 {
                background-image: url("/assets/images/program/banner9.png");;
            }
        }
    }

    .program_detail_content > div {
        margin-bottom: 40px;

        h5 {
            display: flex;
            align-items: center;

            .anticon {
                margin-right: 1rem;
                color: $icon_color;
            }
        }
    }

    .how_to_pass {
        ul, li {
            list-style: initial;
        }

        ul {
            padding-left: 20px;
        }

        .campaign {
            list-style: none;
            background: #f3e8d7;
            padding: 8px 10px;
            margin-top: 1rem;
            display: flex;
            align-items: center;

            b {
                margin: 0 5px;
            }

            .anticon {
                margin-right: 10px;
            }
        }
    }

    .modules {
        .program_wrap {
            position: relative;
            margin-top: 1rem;
        }

        .overview_info {
            position: absolute;
            left: 150px;
            top: -50px;
            font-size: 14px;
            font-weight: 400;
            color: rgb(0,0,0,0.8);
        }
    }
}
