.ant-layout {
    background: transparent;
}

.ldap-home-container {
    .ant-input {
        height: 40px;
    }

    .ant-table {
        min-height: calc(50vh - 4em);
    }

    .ldap-home-head {
        padding: 0;

        .back-to-users {
            //font-family: 'Roboto', 'sans-serif';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            padding-bottom: 10px;

            a {
                color: rgba(0, 0, 0, 0.65);

                &::after {
                    content: '/';
                    padding-left: 8px;
                    padding-right: 8px;
                }

                &:hover {
                    color: #40a9ff;

                    &::after {
                        color: rgba(0, 0, 0, 0.65);
                    }
                }
            }

            span {
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
}

.ldap-servers-search-box {
    border-radius: 6px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 6%);
    margin: 32px 0;
    padding: 16px 32px 16px 16px;

    .ldap-servers-search-input {
        width: 360px;
    }

    ::placeholder {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #949597;
    }

    svg {
        color: var(--button-color, #1890ff);
    }

    .ant-input-search-button {
        padding: 0 16px;
        width: 56px;

        .anticon-search {
            font-size: 18px;
            color: var(--primary-color);
        }
    }
}

.reached-limit-alert-container {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
}

.ldap-servers-add-new-button {
    float: right;

    button {
        color: #fff;
        background: var(--button-color, #1890ff);
        border-radius: 2px;
        border: 1px solid var(--button-color, #1890ff);
        box-shadow: 0 2px 0 rgb(0 0 0 / 4%);
        padding: 5px 16px;
        //font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        margin: 0;
        display: inline-flex;
        align-items: center;
    }

    &:hover {
        text-decoration: none;
    }
}

.ldap-servers-table {
    margin-top: 15px;

    .ant-table {
        color: #5C5C5C;

        .ant-table-thead {
            th {
                //font-family: 'Roboto', 'sans-serif';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #6E6E6E;
            }
        }

        .ant-table-tbody {
            td {
                //font-family: 'Roboto';
                font-style: normal;
                font-size: 14px;
                line-height: 22px;

                button {
                    font-weight: 500;
                    padding: 0;

                    &.ldap-server-sync-btn {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .ldap-server-status {
        padding: 4px;
        min-width: 90px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 500;
        border: none;
        text-align: center;

        &.red-pill {
            background: rgba(255, 120, 117, 0.1);
            color: #ff7875;
        }

        &.green-pill {
            background: rgba(82, 196, 26, 0.1);
            color: #52C41A;
        }

        &.grey-pill {
            background: rgba(195, 195, 195, 0.1);
            color: #c3c3c3;
        }
    }
}

.ldap-config-form-container {
    .ldap-config-form-head {
        padding: 0;

        .back-to-users {
            //font-family: 'Roboto', 'sans-serif';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            padding-bottom: 10px;

            a {
                color: rgba(0, 0, 0, 0.65);

                &::after {
                    content: '/';
                    padding-left: 8px;
                    padding-right: 8px;
                }

                &:hover {
                    color: #40a9ff;

                    &::after {
                        color: rgba(0, 0, 0, 0.65);
                    }
                }
            }

            span {
                color: rgba(0, 0, 0, 0.5);
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #313232;
        }
    }

    #ldapConfig {
        background-color: #F7F9FB;
        padding-bottom: 16px;

        ::placeholder {
            color: #949597;
        }

        .ant-form-item {
            padding: 0 27px;

            .ant-form-item-label {
                display: flex;
                align-items: center;

                @include _mobile {
                    justify-content: flex-end;
                }

                label {
                    margin-bottom: 0;
                }
            }

            &.ant-form-item-has-error {
                .ant-form-item-label {
                    align-items: start;
                    top: 0.5em;
                }
            }

            &.ldap-subform-header {
                background-color: #FAFAFA;
                padding-left: 0;
                margin-bottom: 0;
            }

            &.ant-form-item-with-help {
                margin-bottom: 28px;
            }
        }

        .ldap-config-form-items, .ldap-attr-form-items {
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.06);
            box-sizing: border-box;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
            padding: 17px 0;
        }

        .ldap-form-btns {
            border-radius: 6px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.06);
            box-sizing: border-box;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
            margin: 32px 0;
            padding: 16px 32px 16px 16px;
        }
    }

    h5 {
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
        padding: 24px 0 24px 27px;
        margin-bottom: 0;
    }

    .ant-btn-primary {
        margin-right: 16px;
    }
}


#page-navbar {
    .breadcrumb {
        .breadcrumb-item {
            a {
                color: var(--links-color, #1890ff);

                &:hover {
                    color: var(--links-color, #1890ff);
                }
            }
        }
    }
}
