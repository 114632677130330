/**
    Admin pages common layout
 */

body {
    font-size: 14px;
    font-family: Roboto, sans-serif;
}

h1 {
    font-size: 1.88rem;
}

h2 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.5rem;
}

h1, h2, h3, h4, h5, h6 {
    font-style: normal;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.85);
}

p {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 12px;
}

button {
    padding: 5px 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 0 rgb(0 0 0 / 4%);
    border-radius: $general_btn_radius;
}

/**
    Components sharing styles, Modal, btn, alert, message, etc.
    Dropdown, selectors, etc.
 */

.ant-breadcrumb {
    margin-bottom: 8px;

    .ant-breadcrumb-link a:hover {
        background-color: transparent;
    }
}

.ant-btn {
    padding: 5px 16px;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: $general_btn_radius;
    transition: 0.35s ease-in-out all;
    height: $general_admin_btn_height;

    &.btn-ht-lg {
        height: $primary_admin_btn_height;
    }

    &.btn-square {
        border-radius: 0;
    }

    &[disabled]:hover {
        text-decoration: none;
    }

    &.btn-wdt-sm {
        width: 75px;
    }

    &.btn-wdt-md {
        width: 129px;
    }

    &.btn-wdt-lg {
        width: 197px;
    }

    &.btn-ht-lg {
        height: 54px;
    }

    &.btn-ht-md {
        height: 32px;
    }

    &.btn-ht-sm {
        height: 22px;
    }

    &.ant-btn-primary {
        display: flex;
        align-items: center;
        color: #fff;
        background: var(--primary-color);
        border-radius: $general_btn_radius;
        border: 1px solid var(--primary-color);
        box-shadow: 0 2px 0 rgba(0, 0, 0, .04);
        height: $primary_admin_btn_height;

        &:hover {
            background-color: #FFFFFF;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            text-decoration: none;
        }
    }

    &.ant-btn-secondary {
        background: #fff;
        color: rgba(0, 0, 0, 0.85);
        border: 1px solid #d9d9d9;
        border-radius: $general_btn_radius;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
        height: $primary_admin_btn_height;

        &:hover {
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
            text-decoration: none;
        }
    }

    &.ant-btn-transparent {
        display: flex;
        align-items: center;
        background-color: transparent;
        color: var(--primary-color);
        border-radius: 6px;
        border: 1px solid var(--primary-color);
        box-shadow: 0 2px 0 rgba(0, 0, 0, .04);

        &:hover {
            color: #FFFFFF;
            background-color: var(--primary-color);
            border: 1px solid var(--primary-color);
            text-decoration: none;
        }
    }

    &.ant-btn-sm {
        height: $general_admin_sm_btn_height;
    }

    &.ant-btn-input {
        height: $general_admin_input_height;
    }
}

.ant-modal {
    .ant-modal-content {
        padding: 0;
        border-radius: 10px;
        position: relative;

        .ant-modal-title {
            padding: 16px 24px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            box-shadow: inset 0px -1px 0px #F0F0F0;
        }

        .ant-modal-body {
            margin: 0;
            padding: 18px 24px 24px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;

            p {
                padding: 8px 0;
                font-weight: 400;
                margin: 0;
            }
        }

        .ant-modal-header + .ant-modal-body {
            padding: 12px 24px 0;
        }

        .ant-modal-footer {
            display: flex;
            justify-content: flex-end;
            padding: 25px;
            margin: 0;
        }

        > .ant-modal-close {
            box-shadow: none;
            position: absolute;
            right: 4%;
            top: 8%;
        }

        .ant-modal-close-x {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ant-btn {
            padding: 5px 16px;
            min-height: 32px;
            background: #fff;
            border: 1px solid #d9d9d9;
            box-sizing: border-box;
            border-radius: $general_btn_radius;
            font-size: 14px;
            line-height: 22px;
            text-align: center;

            &:hover {
                border: 1px solid var(--primary-color);
                color: var(--primary-color);
            }

            &.btn-wdt-sm {
                width: 75px;
            }

            &.btn-wdt-md {
                width: 129px;
            }

            &.btn-wdt-lg {
                width: 197px;
            }

            &.btn-ht-sm {
                height: $general_admin_sm_btn_height;
            }

            &.btn-ht-lg {
                height: $primary_admin_btn_height;
            }

            &.ant-btn-primary {
                margin-right: 8px;
                color: #fff;
                background: var(--primary-color);
                border-radius: $general_btn_radius;
                border: 1px solid var(--primary-color);
                order: 1;

                &:hover {
                    background-color: #FFFFFF;
                    color: var(--primary-color);
                }
            }

            &.ant-btn-transparent {
                display: flex;
                align-items: center;
                background-color: transparent;
                color: var(--primary-color);
                border-radius: 6px;
                border: 1px solid var(--primary-color);
                box-shadow: 0 2px 0 rgba(0, 0, 0, .04);

                &:hover {
                    color: #FFFFFF;
                    background-color: var(--primary-color);
                    border: 1px solid var(--primary-color);
                    text-decoration: none;
                }
            }

            &.ant-btn-default, &.ant-btn-secondary {
                height: $primary_admin_btn_height;
                order: 2;

                &.btn-ht-sm {
                    height: $general_admin_sm_btn_height;
                }
            }
        }
    }
}

$modal_confirm_padding: 16px 24px;
.ant-modal-confirm {
    font-style: normal;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.85);

    .ant-modal-content {
        .ant-modal-body {
            padding: 0;

            .ant-modal-confirm-body {
                > .anticon {
                    display: none;
                }

                > .ant-modal-confirm-title {
                    padding: $modal_confirm_padding;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    box-shadow: inset 0px -1px 0px #F0F0F0;
                }

                > .ant-modal-confirm-content {
                    margin: 0;
                    padding: $modal_confirm_padding;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .ant-modal-close {
            top: 10%;
        }

        .ant-modal-confirm-btns {
            display: flex;
            justify-content: flex-end;
            // keep space left since some buttons aren't right-aligned
            padding: 0 0 24px 24px;

            > .ant-btn {
                min-width: 80px;
            }

            > .ant-btn:first-child {
                margin-right: 24px;
            }
        }
    }
}

//Standard heights for Form Input

.ant-input {
    &.input-ht-lg {
        height: $general_admin_input_lg_height;
        border-radius: 6px;
    }

    &.input-ht-md {
        height: $general_admin_input_height;
        border-radius: 6px;
    }

    &.input-ht-sm {
        height: 22px;
        border-radius: 6px;
    }
}

// ant collapse
.ant-collapse {
    .ant-collapse-header {
        align-items: center;

    }
}

//Standard heights for Input Password, Select and Search box

.input-ht-lg {
    border-radius: 6px;

    input {
        height: $general_admin_input_lg_height;
    }

    .ant-radio-button-wrapper {
        height: $general_admin_input_lg_height;
        display: inline-flex;
        align-items: center;
    }


}

.input-ht-md {
    border-radius: 6px;

    input {
        height: $general_admin_input_height;
    }

    .ant-radio-button-wrapper {
        height: $general_admin_input_height;
        display: inline-flex;
        align-items: center;
    }
}

.input-ht-sm {
    border-radius: 6px;

    input {
        height: 22px;
    }

    .ant-radio-button-wrapper {
        height: 22px;
        display: inline-flex;
        align-items: center;
    }
}

.ant-input-password {
    &.input-ht-lg {
        padding: 8px 12px;
        height: $general_admin_input_lg_height;

        .ant-input {
            height: 100%;
        }

        .ant-input-suffix {
            padding-right: 12px;
        }
    }

    &.input-ht-md {
        padding: 8px 12px;
        height: $general_admin_input_height;

        .ant-input {
            height: 100%;
        }

        .ant-input-suffix {
            padding-right: 12px;
        }
    }
}

.ant-input-search {
    .ant-input-clear-icon {
        display: flex;
    }

    .ant-input-group {
        .ant-input-affix-wrapper {
            border-radius: 6px 0 0 6px;
            height: 40px;

            > .ant-input {
                height: auto;
            }
        }

        > .ant-input-group-addon {
            .ant-input-search-button {
                border-radius: 0 6px 6px 0;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
            }
        }
    }

    &.input-ht-md {
        .ant-input-group {
            .ant-input-affix-wrapper {
                border-radius: 6px 0 0 6px;
                height: $general_admin_input_height;

                input {
                    height: auto;
                }
            }

            > .ant-input-group-addon {
                .ant-input-search-button {
                    border-radius: 0 6px 6px 0;
                    height: $general_admin_btn_height;
                    display: flex;
                    align-items: center;

                    svg {
                        font-size: 21px;
                        color: #1890FF;
                    }
                }
            }
        }
    }

    &.input-ht-sm {
        .ant-input-group {
            .ant-input-affix-wrapper {
                border-radius: 6px 0 0 6px;
                height: 22px;

                input {
                    height: auto;
                }
            }

            > .ant-input-group-addon {
                .ant-input-search-button {
                    border-radius: 0 6px 6px 0;
                    height: 22px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.ant-select {
    &.input-ht-lg {
        .ant-select-selector {
            min-height: $general_admin_input_lg_height;

            input {
                min-height: $general_admin_input_lg_height;
            }
        }
    }

    &.input-ht-md {
        .ant-select-selector {
            display: flex;
            align-items: center;
            min-height: $general_admin_input_height;

            input {
                min-height: $general_admin_input_height;
            }
        }
    }
}

.ant-typography {
    .ant-typography-edit {
        font-size: 24px;
        display: flex !important;
        margin-left: 9px;
    }
}

.ant-form-item {
    margin-bottom: 15px;

    .ant-select {
        &.ant-select-multiple {
            // multi-selection may be higher than the default
            .ant-select-selector {
                min-height: $general_admin_input_height;
                height: auto;
            }
        }

        .ant-select-selector {
            border-radius: $general_btn_radius;
            display: flex;
            align-items: center;
            height: $general_admin_input_height;
        }

        &.ant-select-lg {
            &.input-ht-lg {
                .ant-select-selector {
                    height: $general_admin_input_lg_height;
                }
            }

            &.input-ht-md {
                .ant-select-selector {
                    height: $general_admin_input_height;
                }
            }

            &.input-ht-sm {
                .ant-select-selector {
                    height: 22px;
                }
            }
        }
    }

    textarea {
        border-radius: $general_btn_radius;
    }
}

.ant-select-multiple {
    &.input-ht-lg {
        .ant-select-selector {
            min-height: $general_admin_input_lg_height;
        }
    }

    &.input-ht-md {
        .ant-select-selector {
            min-height: $general_admin_input_height;
        }
    }

    &.input-ht-sm {
        .ant-select-selector {
            min-height: 22px;
        }
    }
}

.ant-picker {
    padding: 0 11px;

    &.input-ht-lg {
        input {
            height: $general_admin_input_lg_height;
        }
    }

    &.input-ht-md {
        input {
            height: $general_admin_input_height;
        }
    }

    &.input-ht-sm {
        input {
            min-height: 22px;
        }
    }
}

.ant-input-number {
    &.input-ht-lg {
        input {
            height: $general_admin_input_lg_height;
        }
    }

    &.input-ht-md {
        input {
            height: $general_admin_input_height;
        }
    }

    &.input-ht-sm {
        input {
            min-height: 22px;
        }
    }
}

.ant-dropdown {
    .ant-dropdown-menu {
        border-radius: 6px;

        .ant-dropdown-menu-item {
            a:hover {
                text-decoration: none;
            }
        }
    }
}

.ant-select-dropdown {
    border-radius: 6px;
}

.customize-popup {
    // ...
}

.page-header-headings {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    letter-spacing: 0.02em;
    margin-bottom: 0;

    h1, h2 {
        margin: 0;
        color: rgba(0, 0, 0, .85);
        font-size: 28px;
        line-height: 42px;
        display: flex;
        align-items: center;
    }

    &.custom-edit-title {
        display: flex;
        align-items: center;
        flex: 2 1;
        min-width: 0;

        h2 {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 42px;
            letter-spacing: 0.02em;
            color: rgba(0, 0, 0, 0.85);
            cursor: text;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
        }

        &:not(.disabled) {
            .anticon-edit {
                display: none;
            }
        }

        .anticon-edit {
            font-size: 24px;
            margin-left: 9px;
        }

        .ant-input-affix-wrapper {
            padding: 0 11px;
            inset-inline-start: -12px;

            .ant-input-suffix {
                display: block;

                .ant-input-show-count-suffix {
                    position: absolute;
                    right: 0;
                    bottom: -1.2rem;
                    font-size: 10px;
                    font-weight: 400;
                    letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.45);
                }
            }

            &.ant-input-affix-wrapper-disabled {
                background: none;
                border: none;
                padding: 0;
                inset-inline-start: 0;

                .ant-input-suffix {
                    display: none;
                }
            }

            input {
                font-family: Inter, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 42px;
                letter-spacing: 0.02em;
                color: rgba(0, 0, 0, 0.85);
                text-overflow: ellipsis;
                cursor: text;
            }
        }
    }
}

.empty-desc {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #313232;
    padding: 8px;
}

.ant-table-wrapper {
    background: #fff;
    margin: 20px 0;
    padding-bottom: 20px;

    .ant-pagination.ant-table-pagination {
        margin: 16px 5px;
    }
}

.ant-table {
    .ant-table-wrapper {
        background: #fff;
        margin: 20px 0;
        padding-bottom: 20px;

        .ant-pagination.ant-table-pagination {
            margin: 16px 5px;
        }
    }


    &:not(.ant-table-bordered) {
        .ant-table-container {
            .ant-table-content {
                .ant-table-tbody {
                    > .ant-table-row {
                        > td {
                            white-space: break-spaces;
                            text-align: left;
                            word-break: break-word;

                            button {
                                > span {
                                    text-align: left;
                                }
                            }

                            &.ant-table-row-selected, &.ant-table-cell-row-hover {
                                &:first-child, &:last-child {
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

$grid-gutter-width-small: 10px;

.row.small-gutters {
    margin-right: -$grid-gutter-width-small;
    margin-left: -$grid-gutter-width-small;

    > .col,
    > [class*="col-"] {
        padding-right: $grid-gutter-width-small;
        padding-left: $grid-gutter-width-small;
    }
}
