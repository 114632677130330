.remediation-page {
    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page-header-headings {
            flex: 2 1;
        }
    }

    .remediation_panel {
        margin: 0;
        padding: 0;

        .remed-input-search {
            border-radius: 6px;
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.06);
            box-sizing: border-box;
            margin: 16px 0;
            padding: 16px 32px 16px 16px;

            .ant-input-search {
                max-width: 423px;
            }

            .ant-input-search-button {
                display: flex;
            }
        }

        .ant-table {
            min-height: calc(50vh - 4em);

            .ant-switch-inner {
                min-width: 56px;
            }
        }

    }
}

.remediation-rule-page {
    .ant-space {
        display: flex;
        position: relative;
        z-index: 1;

        .ant-space-item {
            &:first-child {
                position: relative;
                z-index: 2;
            }

            .condition-panel, .actions-panel {
                &.maxHeight {
                    max-height: 108px;
                }
            }
        }
    }

    #edit-rule-filter-form {
        .submit-btns {
            margin-top: 16px;
            padding: 24px 24px 0;
            background-color: #FFFFFF;

            button {
                min-height: 48px;
                margin-right: 16px;

                span {
                    min-width: 40px;
                }
            }
        }

        .input-item-panel {
            padding: 32px 24px 24px;
            background: #FFFFFF;
            margin: 16px 0 0;

            .ant-form-item-label {
                text-align: left;
            }

            .ant-form-item {
                > .ant-form-item-row {
                    align-items: start;
                }

                label {
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 150%;
                    letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.85);
                    margin-bottom: 0;
                    height: auto;

                    &::after {
                        content: '';
                    }
                }

                .ant-select-multiple {
                    .anticon-close {
                        vertical-align: 0;
                    }
                }
            }

            > .ant-form-item {
                margin-bottom: 24px;

                label {
                    font-size: 20px;
                    height: 48px;
                }
            }

            .rule-collapse-btn {
                width: 100%;
                max-width: 400px;

                button, &.ant-btn {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 16px;
                    gap: 8px;
                    height: 48px;
                    background: #FFFFFF;
                    border: 1px solid #D9D9D9;
                    border-radius: 6px;
                    margin-right: 0;
                    text-align: left;

                    &[disabled] {
                        background: #F5F5F5;
                        border: 1px solid #D9D9D9;
                        border-radius: 6px;
                        cursor: not-allowed;
                    }

                    span {
                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 140%;
                        letter-spacing: 0.02em;
                        color: #313232;
                    }
                }
            }

            .rule-collapse {
                min-height: 48px;
                background-color: #FFFFFF;
                border-radius: 2px;
                border: none;
                box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
                font-family: 'Inter', sans-serif;
                font-style: normal;
                font-weight: 400;
                max-width: 400px;

                .ant-collapse-item {
                    border: none;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.04);
                    }

                    .ant-collapse-header {
                        &[aria-expanded="true"] {
                            background-color: rgba(0, 0, 0, 0.04);
                        }

                        .ant-collapse-header-text {
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.02em;
                            color: #313232;
                        }
                    }

                    .ant-collapse-content {
                        border: none;

                        .ant-form-item {
                            margin-bottom: 0;
                        }

                        .ant-collapse-content-box {
                            padding: 0;

                            .rule-collapse-content-btn {
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0.02em;
                                color: #313232;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                box-shadow: none;
                                width: 100%;
                                padding: 12.83px 24px 12.83px 41px;
                                background: rgba(255, 255, 255, 1e-05);
                                border: none;
                                border-bottom: 1px solid #D9D9D9;
                                border-radius: 0;
                                justify-content: space-between;

                                &:hover {
                                    background-color: rgba(0, 0, 0, 0.04);
                                }
                            }
                        }
                    }
                }
            }

            .condition-input-item {
                width: 100%;

                .condition-input-item-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 12px 36px 12px 16px;
                    background: #F7F9FB;
                    border: 1px solid #D9D9D9;

                    .ant-btn-secondary {
                        background: transparent;
                        border: none;
                        box-shadow: none;
                        display: flex;
                        padding: 0;
                        outline: transparent none;

                        span {
                            font-family: 'Inter', sans-serif;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.02em;
                            color: rgba(0, 0, 0, 0.85);
                        }

                        .anticon {
                            font-size: 12px;
                        }
                    }
                }

                .condition-input-item-content {
                    padding: 36px 36px 0;
                    background: #FFFFFF;

                    &.fortiphish-condition-container {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 20px;

                        .fortiphish-condition-selector {
                            margin-bottom: 35px;

                            .campaign-select {
                                min-width: 376px;

                                @include tablet {
                                    min-width: auto;
                                }
                            }

                            .ant-row.ant-form-item-row {
                                flex-direction: column;
                                align-content: flex-start;
                                align-items: flex-start;

                                .ant-col.ant-form-item-label {
                                    padding-bottom: 5px;

                                    label {
                                        font-size: 14px;
                                    }
                                }

                                .ant-col.ant-form-item-control {
                                    flex: auto;
                                    min-width: 100%
                                }
                            }
                        }
                    }
                }
            }

            .condition-input-item-content {
                background: #FFFFFF;
                padding: 36px 36px 10px;
                background: #FFFFFF;
                border: 1px solid #F0F0F0;

                .ant-form-item-row {
                    align-items: center;
                }

                .ant-form-item-label {
                    white-space: normal;
                }

                .ant-picker {
                    .anticon-calendar {
                        color: var(--primary-color)
                    }
                }

                .placeholder-btn {
                    display: flex;
                    align-items: center;
                    background: transparent;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    color: var(--primary-color);
                }

                .placeholder-content {
                    padding: 0.5rem 1rem 0;

                    p {
                        display: flex;
                        align-items: center;
                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        color: rgba(0, 0, 0, 0.85);

                        button {
                            display: flex;
                            background: transparent;
                            border: none;
                            box-shadow: none;
                            padding: 0;
                            margin-left: 4px;
                            color: var(--primary-color);
                        }
                    }
                }
            }

            &.actions-panel {
                margin: 0;

                .actions-drpdwn-items {
                    >.ant-form-item-row {
                        max-height: 45px;

                        .ant-collapse {
                            position: relative;
                            z-index: 3;
                        }
                    }
                }

                .action-notification {
                    .ant-form-item-row {
                        align-items: start;
                    }

                    .notification-checkbox {
                        display: block;

                        label {
                            display: flex;
                            align-items: center;
                            margin-left: 0;
                            font-size: 14px;
                            font-weight: 400;

                            .ant-checkbox {
                                font-size: 0;
                            }
                        }
                    }
                }

                .ant-tabs-nav {
                    margin-bottom: 0;

                    &::before {
                        border-bottom: 1px solid #F0F0F0;
                    }

                    .ant-tabs-nav-wrap {
                        margin-left: 16px;

                        .ant-tabs-tab {
                            .ant-tabs-tab-remove {
                                box-shadow: none;
                            }
                        }
                    }
                }

                .ant-tabs-content-holder {
                    padding: 24px 36px;
                    gap: 12px;
                    border-width: 0px 1px 1px 1px;
                    border-style: solid;
                    border-color: #F0F0F0;
                    border-radius: 6px;
                }
            }
        }
    }
}
