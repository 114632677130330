/**
program section
 */
.program_container {
    .program_content {
        min-height: 150px;
    }

    .program_list {
        padding: 40px 0;
    }

    .program_wrap {
        display: inline-block;
        //overflow: hidden;
        width: 100%;
        border-radius: 12px 12px 10px 10px;
        border: 1px solid $bg_grey;
        overflow: visible;
        margin-bottom: 24px;

        .program_head {
            border-radius: 12px 12px 0 0;
            padding: 15px 50px 28px 140px;
            position: relative;
            display: flex;
            background: $bg_grey;
            border: 0.5px solid #E2E8F0;
            box-sizing: border-box;
            width: 100%;
            align-content: space-between;
            overflow: visible;

            .program_progress {
                .anticon-loading-3-quarters {
                    color: $default_correct_color;
                    position: relative;
                    top: 5px;
                    left: -5px;
                }
            }

            .program_vector {
                position: absolute;
                left: 10px;
                top: -20px;
                display: inline-block;
                width: 120px;
                height: 120px;
                background-image: url("/assets/images/program/banner3.png");
                background-repeat: no-repeat;
                background-size: cover;
            }

            .program_info {
                width: 600px;
                flex: 1;
                border-radius: 10.5px;

                h5 {
                    text-transform: capitalize;
                }
            }

            .program_date {
                .status {
                    margin-left: 10px;
                    font-size: 12px;
                    background: var(--link-color);
                    color: white;
                    border-radius: 6px;
                    padding: 6px 10px;
                    line-height: 16px;

                    &.overdue {
                        background-color: #ff7875;
                    }
                }
            }
        }

        .program_body {
            padding: 29px 22px 0;
        }

        .btn_wrap {
            padding: 0 22px 29px;
        }

        .modules {
            .module {
                &.completed {
                    .module_title {
                        &:hover {
                            color: var(--link-color);
                        }
                    }

                    .module_right .anticon {
                        color: #A6D997;
                    }
                }

                &.incompleted:not(.locked):not(.notenrolled) {
                    .module_title {
                        &:hover {
                            color: var(--link-color);
                        }
                    }
                }

                &:not(.locked) {
                    .module_right .text {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                padding: 10px 0;
                border-bottom: 1px solid #EFEFEF;
                display: flex;
                transition: .5s ease-in-out background-color;

                @include mobile {
                    display: block;

                    .ant-btn {
                        margin: 14px 0 0 50px ;
                        max-height: 32px;
                        padding: 6px 16px;
                        font-size: 14px;
                    }
                }

                .anticon, .ant-progress-inner {
                    margin-right: 8px;
                }

                .play_module, .play_quiz {
                    .text {
                        font-weight: 500;
                    }
                }

                .anticon {
                    position: relative;
                    padding-left: 18px;

                    @include extra-small-mobile {
                        padding-left: 12px;
                    }

                    &.anticon-lock {
                        display: none;
                    }
                }

                &.locked {
                    color: $neutral_400;
                    cursor: auto;

                    &:hover {
                        .module_title {
                            color: inherit;
                        }
                    }

                    .anticon-lock {
                        display: inline-block;
                    }

                    .module_right {
                        > span {
                            &:hover {
                                color: inherit;
                            }
                        }
                    }
                }

                .module_left {
                    flex: 1;
                }

                &:hover {
                    border-color: #E2E8F0;
                }

                .module_right {
                    color: #687489;

                    > span {
                        cursor: pointer;
                        margin-right: 25px;

                        &:hover {
                            color: var(--link-color);
                        }
                    }

                    .ant-spin {
                        padding-left: 10px;
                        margin-right: 8px;
                    }
                }

                .module_title {
                    color: #687489;
                    cursor: pointer;

                    .anticon-loading-3-quarters {
                        position: relative;
                        bottom: 0;
                    }

                    .anticon-check-circle {
                        color: #52C41A;
                        font-size: 1rem;
                    }
                }
            }
        }

        /**
        Change these to be dynamic program image
         */
        &.program_0 {
            .program_vector {
                background-image: url("/assets/images/program/banner.png");
            }
        }

        &.program_1 {
            .program_vector {
                background-image: url("/assets/images/program/banner1.png");
            }
        }

        &.program_2 {
            .program_vector {
                background-image: url("/assets/images/program/banner2.png");
            }
        }

        &.program_3 {
            .program_vector {
                background-image: url("/assets/images/program/banner3.png");
            }
        }

        &.program_4 {
            .program_vector {
                background-image: url("/assets/images/program/banner4.png");
            }
        }

        &.program_5 {
            .program_vector {
                background-image: url("/assets/images/program/banner5.png");
            }
        }

        &.program_6 {
            .program_vector {
                background-image: url("/assets/images/program/banner6.png");
            }
        }

        &.program_7 {
            .program_vector {
                background-image: url("/assets/images/program/banner7.png");
            }
        }

        &.program_8 {
            .program_vector {
                background-image: url("/assets/images/program/banner8.png");
            }
        }

        &.program_9 {
            .program_vector {
                background-image: url("/assets/images/program/banner9.png");
            }
        }
    }

    .program_cards {
        display: flex;
        //justify-content: space-between;
        //justify-content: space-evenly;
        padding: 30px 0;
        //flex-wrap: wrap;

        .program_card {
            max-width: 331px;
            background: #FFFFFF;
            border-radius: 10px;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);

            //todo: update this to be real program pic or convert shortname to render static pic
            .card_img {
                background-image: url("/assets/images/program/banner.png");;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

                &.banner_1 {
                    background-image: url("/assets/images/program/banner1.png");;
                }

                &.banner_2 {
                    background-image: url("/assets/images/program/banner2.png");;
                }

                &.banner_3 {
                    background-image: url("/assets/images/program/banner3.png");;
                }

                &.banner_4 {
                    background-image: url("/assets/images/program/banner4.png");;
                }

                &.banner_5 {
                    background-image: url("/assets/images/program/banner5.png");;
                }

                &.banner_6 {
                    background-image: url("/assets/images/program/banner6.png");;
                }

                &.banner_7 {
                    background-image: url("/assets/images/program/banner7.png");;
                }

                &.banner_8 {
                    background-image: url("/assets/images/program/banner8.png");;
                }

                &.banner_9 {
                    background-image: url("/assets/images/program/banner9.png");;
                }
            }
        }

        .card_head {
            width: 100%;
            height: 163px;
            background: #FAFAFA;
            border-radius: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .card_img {
                display: inline-block;
                width: 185px;
                height: 131px;
                border-radius: 10px;
            }

            .status {
                position: absolute;
                left: 24px;
                top: 18px;
                font-size: 12px;
                background-color: $marketing_blue;
                color: white;
                border-radius: 6px;
                padding: 6px 10px;

                &.new {
                    background-color: $marketing_blue;
                    top: 13px;
                    bottom: auto;
                }

                &.completed {
                    background-color: $success_color;
                    border-radius: 12px;
                    left: 24px;
                    bottom: 13px;
                    top: auto;
                    padding: 2px 10px;
                }

                &.overdue {
                    background-color: #ff7875;
                    border-radius: 12px;
                    left: 24px;
                    bottom: 13px;
                    top: auto;
                    padding: 2px 10px;
                }
            }
        }

        .card_body {
            > .title {
                font-family: "Inter", sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: 0.36px;
            }

            .due_date {
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.28px;
                color: #949597;

                .overdue.status {
                    font-size: 12px;
                    font-weight: bold;
                    background-color: #ff7875;
                    color: white;
                    border-radius: 10px;
                    padding: 2px 10px;
                    line-height: 20px;
                    position: absolute;
                    left: 0;
                    top: 8rem;
                }
            }

            .btn_wrap {
                > a {
                    &:hover {
                        text-decoration: none;
                    }

                    button {
                        border-radius: 6px;
                        font-family: "Inter", sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.32px;

                        &:not([disabled]) {
                            border-color: #1890FF;
                            color: #1890FF;

                            &:hover {
                                border: 1px solid var(--primary-color, #1890FF);
                                box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.19), 0 3px 3px -5px rgba(0, 0, 0, 0.23);
                                color: var(--primary-color, #1890FF);
                            }
                        }
                    }
                }
            }
        }
    }
}
