#signup_page, #forgot_page, #reset_page {
    &.login-process-page {
        height: 100%;
    }

    .form_wrap {
        width: 100%;
        border-radius: 3px;
        box-sizing: border-box;
        padding: 0 20px;

        .welcome {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            margin-bottom: 41px;
        }

        .login-form {
            .ant-form-item {
                margin-bottom: 41px;
                max-height: 54px;

                .ant-form-item-control-input {
                    .ant-input-affix-wrapper {
                        &.account_not_found {
                            background-color: #fff;
                            border-color: #ff4d4f;

                            &:hover, &:focus {
                                box-shadow: none;
                            }

                            .anticon-user {
                                color: #ff4d4f;
                            }
                        }
                    }
                }

                &.ant-form-item-has-error {
                    .ant-input-affix-wrapper {
                        &:hover, &:focus, &.ant-input-affix-wrapper-focused {
                            border: 1px solid #ff4d4f;
                            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                        }
                    }
                }

                &.usr_lgn_fb {
                    .input_pwd {
                        border: 1px solid #ff4d4f;

                        .anticon-lock {
                            color: #ff4d4f;
                        }
                    }
                }
            }

            .account_not_found:not(.usr_vrf_fail) {
                margin-top: -38px;
                margin-bottom: 15px;

                .ant-alert-info, .ant-alert-error, .ant-alert-warning {
                    padding: 0;
                    background: none;
                    border: none;

                    .ant-alert-message {
                        color: #FF4D4F;
                    }
                }
            }
        }
    }

    .signup_form {
        > .field_wrap_inner.checkbox_field {
            margin-top: 1rem;

            > label {
                margin-bottom: 0;
            }
        }

        .field_wrap_inner {
            position: relative;

            .hints_wrap {
                cursor: pointer;
                position: absolute;
                right: -28px;
                top: 8px;
                transition: ease-in-out .3s;

                :hover {
                    color: #000;
                }

                @include mobile {
                    right: -24px;
                }
            }
        }
    }

    .forgot_page_inner {
        .forgot_password_submit_success_text_container, .forgot_password_submit_success_link {
            font-size: 16px;
        }
    }

    .reset-form {
        .ant-form-item-explain-error {
            white-space: pre-line;
        }

        .ant-form-item-margin-offset {
            margin-bottom: 0 !important;
        }
    }

    .sec_err {
        margin-top: 1rem;

        p, h1, h2, h3, h4, h5 {
            margin: 0;
        }
    }

    .form_footer {
        margin: 0 auto 24px;
        font-size: 12px;
        max-width: 400px;
        width: 100%;
        text-align: center;
        -webkit-box-align: center;
        align-items: center;
        border-radius: 3px;

        li a {
            color: #0052CC;
            cursor: pointer;

            &:hover {
                color: #0065ff;
            }
        }

        ul {
            display: flex;
            justify-content: center;
        }

        ul, li {
            list-style: none !important;
            padding: 0;
            margin: 0;
        }

        li:not(:last-of-type) {
            padding-right: 20px;
            position: relative;

            &::after {
                content: "";
                height: 3px;
                width: 3px;
                border-radius: 50%;
                background: #0052CC;
                display: inline-block;
                position: absolute;
                top: calc(50% - 3px / 2);
                right: 10px;
            }
        }
    }

    .page_header {
        text-align: left;
        padding: 0 0 20px;
        color: rgb(255, 255, 255);
        max-width: 476px;
        margin: auto;
    }

    .login_process_page_content {
        max-width: 476px;
        margin: auto;
    }

    .logo_wrap {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        height: 100%;
        min-height: 70px;
        width: 100%;
        max-width: 280px;
    }

    .full_width_btn_wrap {
        &.space {
            margin: 1rem 0;
        }

        button {
            width: 100%;
            height: auto;
            line-height: 40px;
            justify-content: center;
        }
    }

    .separator {
        color: rgb(107, 119, 140);
        padding-top: 16px;
        margin-top: 32px;
        border-top: 1px solid rgb(213, 216, 222);
        font-size: 14px;
        text-align: center;
        line-height: 20px;
    }

    .field_wrap_inner {
        button {
            background: transparent;

            &.not-clickable {
                cursor: default;
            }
        }

        > div {
            margin-top: 0;
        }
    }

    .recaptcha_wrap {
        margin-top: 1.25rem;

        div {
            margin: 0 auto;
            justify-content: center;
        }
    }

    .page_inner {
        display: flex;
        height: 100%;
        min-height: 100vh;

        .form_container {
            flex: 1;
            display: flex;
            align-items: center;

            @media screen and (max-width: 62rem) {
                width: 100%;
            }

            @include _mobile {
                min-width: 400px;
            }
        }

        .bg_wrap {
            flex: 1;
            max-width: 800px;
            background-repeat: no-repeat;
            background-color: transparent;
            background-size: cover;
            background-position: center;

            @media screen and (max-width: 62rem) {
                display: none;
            }
        }
    }

    .checkbox_wrap {
        margin-top: 1rem;
    }

    .link {
        color: #0052CC;
        cursor: pointer;

        &:hover {
            color: #0065ff;
        }
    }

    .read_term_wrap {
        margin-top: 1.25rem;
    }

    .field_wrap_inner {
        > div:nth-child(2) {
            margin-top: 5px;
        }
    }

    .form_helper {
        .hint_wrap {
            align-items: center;

            > span[role=presentation] {
                height: 15px;
                width: 15px;
                margin-right: 2px;
            }
        }

        span[role='img'] {
            height: 15px;
            width: 15px;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        p::first-letter {
            text-transform: capitalize;
        }

        > div {
            display: flex;
            color: rgb(107, 119, 140);
        }

        button {
            div > span {
                padding: 0;
                color: rgb(107, 119, 140);
            }
        }
    }

    #login_fac_page {
        font-family: Lato, Helvetica, Arial, sans-serif;

        .title {
            margin-bottom: 41px;

            #id_login_title {
                text-align: left;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #131A2A;
            }
        }

        .subtitle {
            #id_verification_subtitle {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #000000;
            }
        }

        .ant-input-affix-wrapper {
            &:hover {
                border-color: #40a9ff;
            }

            &:focus {
                border-color: #40a9ff;
                box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
            }

            &::placeholder {
                font-style: normal;
                font-weight: lighter;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #3C3C3C;
            }
        }

        .forgot_password_link {
            text-align: right;
            padding-top: 10px;
            margin-bottom: 16px;
            display: flex;
            justify-content: right;

            #id_forgot_password_link {
                border: none;
                background: transparent;
                color: #3C3C3C;
                box-shadow: none;
                padding-right: 0;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        #verify {
            .title {
                margin-bottom: 0;

                #id_verify_title {
                    text-align: left;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 32px;
                    letter-spacing: 0.02em;
                    color: #1890FF;
                    padding-bottom: 8px;
                }
            }

            .inner_wrapper {
                display: block;
                margin-top: 20px;

                input {
                    height: 54px;
                }

                .resnd-verf-code {
                    text-align: right;
                    padding-top: 10px;
                    margin-bottom: 16px;
                    display: flex;
                    justify-content: right;

                    #id-resnd-verf-code {
                        border: none;
                        background: transparent;
                        color: #3C3C3C;
                        box-shadow: none;
                        padding-right: 0;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .verf_code_alrt {
                    margin-bottom: 15px;

                    .ant-alert-error {
                        padding: 0;
                        background: none;
                        border: none;

                        .ant-alert-message {
                            color: #FF4D4F;
                        }
                    }

                    .ant-alert-info {
                        .ant-alert-message {
                            color: #58a55c;
                        }
                    }
                }


            }

            #basic_verification_code {
                height: 54px;
            }
        }
    }
}

.modal_body_inner {
    .light_text {
        color: #607d8b;
    }
}

p.hints_content_render {
    width: 100%;
    max-width: 500px;
    margin-bottom: 0;
    font-size: 14px;

    p:last-of-type {
        margin-bottom: 0;
    }
}

/**
  Prevent body scroll to bottom bounce - make sure banner is fixed and full height
 */
body {
    overscroll-behavior-y: none;
}
