.assets-page {
    padding-bottom: 25px;

    .assets-section {
        background: #FFFFFF;
        margin: 20px 20px 20px 0;
        padding: 30px 25px;
        border-right: 6px;

        &.assets-video-section {
            min-height: inherit;
        }

        &.sac-school-assets-section {
            .assets-section-heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                .asset-heading-text {
                    max-width: 570px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    .title {
                        font-family: "Inter", sans-serif;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        span {
                            svg {
                                color: #949597;
                            }
                        }
                    }
                    .sub-title {
                        font-family: "Inter", sans-serif;
                        font-size: 12px;
                    }
                }
                .asset-view-button {
                    button {
                        padding: 28px 16px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        &:not([disabled]) {
                            border-color: #1890FF;
                            color: #1890FF;
                        }
                    }
                }
            }
            &.download-active {
                .assets-section-heading {
                    .title {
                        span {
                            svg {
                                color: #1890FF;
                            }
                        }
                    }
                }
            }

        }
    }

    .title {
        /* text */


        /*width: 171px;*/
        height: 28px;

        /* Heading 20 / bold */

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */


        /* Character / Title .85 */

        color: rgba(0, 0, 0, 0.85);


        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .sub-title {
        /* Co-branded assets allow you to add your own logo to the asset along with the Fortinet logo. */
        /* Body 02/reg */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        letter-spacing: 0.02em;

        /* Neutral/800 */

        color: #313232;


        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .asset-heading-action {
        /* Secondary button */
        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 16px;
        gap: 8px;

        width: 204px;
        height: 54px;

        /* Marketing/White */
        background: #FFFFFF;
        /* Primary/Brand */

        border: 1px solid #1890FF;
        /* drop-shadow / button-secondary */

        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);

        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;

        color: #1890FF;
        border-radius: 6px;
    }

    .asset-heading-action:hover {
        cursor: pointer;
    }

    .underline-span {
        text-decoration: underline;
    }

    .asset-icon {
        font-size: 24px;
        color: #949597;
        padding-top: 12px;
        padding-right: 15px;
    }

    .asset-format-text {
        color: #949597;
    }

    .asset-label {
        width: 260px;
    }

    .asset-section-row {
        padding: 15px 0 20px 10px;
    }

    /*Ant-d style over-rides*/
    .ant-btn {
        box-shadow: none;
    }

    .ant-btn:not(.sac-view-assets):hover {
        background: #1890FF;
        color: #FFFFFF;
    }

    .ant-card-body {
        background: #FAFAFA;
        min-height: 119px;
    }

    .allow-asset-download {
        display: flex;
        gap: 6px;
        margin: 10px 0;
    }
}