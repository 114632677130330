$primary_color: #1890FF;

.auth_panel {
    font-family: 'Inter', sans-serif;

    .page-header-headings {
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        letter-spacing: 0.02em;
        margin-bottom: 16px;

        h1 {
            margin: 0;
            color: rgba(0, 0, 0, .85);
            font-size: 1.8rem;
        }
    }

    .authentication_page_container {
        .authentication-page-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
        }

        .authentication-form-container {
            padding: 0 0 25px;

            form {
                @media screen and (min-width: 1280px) {
                    max-width: 60%;
                }
            }

            .ant-form-item {
                label::after {
                    content: none;
                }
            }

            .ant-form-item-label > label {
                margin-top: 2%;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                text-align: left;
                color: rgba(0, 0, 0, 0.85);
                white-space: break-spaces;
                word-break: break-word;
                height: auto;
                min-width: 250px;
            }

            .view-metadata-link {
                margin-bottom: 40px;

                > .ant-form-item-control {
                    padding-left: 15px;

                    .view-metadata-btn {
                        margin-bottom: 5px;

                        .ant-btn {
                            line-height: initial;
                            padding: 5px 23px !important;
                            max-width: 155px;

                            &:hover {
                                background-color: $primary_color;
                                border-color: $primary_color;
                                color: #FFFFFF;
                                box-shadow: none;
                                transition: 0.4s;
                            }
                        }
                    }

                    .metadata-link-description {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(0, 0, 0, 0.45);
                    }

                    .download-metadata-link {
                        color: $primary_color;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                        text-align: center;

                        &:before {
                            content: url("/assets/images/paperclip-small.png");
                            margin-right: 8px;
                        }
                    }
                }
            }

            .authentication_attributes_label {
                margin-bottom: 1rem;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                text-align: left;
                color: rgba(0, 0, 0, 0.85);
            }

            .authentication-guide-container {
                @media screen and (min-width: 1280px) {
                    max-width: 800px;
                }

                .ant-form-item {
                    margin-bottom: 1rem;

                    &.auth-form-table-item {
                        width: calc(50% - 16px);
                        display: inline-block;
                        padding-right: 16px;
                    }

                    &.auth_table_heading {
                        margin-bottom: 5px;

                        span {
                            font-weight: 500;
                            font-size: 16px;
                            padding-left: 0;
                            width: 100%;
                            max-height: 32px;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            line-height: 1.5;
                            color: #495057;
                            margin-bottom: 0;
                        }
                    }

                    input {
                        border: 1px solid #d9d9d9;
                        border-radius: 0 2px 2px 0;
                        width: 100%;
                        max-height: 40px;
                        padding-top: 5px;
                        padding-bottom: 5px;

                        &::placeholder {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 22px;
                            color: #495057;
                        }
                    }
                }
            }

            .metadata-url-textarea {
                .ant-upload-list-text-container {
                    width: 100%;
                    min-width: 250px;
                    max-width: 50vw;
                }

                .dropzone {
                    background-color: rgba(0, 0, 0, .02);
                    border: 1px dashed #d9d9d9;
                    width: 350px;
                    padding: 15px 20px;
                    display: inline-block;
                    border-radius: 8px;
                    cursor: pointer;
                    transition: all .2s cubic-bezier(.4, 0, .2, 1);

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.18);
                        border-style: solid;
                    }
                }

                > .ant-form-item-label {
                    margin-right: 20px;
                }

                > .ant-form-item-control {
                    .metadata-textarea-formitem {
                        textarea {
                            max-width: 515px;
                            width: 100%;
                            height: 98px;
                            background: #fff;
                            border: 1px solid #d9d9d9;
                            box-sizing: border-box;
                            border-radius: 2px;

                            &::placeholder {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 22px;
                                color: rgba(0, 0, 0, 0.25);
                            }
                        }

                        &.ant-form-item-has-error {
                            textarea {
                                border: 1px solid #ff4d4f;

                                &:focus {
                                    box-shadow: 0 0 0 0.2rem rgb(171 56 84 / 25%);
                                }
                            }

                            .help {
                                color: #ff4d4f;
                            }
                        }
                    }
                }

                div.help {
                    color: rgba(0, 0, 0, 0.65);
                    font-size: 1rem;
                }

                .metadata-textarea-description {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.45);
                }
            }
        }

        .sso_steps {
            border: 1px solid #E2E2E2;
            border-radius: 8px;
            padding: 20px 25px;
            margin-bottom: 25px;
            min-height: 222px;
            background: #fff;

            h6 {
                font-size: 1rem;
                font-weight: 500;
            }

            .sso_steps_label {
                line-height: 2rem;
                font-size: 1rem;
                color: #313232;
            }

            .input_wrap {
                display: flex;
                max-width: 762px;
                width: 100%;
                margin-bottom: 15px;

                > input {
                    flex: 1;
                    border: 1px solid #D9D9D9;
                    background: #F5F5F5;
                    color: #334155;
                    padding: 10px 12px;
                    height: 40px;
                }

                button {
                    width: 69px;
                    border-radius: 2px;
                    color: #fff;
                    font-weight: 500;
                    text-align: center;
                    padding: 0;
                }
            }
        }

        .field-row {
            .auth-form-table-item {
                margin-bottom: 15px;

                > span {
                    font-weight: 500;
                    font-size: 16px;
                    display: inline-block;
                }

                input {
                    border: 1px solid #D9D9D9;
                    width: 100%;
                    line-height: 2rem;
                    padding: 4px 11px;
                    font-weight: 400;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                    cursor: default;
                }
            }

            .col-6:nth-child(1) input {
                background: #F5F5F5;
                cursor: not-allowed;
            }

            .col-6:nth-child(2) input {
                background: none;
            }
        }
    }

    button.copy-btn {
        transition: all .2s ease-in-out;
        background-color: var(--button-color, #1890FF);
    }

    .save_changes_btn {
        display: flex;
        margin-top: 50px;

        button {
            border-radius: 6px;

            &.primary {
                margin-right: 16px;
            }
        }
    }

    .ant-input {
        line-height: 2rem;
    }
}

.empty-confirm-modal {
    .ant-modal-confirm-body {
        display: flex;

        > .ant-modal-confirm-content {
            margin-top: 0;
        }
    }
}
