#root {
    .body_outer {
        display: flex;
        flex-direction: column;

        .body {
            flex: 1;
        }
    }

    .body {
        background: #fff;
        padding: 35px;

        @include mobile {
            padding: 25px 15px;
        }

        @include extra-small-mobile {
            padding: 0;
        }
    }

    .ant-layout {
        &.collapsed-layout, &.expanded-layout {
            margin-left: 0;
        }
    }

    .main_page {
        margin: 0 auto;

        .bg_card {
            background: $bg_grey;
            border-radius: 10px;
            padding: 32px 230px 45px 20px;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
        }

        .greeting {
            position: relative;

            p.greeting_desc {
                margin-bottom: 34px;
                max-width: 640px;
            }

            .banner_img,
            .sac_banner_img {
                display: inline-block;
                position: absolute;
                right: 0;
                top: -33px;
                width: 313.64px;
                height: 313.97px;
                background: url("/assets/images/banner_img.png") no-repeat center;
                background-size: cover;
                filter: drop-shadow(6px 6px 16px rgba(0, 0, 0, 0.15));

                @include tablet {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .sac_banner_img {
                background: url("/assets/images/sac_banner.png") no-repeat center;
            }

            .capital {
                text-transform: capitalize;
            }

            .greeting_txt {
                font-weight: 600;
                font-size: 28px;
                max-width: 640px;
            }

            .ant-btn.secondary {
                background: #307FE2;
                color: white;
                border: none;
            }
        }

        > section {
            margin-bottom: 75px;

            &.keep_learning {
                padding: 0;
            }
        }

        .sac-content-section {
            .sac_banner_wrap {
                border: #DEE0E2 1px solid;
                border-radius: 6px;
                margin-top: 20px;
                padding: 32px;
                display: flex;
                align-items: center;

                h4 {
                    font-family: Inter, sans-serif;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 150%;
                }

                p {
                    font-size: 16px;
                    letter-spacing: 0.02em;
                }

                .right_part {
                    flex: 1;
                }

                .left_icon {
                    background: url("/assets/images/program/banner.png") no-repeat;
                    background-position: center;
                    display: inline-block;
                    width: 120px;
                    height: 120px;
                    margin-right: 46px;
                }
            }
        }
    }

    .header {
        background: $header_bg;
        padding-left: 15px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
        z-index: 999;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobile {
            padding: 0 20px;
        }

        .avatar {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 20px;
            border-left: 1px solid $light_grey;
            height: 40px;

            .ant-avatar {
                color: black;
                //background-color: $light_grey;
                background: #e2e8f0;
                font-weight: normal;
            }

            .ant-avatar-string {
                text-transform: uppercase;
            }

            .ant-badge {
                margin: 0 31px 0 0;

                .anticon-bell {
                    font-size: 22px;
                }
            }
        }

        .avatar_wrap {
            display: flex;
            align-items: center;

            h6 {
                font-weight: 500;
                font-size: 1rem;
            }

            .avatar_right {
                padding-left: 10px;

                h6 {
                    text-transform: capitalize;
                    margin-bottom: 0;
                }
            }
        }
    }

    .side_nav {
        overflow: auto;
        height: calc(100vh - 78px);
        position: sticky;
        left: 0;
        top: 78px;
        background: white;
        box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.03);
        padding-top: 80px;

        @include desktop {
            height: calc(100vh - 65px);
            top: 65px;
        }

        .ant-menu-title-content {
            display: flex;
            align-items: center;
            color: $neutral_800;
            padding-left: 10px;
            font-weight: 500;

            > .anticon {
                padding-right: 20px;
                font-size: 1rem;
            }
        }

        .ant-menu-item-group {
            .ant-menu-item-group-title {
                white-space: nowrap;
                font-size: 12px;
                color: #949597;
                letter-spacing: 1.8px;
            }
        }

        &.ant-layout-sider-collapsed {
            .logo {
                width: 100%;
                background-size: contain;
                margin: 16px auto 80px;
            }

            .ant-menu-item.ant-menu-item-selected::before {
                top: 5px;
                left: -15px;
            }

            .ant-menu-item-group {
                .ant-menu-item-group-title {
                    visibility: hidden;
                    transition: none;
                }
            }
        }

        .ant-menu {
            font-family: "Inter", sans-serif;

            > .ant-menu-item-group {
                margin-bottom: 20px;
            }

            .ant-menu-item {
                position: relative;
                width: 100%;
                margin-inline: 0;
                border-radius: 0;

                &:hover {
                    color: var(--link-color);

                    .side_nav_txt {
                        color: var(--link-color);
                    }
                }

                &:after {
                    content: '';
                    //visibility: hidden;
                    position: absolute;
                    width: 3px;
                    height: 100%;
                    right: 0;
                }

                &.ant-menu-item-selected {
                    // for the icon inherited color
                    color: var(--nav-bar-color);
                    background-color: rgba(var(--nav-bar-color-rgb), 0.25);

                    .anticon {
                        color: var(--nav-bar-color);
                    }
                }

                .anticon {
                    position: relative;
                    z-index: 1;
                    font-size: 20px;
                    color: #949597;
                }
            }

            a {
                position: relative;
            }
        }
    }

    .corner_logo_wrap {
        height: 100%;
        display: flex;
        align-items: center;

        .hamburger {
            display: flex;
            align-items: center;
            margin-right: 12px;

            .hamburger_icon {
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 50%;
                transition: background-color ease-in-out;
            }

            .anticon {
                font-size: 20px;
                font-weight: bold;
                color: $neutral_500;
            }
        }

        .sub-account-switch-button {
            margin-left: 58.86px;
        }

        .logo {
            width: 163px;
            height: 33px;
            background-repeat: no-repeat;
            background-color: transparent;
            background-size: contain;
            background-position: center;

            &.forticloud_logo {
                width: 163px;
                height: 36px;
                margin: 0 63px 0 0;
            }
        }
    }

    .footer_logo {
        width: 109px;
        min-height: 40px;
        height: 100%;
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: contain;
        background-position: center;
        display: inline-block;
    }

    .footer_txt {
        cursor: pointer;
        font-size: 12px;

        &:hover {
            color: var(--link-color);
        }
    }
}

.cyber_sec {
    margin-top: 119px;

    > h4 {
        margin-bottom: 20px;
    }

    .spotcyberthreats_listitem > div {
        background: #FFFFFF;
        border: 1px solid #EFEFEF;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 20px;
        height: 230px;

        .spot_icon {
            margin: 20px 0;
            height: 30px;
            width: 30px;
            display: inline-block;
            background-repeat: no-repeat;
            background-size: cover;

            &.spot_icon_one {
                background-image: url("/assets/images/svg/icon_01.svg");
            }

            &.spot_icon_two {
                background-image: url("/assets/images/svg/icon_02.svg");
            }

            &.spot_icon_three {
                background-image: url("/assets/images/svg/icon_03.svg");
            }

            &.spot_icon_four {
                background-image: url("/assets/images/svg/icon_04.svg");
            }

            &.spot_icon_five {
                background-image: url("/assets/images/svg/icon_05.svg");
            }

            &.spot_icon_six {
                background-image: url("/assets/images/icon/icon_06.png");
            }
        }
    }
}

.ant-avatar-string {
    text-transform: uppercase;
}
