.local-users-page {
    padding-top: 24px;

    .no-verified-domains {
        margin-bottom: 20px;
    }

    .users-header-banner {
        .users-page-header {
            display: flex;
            justify-content: space-between;
        }

        .users-header-title {
            .total-tag {
                font-family: "Inter", sans-serif;
                font-weight: 500;
                font-size: 16px;
                background-color: transparent;
                border-radius: 10px;
                text-align: center;
                border: none;
                display: flex;
                padding-left: 0;
            }
        }

        .users-header-controls-container {
            .ant-btn.ant-input-search-button {
                width: auto;
                padding: 4px 15px;

                .anticon-search {
                    font-size: 18px;

                    svg {
                        color: #1890FF;
                    }
                }
            }

            .search-field-label {
                .ant-input-affix-wrapper {
                    overflow: hidden;

                    > input {
                        height: auto;
                    }
                }

                .ant-form-item-label > label {
                    &::after {
                        content: '';
                    }
                }
            }

            .import-users-menu {
                display: flex;
                gap: 10px;
                font-size: 14px;
                align-items: center;
            }
        }
    }

    #user-search-form {
        border-radius: 6px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        margin: 12px 0;
        padding: 16px 32px 16px 16px;


        .user-search-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .user-search-action-btn {
                border: 1px solid #1890FF;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                border-radius: 6px;
                color: #1890FF;
            }

            .ant-space-item {
                .ant-form-item {
                    margin-bottom: 0;

                    label {
                        height: 100%;
                    }
                }
            }
        }
    }

    .ant-table {
        min-height: calc(50vh - 4em);
    }
}

.local-users-csv-upload-page {
    background-color: #FFFFFF;

    #user-csv-upload-form {

        .user_example_csv_placeholders {
            .ant-collapse-header {
                padding-left: 0;
            }

            .ant-collapse-content-box {
                padding-left: 0;
            }
        }

        @media screen and (min-width: 1083px) {
            .user_csv_upload_dragger, .lang_selector_form_item {
                max-width: 60%;
            }
        }
    }

    .local-users-csv-upload-content {
        h4 {
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            margin: 12px 0;
        }

        .user-upload-preview-table {
            .upload-user-result-status {
                color: red;

                &.admin_users_upload_result_code_4, &.admin_users_upload_result_code_6 {
                    color: green;
                }
            }
        }

        a[download="ExampleCsvFile"] {
            button {
                padding-left: 0;
            }
        }
    }

    #user-csv-upload-form_example_link {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid #1890ff;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
        border-radius: 6px;
        width: 150px;
        height: 32px;
        margin-bottom: 8px;

        span {
            color: #1890ff;

            svg {
                vertical-align: baseline;
            }
        }
    }

    .lang_selector_form_item {
        max-width: inherit;
    }
}

.ant-dropdown {
    .ant-dropdown-menu {
        .usr_actn_del {
            min-width: 178px;
        }
    }
}

.ant-input {
    height: 40px;
}

.userForm_modal {
    .ant-modal-content {
        .ant-select-selection-placeholder, ::placeholder {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #949597;
        }

        .ant-modal-close {
            top: 2.5%;
        }
    }
}
