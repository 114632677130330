.appearance_panel {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.85);

    .app_tab_panel {
        padding: 0;

        h4 {
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            margin: 0 0 12px;
        }

        p {
            font-weight: 300;
            font-size: 14px;
            line-height: 120%;
            color: rgba(0, 0, 0, 0.6);
        }

        .ant-tabs-nav {
            &::before {
                border: none;
            }

            .ant-tabs-tab {
                height: 24px;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                padding: 4px 0;
            }
        }

        .app_img_panel,
        .app_colors_panel {
            .reset-button {
                border-radius: 6px;
                background: #fff;
                border: 1px solid rgba(0, 0, 0, 0.06);
                box-sizing: border-box;
                box-shadow: 5px 5px 20px rgb(0 0 0 / 6%);
                padding: 16px;
                display: flex;
                width: 100%;
            }

            ul {
                display: flex;
                list-style-type: none;
                padding: 0;
                margin-bottom: 0;

                .listitem {
                    padding: 0;
                    margin-bottom: 24px;
                }
            }
        }

        .app_img_panel {
            border-radius: 6px;
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.06);
            box-sizing: border-box;
            box-shadow: 5px 5px 20px rgb(0 0 0 / 6%);
            padding: 16px 16px 0;
            margin-bottom: 24px;

            ul .listitem > div {
                background: #fff;
                border: 0.5px solid #d8d8d8;
                box-sizing: border-box;
                box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
                border-radius: 2px;
                margin-right: 32px;

                .ant-upload-drag {
                    width: 100%;
                    min-height: 89px;
                    border-top: 1px dashed #d9d9d9;
                    border-bottom: 1px dashed #d9d9d9;
                    border-radius: 2px;
                    background: transparent;

                    .upload_text {
                        margin-bottom: 8px;

                        span {
                            font-size: 20px;
                        }
                    }

                    .ant-upload-text {
                        font-size: 12px;
                    }

                    > .ant-upload-btn {
                        background: #FAFAFA;
                    }

                }

                .img_wrap {
                    height: 100%;

                    img {
                        width: 104px;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .avatar-uploader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top: 1px dashed #d9d9d9;
                    border-bottom: 1px dashed #d9d9d9;
                    border-radius: 2px;

                    .ant-upload-list-picture-card-container {
                        min-height: 89px;
                        width: 100%;
                        margin: 0;

                        .ant-upload-list-item-list-type-picture-card {
                            border: none;
                            padding: 0;

                            .ant-upload-list-item-info {
                                min-height: 87px;
                            }

                            .ant-upload-list-item-actions {
                                right: 10px;
                                bottom: -25px;
                                left: auto;
                                opacity: 1;
                                top: auto;
                                transform: inherit;

                                button {
                                    opacity: 1;
                                    background-color: transparent;
                                    box-shadow: none;

                                    .anticon-delete {
                                        color: rgba(0, 0, 0, 0.45);
                                        width: 12px;
                                        height: 12.5px;
                                    }
                                }
                            }
                        }
                    }

                    > .ant-upload {
                        width: 100%;
                        max-height: 90px;
                        padding: 0;
                        margin: 0;
                        border: none;
                    }
                }

                .image_header {
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0;
                    padding: 8px 16px;
                    font-weight: normal;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .upload_button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 16px;
                    width: 100%;

                    .ant-upload-list-text {
                        display: none;
                    }

                    button {
                        border: none;
                        color: rgba(48, 127, 226, 1);
                        box-shadow: none;
                        background-color: transparent;
                        position: static;
                        width: 50px;
                        height: 22px;
                        left: 0;
                        top: 0;
                        font-size: 14px;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        padding: 0;
                    }
                }
            }
        }

        .app_colors_panel {
            .color-settings {
                border-radius: 6px;
                background: #fff;
                border: 1px solid rgba(0, 0, 0, 0.06);
                box-sizing: border-box;
                box-shadow: 5px 5px 20px rgb(0 0 0 / 6%);
                padding: 16px 16px 0;
                margin-bottom: 24px;

                .container {
                    padding: 0;

                    ul {
                        padding: 0 16px;
                    }
                }
            }
        }

        .color-picker {
            background: #fff;
            border: 0.5px solid rgba(216, 216, 216, 1);
            box-sizing: border-box;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
            border-radius: 2px;
            padding: 8px 16px;
            height: 100%;
            margin-right: 32px;

            .color-header {
                display: flex;
                max-height: 27px;

                .color-box {
                    width: 30px;
                    margin-left: auto;
                    margin-right: 0;
                    border-radius: 4px;
                }

                h5 {
                    margin: 0;
                    font-size: 16px;
                    line-height: 24px;
                    white-space: nowrap;
                    font-weight: 400;

                    @media screen and (min-width: 540px) and (max-width: 1200px) {
                        font-size: 13px;
                        white-space: normal;
                    }
                }
            }

            .alpha-bar {
                display: flex;
                width: 100%;
                height: 27px;
                position: relative;
                margin: 24px 0 16px 0;
                border-radius: 4px;

                div {
                    border-radius: 4px;
                    margin: 0;
                }
            }

            .hue-bar {
                display: flex;
                width: 100%;
                height: 8px;
                position: relative;
                margin: 0;

                .hue-horizontal {
                    border-radius: 100px;

                    .picker {
                        width: 8px;
                        height: 8px;
                        border-radius: 8px;
                        background: transparent;
                        border: 2px solid #fff;
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
                        filter: drop-shadow(0 2px 4px rgba(31, 41, 55, 0.06)) drop-shadow(0 4px 6px rgba(31, 41, 55, 0.1));
                    }
                }
            }

            .edit_box {
                display: flex;
                margin: 16px 0 8px;

                > div {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 28px;
                    padding: 4px 6px;
                    border: 1px solid #e5e7eb;
                    box-shadow: 0 1px 2px rgba(31, 41, 55, 0.08);
                    border-radius: 4px;
                    background: #fff;

                    &::before {
                        content: "#";
                        position: relative;
                        z-index: 1;
                        color: #9ca3af;
                    }

                    input {
                        position: relative;
                        width: 90%;
                        font-size: 14px;
                        line-height: 20px;
                        border: 0;
                        left: 6px;
                    }
                }
            }
        }
    }
}

#notification-template {
    .appr-notification-form-items, .appr-notification-form-btns {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
        padding: 32px 24px;
        margin: 12px 0;

        label {
            font-weight: 500;
            line-height: 30px;
            font-size: 20px;
        }

        .appr-notification-form-placeholders {
            .ant-collapse-header, .ant-collapse-content-box {
                padding: 0;
            }
        }

        &.button-text {
            .ant-form-item {
                width: 50%;
                padding-right: 12px;
            }
        }

        &.sender-items {
            .ant-form-item {
                width: 50%;

                &:first-child {
                    margin-right: 24px;
                }
            }
        }
    }

    .appr-notification-form-btns {
        button {
            margin-right: 16px;
        }
    }
}

.reset-modal {
    .ant-modal-body {
        .ant-modal-confirm-body {
            .anticon {
                display: none;
            }
        }
    }
}
