.sac-content-section {
    padding-top: 70px;

    .page-controls {

    }

    .module-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .ant-collapse-header {
            display: flex;
            align-items: center;
            padding-right: 32px;

            .ant-collapse-header-text {
                order: 1;

                .header_text {
                    display: flex;
                    align-items: center;

                    h4 {
                        font-family: Inter, sans-serif;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 36px;
                        letter-spacing: 0.02px;
                    }

                    img {
                        margin-right: 8px;
                    }

                    p {
                        font-family: Inter, sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.02px;
                    }
                }
            }

            .ant-collapse-expand-icon {
                padding: 0;
                order: 2;

                .anticon {
                    font-size: 24px;
                    width: 45.5px;
                    height: 45.5px;
                    background: rgba(0, 0, 0, 0.06);
                    border-radius: 50%;
                    justify-content: center;
                }
            }
        }

        .ant-collapse-content-box {
            padding: 32px;

            .sac_intro {
                ol, ul {
                    padding-bottom: 32px;
                    margin-bottom: 0;

                    &:last-child {
                        padding-bottom: 0;
                    }

                    li {
                        font-family: 'Inter', sans-serif;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.6);
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.02px;
                        margin-left: 16px;
                    }
                }

                ul {
                    li {
                        list-style: disc;
                    }
                }

                ol{
                    padding-left: 0;

                    li {
                        list-style: auto;
                    }

                }

                p {
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.6);
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.02px;
                    padding-bottom: 32px;
                    margin-bottom: 0;
                }
            }

            .module-wrapper {
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding-bottom: 32px;

                &:last-child {
                    padding-bottom: 0;
                }

                .module-tags {
                    display: flex;
                    gap: 12px;
                    color: #00000099;
                    margin-bottom: 20px;

                    > span {
                        font-size: 14px;
                        font-family: Inter, sans-serif;
                        line-height: 20px;
                    }
                }

                .sac_course_summary {
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    color: #313232;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.02px;
                }

                .module-content {
                    display: flex;
                    gap: 20px;
                    justify-content: space-between;
                    align-items: flex-start;

                    .module-details {
                        h4 {
                            color: #000000D9;
                            font-weight: 600;
                            font-size: 24px;
                        }

                        p {
                            color: #313232;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }

                    .module-download {
                        &:hover {
                            .sac-download-button:not(:disabled) {
                                span {
                                    svg {
                                        transform: rotate(90deg);
                                        transition: 0.6s ease;
                                    }
                                }
                            }
                        }

                        .sac-download-button {
                            padding: 28px 16px;
                            border-color: #1890FF;
                            color: #1890FF;

                            > span {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                justify-content: space-between;

                                svg {
                                    transform: rotate(0deg);
                                    transition: 0.6s ease;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
