body {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    //line-height: 12px;
    line-height: 24px;

    p, div {
        font-size: 14px;
    }

    ul, li {
        list-style: none;
        padding: 0;
    }
}

.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.primary {
        padding: 8px 12px;
        background: var(--primary-color);
        border-radius: 2px;
        border: none;
        color: white;

        &.launch-admin {
            padding: 5px 16px;
        }

        &.full-width {
            width: 100%;
            height: auto;
            padding: 15px 0;
        }

        &:hover {
            background: #FFFFFF;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            text-decoration: none;
        }
    }

    &.link {
        min-height: 34px;
    }

    &.default {
        padding: 5px 16px;
        width: 221px;
        min-height: 34px;
        border: none;
        background: var(--primary-color);
        border-radius: 2px;
        color: white;

        &:hover {
            background: #FFFFFF;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            text-decoration: none;
        }
    }

    &.auto-width {
        width: auto;
    }

    &.icon {
        background: #FFFFFF;
        box-shadow: 0px 10px 14px rgba(10, 4, 60, 0.04);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .anticon {
            width: 24px;
            height: 24px;
        }
    }

    &.secondary {
        padding: 5px 16px;
        min-height: 34px;
        background: #FFFFFF;
        border: 1px solid #131A2A;
        box-sizing: border-box;
        border-radius: 2px;

        &.no-frame {
            border: none;
            box-shadow: none;
            background: transparent;

            &:hover {
                color: var(--primary-color);
                border: none;
                box-shadow: none;
            }
        }

        &:hover {
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .19), 0 3px 3px -5px rgba(0, 0, 0, .23);
        }

        &:active {
            box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .19), 0 3px 3px -5px rgba(0, 0, 0, .23);
        }
    }
}

.ant-form {
    .ant-input-prefix {
        margin-right: 10px;
    }
}

.ant-input {
    height: 44px;
    padding: 8px 12px;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;
}

.ant-layout-footer {
    background: $grey_bg;
    padding: 18px 20px;
    align-items: center;
    position: relative;

    .footer_logo {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        //float: right;
    }
}

.card {
    border: none;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

.hidden {
    display: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--link-color);
}

.ant-tabs-ink-bar {
    color: var(--link-color);
}

a {
    color: var(--link-color);
}

.ant-breadcrumb-link a {
    color: rgba(0, 0, 0, 0.6);

    &:hover {
        color: var(--link-color);
    }
}

.ant-layout-sider-light .ant-layout-sider-trigger {
    transition: .15s ease-in-out;

    &:hover {
        background: #EFEFEF;
    }
}
