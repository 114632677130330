.module_page {
    height: 100%;
    display: flex;
    flex-direction: column;

    .module_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.main_page,
.program_detail {
    font-family: Inter, sans-serif;
    max-width: 1200px;
    margin: 0 auto;

    h1, h2, h3, h4, h5, h6 {
        font-family: Inter, sans-serif;
    }

    .detail_separator {
        background-color: #949597;
        display: inline-block;
        height: 5px;
        width: 5px;
        border-radius: 50%;
    }

    .with_separator {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    .my_learning,
    .program_container {
        .program_wrap {
            .program_head {
                background-color: #F5F5F5;
                border: none;
                padding: 24px;

                .program_vector {
                    position: relative;
                    margin-right: 35px;
                }

                .program_info,
                .program_progress {
                    margin: auto 5px;
                }

                .program_info {
                    h5 {
                        font-family: "Inter", sans-serif;
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0.48px;
                        line-height: 150%;
                    }
                }

                .program_date {
                    color: #313232;

                    .program_timeinfo {
                        font-family: "Inter", sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.32px;
                        line-height: 150%;
                    }
                }
            }

            .program_body {
                &:has(.module-panel) {
                    padding: 0 !important;
                }

                .program_content {
                    min-height: auto;

                    .show-more-option {
                        button {
                            margin: 0 auto;
                        }
                    }

                    .modules {
                        .ant-collapse {
                            border: none;
                            background-color: #FFFFFF;

                            .ant-collapse-item {
                                border: none;

                                .module_title {
                                    color: #000000;
                                }

                                &:has(.start_now_btn) {
                                    background-color: #F7F9FB;

                                    .module_title {
                                        color: #1C1C1C;
                                    }
                                }

                                &.module-panel {
                                    &.ant-collapse-item-active {
                                        .arrow-icon {
                                            svg {
                                                transform: rotate(180deg);
                                            }
                                        }

                                        .ant-collapse-header-text {
                                            .module_right {
                                                @include mobile {
                                                    button {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }

                                        //.module {
                                        //    padding-bottom: 0;
                                        //}
                                    }

                                    .ant-collapse-content {
                                        border-top: none;
                                        background: none;

                                        .ant-collapse-content-box {
                                            margin-left: 60px;
                                            padding-top: 0;
                                            padding-bottom: 32px;
                                            font-size: 16px;
                                            color: rgb(0, 0, 0, 0.8);

                                            @include mobile {
                                                padding-bottom: 22px;
                                            }

                                            .mobile_course_btn {
                                                button {
                                                    display: none;

                                                    @include mobile {
                                                        display: flex;
                                                        border-radius: 6px;
                                                        font-family: "Inter", sans-serif;
                                                        font-weight: 400;
                                                        line-height: 24px;
                                                        letter-spacing: 0.32px;
                                                        max-height: 32px;
                                                        padding: 6px 16px;
                                                        font-size: 14px;
                                                        margin: 15px 0 0;
                                                    }

                                                    &:not([disabled]).ant-btn-secondary {
                                                        border-color: var(--button-color, #1890FF);;
                                                        color: var(--button-color, #1890FF);;

                                                        &:hover {
                                                            border: 1px solid var(--primary-color, #1890FF);
                                                            box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.19), 0 3px 3px -5px rgba(0, 0, 0, 0.23);
                                                            color: var(--primary-color, #1890FF);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .module {
                                        border-bottom: none;

                                        &.unlocked {
                                            .module_title {
                                                span.course-name {
                                                    &:hover {
                                                        color: var(--link-color);
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                        }

                                        svg {
                                            width: 26px;
                                            height: 26px;
                                        }

                                        &.locked {
                                            svg {
                                                color: #949597;
                                                height: 22.5px;
                                            }
                                        }

                                        .arrow-icon {
                                            svg {
                                                width: 32px;
                                                height: 32px;

                                                path {
                                                    fill: #F5F5F5;

                                                    &:last-of-type {
                                                        fill: #131A2A;
                                                    }
                                                }
                                            }
                                        }

                                        .module_details {
                                            color: #949597;
                                            margin-left: 60px;
                                            font-family: Inter, sans-serif;
                                            font-size: 14px;
                                            font-weight: 500;
                                            line-height: 20px;
                                            white-space: nowrap;

                                            @media screen and (max-width: 400px) {
                                                display: block;

                                                span {
                                                    display: block;
                                                    white-space: nowrap;
                                                    margin-right: 16px;

                                                    &.detail_separator {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }

                                        .module_title {
                                            display: flex;
                                            align-items: center;
                                            font-family: Inter, sans-serif;
                                            font-size: 20px;
                                            font-weight: 500;
                                            line-height: 30px;
                                            letter-spacing: 0.02em;
                                            padding-bottom: 12px;

                                            > span {
                                                &:first-of-type {
                                                    margin-right: 22px;
                                                }

                                                &:nth-of-type(3) {
                                                    margin-left: 5px;
                                                }
                                            }
                                        }

                                        .module_right {
                                            margin: 0 10px;

                                            button {
                                                border-radius: 6px;
                                                font-family: "Inter", sans-serif;
                                                font-size: 16px;
                                                font-weight: 400;
                                                line-height: 24px;
                                                letter-spacing: 0.32px;

                                                &:not([disabled]).ant-btn-secondary {
                                                    border-color: var(--button-color, #1890FF);;
                                                    color: var(--button-color, #1890FF);;

                                                    &:hover {
                                                        border: 1px solid var(--primary-color, #1890FF);
                                                        box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.19), 0 3px 3px -5px rgba(0, 0, 0, 0.23);
                                                        color: var(--primary-color, #1890FF);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.show-more-panel {
                                    .ant-collapse-header {
                                        padding: 23.5px;

                                        .ant-collapse-header-text {
                                            margin: 0 auto;

                                            .ant-btn {
                                                padding: 0;

                                                span {
                                                    font-size: 20px;
                                                    line-height: 30px;
                                                    font-weight: 600;
                                                    letter-spacing: 0.02em;
                                                }

                                                .anticon {

                                                    @include _extra-small-mobile {
                                                        display: none;
                                                    }

                                                    svg {
                                                        width: 32px;
                                                        height: 32px;

                                                        path {
                                                            fill: #FFFFFF;

                                                            &:last-of-type {
                                                                fill: #131A2A;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.no-short-description {
                                    cursor: auto;

                                    .ant-collapse-header-text {
                                        cursor: auto;
                                        flex: auto;

                                        .module {
                                            .module_left,
                                            .module_title {
                                                cursor: auto;
                                            }
                                        }
                                    }
                                }
                            }

                            hr {
                                margin: 0 24px;

                                &:last-child {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
