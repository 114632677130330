.setting_page {
    .setting-section-container {
        .setting-section-head {
            display: flex;
            align-items: center;

            .setting-section-title {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 0.02em;
                color: $default_setting_title_color;
            }

            .line-through {
                border: 0.5px solid #E6E6E6;
                width: 100%;
                height: 0;
                margin-bottom: 24px;
                margin-left: 36px;
            }
        }
    }

    .account_wrap {
        margin-bottom: 30px;

        > .col-12 {
            margin-bottom: 28px;
        }

        .label {
            font-weight: 500;
            //margin-bottom: 25px;
        }
    }

    .setting_wrap {
        .ant-form-item {
            margin-bottom: 24px;
        }

        .ant-form-item-label {
            text-align: left;
            max-width: 100%;

            label {
                font-family: Inter,sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 19.6px;
                letter-spacing: 0.02em;
                text-align: left;

                &::after {
                    content: none;
                }
            }
        }

        .ant-input {
            height: inherit;
        }

        &.setting_wrap_mfa {
            .setting_wrap_mfa_inner {
                display: flex;
                justify-content: space-between;
            }

            .label {
                font-style: normal;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .setting-section-container {
        max-width: 550px;
        width: 100%;
    }

    .warning_txt {
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        color: rgb(0, 0, 0, 0.85);
        margin-bottom: 18px;

        .icon {
            color: $danger_color_light;
            position: relative;
            bottom: 3px;
            padding-right: 8.4px;
        }
    }

    .ant-select-single {
        .ant-select-selector {
            height: 54px;
            display: flex;
            align-items: center;
            padding: 0;

            .ant-select-selection-search {
                input {
                    height: 100%;
                }
            }
        }

        .ant-select-selection-item {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            padding: 0 16px;
        }

        &.ant-select-disabled {
            .ant-select-selection-item {
                color: rgba(0, 0, 0, 0.25);
                background-color: #f5f5f5;
                border-color: #d9d9d9;
                box-shadow: none;
                cursor: not-allowed;
                opacity: 1;
            }

            > .ant-select-arrow {
                display: none;
            }
        }
    }
}

.modal_wrap {
    padding: 25px 50px;

    .warning_txt {
        margin-bottom: 28px;
        color: rgba(0, 0, 0, 0.6);
    }

    h5 {
        font-size: 20px;
    }

    p {

    }

    .btn_wrap {
        display: flex;
        flex-wrap: nowrap;

        .secondary {
            margin-right: 1rem;
        }
    }
}

.password_reset_wrap {
    .btn_wrap {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        .btn_change_password {
            padding: 12px 16px;
            border-radius: 6px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            min-height: 48px;
        }

        .label {
            margin-right: 60px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.ant-select-dropdown {
    .stng_drpdwn_lng {
        .ant-select-item-option-content {
            height: 54px;
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            padding: 15px 4px;
        }
    }
}

.ant-modal.notification-modal {
    max-width: 384px;

    .ant-modal-header {
        border: none;

        .ant-modal-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
        }
    }

    .ant-modal-body {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 0 24px;
    }

    .ant-modal-footer {
        border: none;
        text-align: -webkit-right;
    }
}
