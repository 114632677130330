@import "partials/progressbar";
@import "partials/campaigntable";
@import "partials/welcomebanner";
@import "partials/fortiphish";

$summary_title_text: #718296;
$summary_num_text: #32325d;
$description_text: #525f7f;
$float_arrow_color: #48d597;

$ftnt_red: #da291c;
$ftnt_green: #48d597;
$ftnt_blue: #1890ff;
$ftnt_teal: #2cccd3;

$block_margin_top: 114px; // 90px(welcome banner height) + 24px(margin between banner and table)
$block_margin_top_mobile: 198px;

body {
    width: auto !important;
    font-size: initial;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#page-my-index {
    background: #F8FAFC;

    #region-main-box {
        padding-top: 0px !important;

        .block_custom_dashboard {
            > .card-body {
                padding-top: 0px !important;

                > .card-text {
                    margin-top: 0px !important;
                }
            }
        }
    }
}

#app {
    height: 100%;
}

hr.hr {
    display: block !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.block_custom_dashboard {
    border: none !important;
    background: transparent !important;
}

.dashboard-page {
    h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        margin: 0 0 12px;
    }
}