.admin_access_tab {
    .page-header {
        border-radius: 6px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        box-shadow: 5px 5px 20px rgb(0 0 0 / 6%);
        margin: 0 32px 16px 0;
        padding: 16px 32px 16px 16px;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 150%;
        }

        p {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
        }
    }

    .admin-table-action {
        button {
            padding-left: 0;
        }
    }

    .ant-table {
        min-height: calc(50vh - 4em);
    }
}
