@import "variables";
@import "overrides";
@import "mixins";
/**
pages
 */
@import "./home";
@import "./accessdenied";

/**
components
 */
@import "./components/header";
@import "./components/program";
@import "./components/empty";
@import "./components/moodlealert";

@import "mobile";

body {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    margin: 0;
}

#root {
    height: 100%;

    > .ant-layout {
        min-height: 100vh;
    }

    .ant-layout {
        background: white;
    }
}

.text-center {
    .ant-btn {
        margin: 0 auto;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.ant-modal {
    &.ant-modal-confirm {
        .ant-modal-confirm-btns {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.ant-input-affix-wrapper > input.ant-input:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.maintenance-window {
    border: 1px solid #f7dbd8;

    .ant-notification-notice-message {
        font-size: 1.1rem;
    }

    p {
        font-size: 1rem;
    }
}

.pure_html {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.5;

    p, h1, h2, h3, h4, h5, h6 {
        margin: 0 0 10px 0;
        padding: initial;
    }

    li {
        list-style: initial;
    }

    a {
        text-decoration: initial;
        color: initial;
    }
}
