$banner_to_edge: 32px;
$banner_to_top: calc(-90px - 24px); // 90px(banner height) + 24px(margin between banner and block)
$banner_to_top_mobile: -198px;

#welcome_banner_container {
    width: 100%;
    padding: 15px;
    margin-bottom: 32px;
    left: 0;
    background: #fff;

    .welcome-text-container {
        .welcome-logo {
            background-image: url("/assets/images/dashboard/welcome_icon.png");
            float: left;
            display: inline-flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0;
            position: static;
            width: 72px;
            height: 72px;
            left: 0;
            top: 0;

        }

        .welcome-text {
            height: 72px;
            padding-left: 24px;
            display: table-cell;
            vertical-align: middle;

            .welcome-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                color: rgba(0, 0, 0, 0.85);
                position: static;
            }

            .welcome-content {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }

    .welcome-data {
        display: table;

        .total-campaigns-container {
            width: 60%;
            //display: inline-block;
            display: table-cell;
            vertical-align: middle;
            height: 72px;
            padding-right: 32px;

            .total-campaigns-title {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.45);
                right: 0;
                text-align: right;
                flex-direction: row-reverse;
            }

            .total-campaigns-number {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 28px;
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.85);
                flex-direction: row-reverse;
            }
        }

        .total-students-container {
            width: calc(40% - 0px);
            text-align: end;
            display: table-cell;
            vertical-align: middle;
            height: 72px;
            padding: 0 32px;

            .total-students-title {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.45);
                flex-direction: row-reverse;
            }

            .total-students-number {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 28px;
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.85);
                flex-direction: row-reverse;
            }
        }

        .welcome-data-divider {
            height: 32px;
            vertical-align: middle;
            margin: auto;
            top: 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    #welcome_banner_container {
        padding: 0;
        margin-bottom: 0;

        .welcome-text-container {
            .welcome-logo {
                width: 43px;
                height: 43px;
                background-size: contain;
            }

            .welcome-text {
                padding-left: 12px;

                .welcome-title {
                    font-style: normal;
                    font-size: 18px;
                }
            }
        }

        .welcome-data {
            margin: 15px 55px 0 55px;
            max-width: inherit;
            flex: auto;

            .total-campaigns-container {
                padding-right: 15px;
                padding-left: 0;

                .total-campaigns-title {
                    font-size: 12px;
                }

                .total-campaigns-number {
                    font-size: 18px;
                    color: #000;
                }
            }

            .total-students-container {
                padding-left: 15px;
                padding-right: 0;

                .total-students-title {
                    font-size: 12px;
                }

                .total-students-number {
                    font-size: 18px;
                    color: #000;
                }
            }
        }
    }
}
