.domain-page {
    .table-container {
        padding: 30px;
        margin-bottom: 20px;
        background: #fff;

        .table {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &.sub-domain-table-container {
            margin-bottom: 30px;
        }

        .table-title-container {
            padding: 0 0 15px;

            .table-title {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                color: rgba(0, 0, 0, 0.85);
            }

            .table-sub-title {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 120%;
                color: rgba(0, 0, 0, 0.6);
                margin-top: 12px;
            }
        }

        .domain-notification-text,
        .subdomain-notification-text {
            //display: inline-block;
            min-width: 45%;
            max-width: 60%;
            box-sizing: border-box;
            box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
            border: 1px solid #ffe58f;
            border-radius: 2px;
            background-color: #fffbe6;
            background-image: url("/assets/images/admin/domains/orange-exclamation-icon.png");
            background-repeat: no-repeat;
            background-position-y: 13px;
            background-position-x: 17px;
            background-size: 14px 14px;
            margin: 0 0 15px;
            padding: 10px 10px 10px 40px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);

            .highlight-in-notification {
                text-decoration: underline;
            }

            button.close {
                top: -10px
            }

            &.default {
                //display: flex;
                //justify-content: space-between;

                button.close {
                    display: flex;
                    align-items: flex-start;
                    position: relative;
                    top: -2px;
                    box-shadow: none !important;
                }
            }

            &.error {
                background-image: url("/assets/images/admin/domains/red-failed-icon.png");
                background-color: #fff1f0;
                border: 1px solid #ffccc7;
            }

            &.success {
                background-image: url("/assets/images/admin/domains/green-checkmark-icon.png");
                background-color: #f6ffed;
                border: 1px solid #b7eb8f;
            }
        }

        .domain-notification-text {


        }

        .create-domain-form-container,
        .create-subdomain-form-container {
            form.mform {
                display: flex;
                flex-wrap: nowrap;
                padding: 0 0 15px;

                .form-group.row {
                    margin: 0;
                    display: inline-block;

                    .col-form-label {
                        display: none !important;
                    }

                    .form-inline {
                        padding: 0;
                        //display: inline-block;
                        max-width: 100%;

                        input.form-control {
                            background: url("/assets/images/admin/domains/earth-icon.png") no-repeat no-repeat left;
                            background-position-x: 12px;
                            padding-left: 35px;
                            width: 345px;
                            border: 1px solid #d9d9d9;
                            box-sizing: border-box;
                            border-radius: 2px 0 0 2px;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            transition: 0.4s;

                            &:focus {
                                border: 1px solid var(--button-color, #1890ff);
                                box-shadow: none;
                            }

                            &#subdomain_name_input {
                                width: 242px;
                            }
                        }

                        select#id_domainid {
                            height: 40px;
                            left: 242px;
                            top: 0;
                            background-image: url("/assets/images/admin/domains/down-arrow.png");
                            background-color: #fff;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: normal;
                            line-height: 24px;
                            color: #000;
                            border: 1px solid #d9d9d9;
                            cursor: pointer;
                            transition: 0.4s;

                            &:hover {
                                background-color: #d9d9d9;
                            }
                        }

                        input.btn.btn-primary {
                            padding: 0 16px;
                            background: var(--button-color, #1890ff);
                            border: 1px solid var(--button-color, #1890ff);
                            box-sizing: border-box;
                            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
                            border-radius: 0 2px 2px 0;
                            transition: 0.4s;

                            &:hover {
                                color: var(--button-color, #1890ff);
                                background: #fff;
                            }
                        }
                    }

                    .ant-input {
                        min-width: 420px;
                        height: $general_admin_input_height;
                    }

                    .ant-select {
                        min-width: 200px;
                        border-radius: 0;
                        background: none;
                        padding: 0;

                        .ant-select-selector {
                            .ant-select-selection-item {
                                display: flex;
                                align-items: center;
                            }
                        }
                    }

                    .ant-input-affix-wrapper {
                        padding-top: 0;
                        padding-bottom: 0;

                        &.input-ht-lg {
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                        }
                    }
                }

                .ant-select-selector input {
                    min-height: inherit;
                }
            }
        }

        .domains-list-container {
            margin-top: 18px;

            .table.domains-list {
                .list-title {
                    background: #fafafa;
                    box-shadow: -18px 0 0 -17px rgba(0, 0, 0, 0.06);
                    border-radius: 0;
                    border-top: none;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.85);
                    padding-left: 15px;

                    &.list-title-first {
                        box-shadow: 0 0 0 -17px rgba(0, 0, 0, 0.06);
                    }
                }

                td {
                    border-top: 1px solid rgba(0, 0, 0, 0.06);
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    letter-spacing: 0.02em;
                    color: #0d2138;
                    vertical-align: middle;

                    > .row {
                        align-items: center;
                        display: flex;
                        flex-wrap: nowrap;
                    }

                    .copy-text-button {
                        background-image: url("/assets/images/admin/domains/copy-icon.png");
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        vertical-align: text-bottom;
                        margin-left: 5px;
                        cursor: pointer;
                        transition: 0.8s;
                    }

                    .copy-token-icon :hover {
                        cursor: pointer;
                    }

                    .tooltiptext {
                        //visibility: hidden;
                        //opacity: 0;
                        display: none;
                        font-size: 12px;
                        width: 60px;
                        background-color: #555;
                        color: #fff;
                        text-align: center;
                        border-radius: 6px;
                        padding: 5px;
                        position: absolute;
                        z-index: 1;
                        margin-left: -40px;
                        margin-top: -50px;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            margin-left: -5px;
                            border-width: 5px;
                            border-style: solid;
                            border-color: #555 transparent transparent transparent;
                        }
                    }

                    .status-pill {
                        display: flex;
                        width: 100px;
                        align-items: center;
                        justify-content: center;
                        padding: 4px;
                        position: static;
                        height: 23px;
                        left: 10px;
                        top: 10px;
                        border-radius: 10px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        letter-spacing: 0.02em;
                        color: #fff;

                        &.green-pill {
                            background: #52c41a;
                        }

                        &.red-pill {
                            background: #ff7875;
                        }
                    }

                    .primary-domain-radio {
                        background-image: url("/assets/images/admin/domains/primary-domain-radio.png");
                        vertical-align: middle;
                        background-position-y: center;
                        background-repeat: no-repeat;
                        background-color: transparent;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        border: none;
                        box-shadow: none;

                        &.primary-domain-selected {
                            background-image: url("/assets/images/admin/domains/primary-domain-selected.png");
                        }
                    }

                    .primary-domain-text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                        letter-spacing: 0.02em;
                        color: #1890ff;
                    }

                    .delete-item-link {
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        vertical-align: middle;
                        background-image: url("/assets/images/admin/domains/delete-icon.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        border: none;
                        background-color: transparent;
                        transition: .4s;

                        &:not(.disabled):hover {
                            background-color: #ced4da;
                            border-radius: 50%;
                        }

                        &.disabled {
                            cursor: not-allowed;
                            background-image: url("/assets/images/admin/domains/DeleteOutlined.png");
                        }
                    }

                    .verify-item-link {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        height: 32px;
                        text-align: center;
                        color: var(--button-color, #1890ff);;
                        border: 1px solid var(--button-color, #1890ff);;
                        box-sizing: border-box;
                        border-radius: 2px;
                        padding: 5px 10px;
                        margin-right: 10px;
                        transition: 0.4s;

                        > span {
                            display: flex;
                            align-items: center;
                            height: 20px;
                        }

                        &:hover {
                            background-color: var(--button-color, #1890ff);;
                            color: white;
                            text-decoration: none;
                        }

                        &.disabled-link {
                            background: #F5F5F5;
                            border: 1px solid #D9D9D9;
                            border-radius: 2px;
                            cursor: not-allowed;
                            opacity: 0.5;
                            text-decoration: none;

                            span {
                                color: rgba(0, 0, 0, 0.25);
                            }


                        }
                    }
                }
            }

            .copy-token-icon {
                position: relative;
                display: inline-block;
            }

            .no-items-text {
                text-align: center;
                margin-bottom: 15px;
                font-style: normal;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
            }
        }

        .btn.btn-primary {
            height: 41.67px;
            border-radius: 0 6px 6px 0;
        }
    }

    input#id_domainid {
        //height: $general_admin_input_height;
    }

    .ant-form-item .ant-select .ant-select-selector {
        border-radius: 0;
        box-sizing: initial;
        width: initial;
    }

    .subdomain-input {
        border-radius: $general_btn_radius 0 0 $general_btn_radius;
    }

    #page-local-users-domains {
        #region-main-box {
            padding-top: 0 !important;

            #region-main {
                padding-top: 0;
            }
        }
    }
}

#page_modal_container,
#delete_modal_container {
    .modal-body {
        .highlight-in-notification {
            text-decoration: underline;
        }
    }
}
