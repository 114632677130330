#root {
    > .fc_menu {
        @include extra-small-mobile {
            width: min-content;
        }
    }

    /** Sign Up page **/

    #signup_page, #forgot_page, #reset_page {
        .page_inner {
            @include mobile {
                .bg_wrap {
                    display: none;
                }
                .form_container {
                    max-width: 100%;
                }
            }

            @include _mobile {
                .form_container {
                    min-width: 320px;
                }
            }

            .continue_wrap {
                .welcome span {
                    font-weight: bold;
                }
            }
        }
    }

    /*****Header CSS*****/
    .header {
        padding-left: 16.73px;
        box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.03);
        z-index: 999;
        height: 78px;
        position: sticky;
        top: 0;
        width: 100%;

        @include desktop {
            height: 65px;
            padding: 0 22.98px;
        }
        @include tablet {
            padding: 0 14px;
        }

        .corner_logo_wrap {
            height: 78px;
            @include desktop {
                height: 65px;
            }

            .hamburger {
                line-height: 0;
                height: 100%;

                .hamburger_icon {
                    height: 17px;
                    width: 17px;

                    .anticon {
                        font-size: 17px;
                    }
                }
            }

            .logo {
                @include desktop {
                    width: 136.69px;
                    margin-top: 0;
                }
            }
        }

        .avatar {
            border-left: none;

            .ant-badge {
                .anticon-bell {
                    @include mobile {
                        font-size: 20px;
                    }
                }
            }

            .ant-avatar-circle {
                width: 50px;
                height: 50px;
                line-height: 50px;
                @include desktop {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                }

                .ant-avatar-string {
                    font-size: 18px;
                    @include desktop {
                        font-size: 16px;
                    }
                }
            }

            .avatar_right {
                @include mobile {
                    display: none;
                }
            }
        }
    }

    /** Footer Panel **/
    .ant-layout-footer {
        padding: 22px 24px 14px;
        background: #ffffff;
        border: 1px solid #EFEFEF;

        .footer_txt_row {
            margin: 0;
            @include mobile {
                flex-wrap: nowrap;
            }

            .footer-panel {
                padding: 0px 12px;
                font-family: Inter, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.02em;

                .footer_txt {
                    color: rgba(0, 0, 0, 0.8);

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &:first-child {
                    padding-left: 32px;
                    @include desktop {
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    .ant-layout {
        position: relative;

        .body {
            .bread_wrap {
                .ant-breadcrumb {
                    span:last-child {
                        font-weight: 500;
                    }
                }
            }
        }

        &.expanded-layout {
            .main_page {
                @include tablet {
                    padding-left: 0;
                }

                .cyber_sec {
                    .spotcyberthreats_listitem {
                        > div {
                            height: 230px;
                            margin-bottom: 24px;
                            @include desktop {
                                height: 244px;
                            }
                            @include tablet {
                                height: 330px;
                            }
                        }
                    }
                }
            }

            .program_page {
                .program_container {
                    .ant-tabs-content-holder {
                        .program_cards {
                            .col-12 {
                                .program_card {
                                    .card_body {
                                        @include tablet {
                                            padding-right: 23px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .quiz_page {
                .quiz_wrap_inner {
                    .quiz_body {
                        ol {
                            li {
                                @include mobile {
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.collapsed-layout {
            @include mobile {
                margin-left: 0;
            }

            .side_nav {
                .ant-menu-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .ant-menu-title-content {
                        display: none;
                    }
                }
            }

            .main_page {
                @include tablet {
                    max-width: 852px;
                }
            }

            .program_page {
                > .program_container {
                    @include tablet {
                        margin: auto;
                        max-width: 365px;
                    }
                }
            }

            .program_detail {
                .banner {
                    padding: 36px 0 41px 84px;

                    @include desktop {
                        padding: 38px 38px 38px 44px;
                    }
                    @include mobile {
                        padding: 41px 0 38px 37px;
                    }
                    @include extra-small-mobile {
                        padding: 24px;
                        margin: 0;
                    }
                }

                .program_detail_content {
                    @include mobile {
                        padding-left: 35px;
                    }
                    @include extra-small-mobile {
                        padding-left: 18px;
                    }
                }
            }

            .setting_page {
                @include tablet {
                    margin-left: 44px;
                }
                @include mobile {
                    margin-left: 25px;
                }
                @include extra-small-mobile {
                    margin-left: 2px;
                }
            }
        }

        .side_nav {
            padding-top: 20px;
            z-index: 10;

            @include mobile {
                position: fixed;
            }

            .sub_txt {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                letter-spacing: .15em;
                text-transform: uppercase;
                color: #818181;
                padding: 16px 0;
                white-space: nowrap;
                pointer-events: none;
            }

            @include mobile {
                box-shadow: 0 6px 5px rgba(0, 0, 0, .06), 0 5px 6px rgba(0, 0, 0, .13);
            }

            &.ant-layout-sider-collapsed {
                .sub_txt {
                    visibility: hidden;
                }

                @include mobile {
                    min-width: 0 !important;
                    max-width: 0 !important;
                    width: 0!important;
                }
            }

            &:not(.ant-layout-sider-collapsed) {
                @include mobile {
                    position: fixed;
                }
            }
        }

        /***Main Page CSS ****/
        .main_page {
            /*Banner Panel */
            .greeting {
                padding-top: 53px;
                margin-bottom: 57px;

                @include desktop {
                    padding-left: 10px;
                    padding-top: 40px;
                }

                @include tablet {
                    padding: 13px 0 0 30px;
                    margin-bottom: 21px;
                }

                @include mobile {
                    padding: 13px 0 0 16px;
                    margin-bottom: 21px;
                }

                @include extra-small-mobile {
                    padding: 38px 0 0 0;
                    margin-bottom: 21px;
                }

                .greeting_txt {
                    font-family: Inter, sans-serif;
                    font-size: 32px;
                    line-height: 48px;
                    font-weight: 700;
                    letter-spacing: 0.02em;
                    color: #000000;
                    margin-bottom: 16px;

                    @include extra-small-mobile {
                        font-size: 28px;
                        line-height: 42px;
                        font-weight: 600;
                    }

                    @include desktop {
                        max-width: 490px;
                    }
                    @include tablet {
                        max-width: 454px;
                    }
                }

                .greeting_desc {
                    font-family: Inter, sans-serif;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    font-weight: 400;
                    letter-spacing: 0.02em;
                    margin-bottom: 30px;

                    @include desktop {
                        max-width: 400px;
                    }
                    @include tablet {
                        max-width: 454px;
                    }
                    @include mobile {
                        margin-bottom: 44px;
                    }
                    @include extra-small-mobile {
                        margin-bottom: 31px;
                    }
                }

                .banner_img {
                    margin-top: 12px;

                    @include desktop {
                        width: 260px;
                        height: 260px;
                    }

                    @include tablet {
                        display: none;
                    }
                }
            }

            /*Cyber threats Panel */
            .cyber_sec {
                font-size: 1.5rem;
                line-height: 150%;
                max-width: 1020px;
                margin-bottom: 25px;

                @include mobile {
                    margin-top: 50px;
                }

                h4 {
                    margin-bottom: 24px;
                }

                .spotcyberthreats_listitem {
                    > div {
                        height: 230px;
                        margin-bottom: 24px;

                        @include desktop {
                            height: 244px;
                        }
                    }

                    .spotcyberthreats_content {
                        padding: 40px 5px 0 17px;

                        @include desktop {
                            padding: 20px 13px 0px 28px;
                        }
                        @include mobile {
                            padding: 20px 13px 0px 32px;
                        }
                        @include extra-small-mobile {
                            padding: 28px 5px 0px 32px;
                        }

                        .spot_icon {
                            margin: 5px 0;
                        }

                        h5 {
                            font-weight: 600;
                            font-size: 1.5rem;
                            line-height: 133%;
                            margin: 12px 0;
                            color: #18191F;
                        }

                        p {
                            font-size: 16px;
                            line-height: 26px;
                            margin: 0;
                            color: #18191F;
                        }
                    }
                }

                @include extra-small-mobile {
                    .spotcyberthreats_panel {
                        overflow-x: scroll;

                        .spotcyberthreats_table {
                            width: 507px;

                            .spotcyberthreats_listitem {
                                > div {
                                    height: 260px;
                                }
                            }
                        }
                    }
                }
            }

            /*My Learning Panel */
            .my_learning {
                margin-bottom: 25px;

                .program_container {
                    @include mobile {
                        padding-left: 16px;
                    }

                    @include extra-small-mobile {
                        padding-left: 0px;
                    }

                    h4 {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 150%;
                        letter-spacing: 0.02em;
                        color: rgba(0, 0, 0, 0.85);
                        margin: 0;
                        padding-left: 21px;

                        @include mobile {
                            padding-left: 0px;
                        }

                        @include extra-small-mobile {
                            padding-left: 9px;
                        }
                    }

                    .program_list {
                        padding: 33px 0;

                        @include extra-small-mobile {
                            width: 100vw;
                            margin: 0 -15px;
                            padding: 39px 0;
                        }

                        .empty_data_wrap {
                            padding: 30px 43px;

                            .left_icon {
                                margin-right: 60px;

                                @include extra-small-mobile {
                                    display: none;
                                }
                            }

                            .right_part {
                                h6 {
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 26px;
                                    letter-spacing: 0.02em;
                                    color: #021442;
                                    margin-bottom: 8px;
                                }

                                p {
                                    font-size: 18px;
                                    line-height: 26px;
                                    align-items: center;
                                    letter-spacing: 0.02em;
                                    color: #687489;
                                    margin: 0;
                                }
                            }
                        }

                        .program_head {
                            @include mobile {
                                padding-left: 24px;
                                padding-right: 44px;
                                padding-top: 23.72px;
                            }

                            .program_vector {
                                top: 0;
                                background-size: contain;
                                background-position: center;

                                @include mobile {
                                    display: none;
                                }
                            }

                            .program_info {
                                h5 {
                                    color: #000000;
                                    font-size: 24px;
                                    line-height: 36px;
                                    letter-spacing: 0.02em;
                                    word-break: break-word;
                                    margin-bottom: 6px;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }

                                .program_timeinfo {
                                    @include desktop {
                                        display: block;

                                        span {
                                            display: block;
                                            white-space: nowrap;
                                            margin-right: 16px;
                                            margin-bottom: 6px;
                                            color: #313232;

                                            &.detail_separator {
                                                display: none;
                                            }

                                            &.status {
                                                width: fit-content;
                                                margin-left: 0 !important;
                                                margin-top: 2px;
                                                color: #FFFFFF;
                                            }
                                        }
                                    }
                                    @include desktop {
                                        .completion_info_status {
                                            display: flex;
                                            align-items: center;
                                        }
                                    }

                                    @include mobile {
                                        margin-top: 6px;
                                    }
                                }

                                .status {
                                    margin-left: 0;
                                }
                            }
                        }

                        .program_progress {
                            @include tablet {
                                display: none;
                            }
                        }

                        .program_body {
                            padding: 2.41px 24px 0 43px;
                            @include mobile {

                            }

                            li {
                                &.module {
                                    padding: 30px 0;
                                    align-items: center;

                                    .module_left {
                                        .module_title {
                                            font-size: 18px;
                                            line-height: 26px;
                                            letter-spacing: 0.02em;
                                            display: flex;
                                            align-items: center;

                                            .ant-progress {
                                                font-size: 22px;
                                                padding-left: 10px;
                                            }

                                            .anticon:not(.anticon-lock) {
                                                font-size: 22px;
                                                padding-top: 5px;
                                            }
                                        }
                                    }

                                    .module_right {
                                        font-size: 14px;
                                        line-height: 22px;
                                        letter-spacing: 0.02em;
                                        color: #94A3BB;
                                        margin-top: 5px;

                                        > span {
                                            @include extra-small-mobile {
                                                margin-right: 5%;

                                                .anticon {
                                                    margin-right: 4px;
                                                    padding-left: 0;
                                                }
                                            }

                                            .anticon {
                                                font-size: 22px;
                                            }
                                        }
                                    }

                                    @include mobile {
                                        display: block;
                                        padding: 16px 0 23.75px;
                                        &:first-child {
                                            padding: 28px 0 23.75px;
                                        }
                                        .module_right {
                                            margin-top: 11px;

                                            .play_module {
                                                .anticon-play-circle {
                                                    padding-left: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .btn_wrap {
                            padding: 10px 24px 24px 43px;

                            @include extra-small-mobile {
                                button {
                                    &.ant-btn.default {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /*Need help Panel */
            .need_help {
                margin-bottom: 45px;
                @include tablet {
                    margin-bottom: 24px;
                }
                @include mobile {
                    margin-bottom: 13px;
                }
                @include extra-small-mobile {
                    margin-bottom: -5px;
                }
                display: flex;
                display: none; //Hide the Need Help panel

                h6 {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: 0.02em;
                    color: #000000;
                }

                p {
                    margin-left: 16px;
                    margin-bottom: 0;
                }
            }
        }

        /*** Programs Page CSS ***/
        .program_page {
            h4 {
                font-weight: 600;
                font-size: 28px;
                line-height: 42px;
                letter-spacing: 0.02em;
                margin-bottom: 22px;
            }

            .program_container {
                .ant-tabs-nav {
                    margin-bottom: 0;

                    &::before {
                        border: none;
                    }

                    .ant-tabs-tab {
                        padding: 0 0 4px;

                        .ant-tabs-tab-btn {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }

                .ant-tabs-content-holder {
                    .program_cards {
                        padding: 0;
                        margin: 0 -12px;
                        width: 100%;

                        .program_row {
                            padding-left: 16px;
                            padding-right: 16px;
                            margin-bottom: 14px;

                            @include tablet {
                                padding: 0;
                            }

                            .program_card {
                                margin-left: 5px;
                                display: flex;
                                flex-direction: column;
                                margin-top: 26px;

                                .card_body {
                                    padding: 24px;
                                    height: auto;
                                    flex: 1;
                                    display: flex;
                                    flex-direction: column;

                                    h6 {
                                        font-family: Inter, sans-serif;
                                        font-size: 18px;
                                        line-height: 26px;
                                        color: rgba(0, 0, 0, 0.85);
                                        margin-bottom: 0;
                                    }

                                    .due_date {
                                        font-size: 14px;
                                        line-height: 20px;
                                        letter-spacing: 0.02em;
                                        color: #949597;

                                        @include extra-small-mobile {
                                            .overdue.status {
                                                top: 7rem;
                                            }
                                        }
                                    }

                                    .btn_wrap {
                                        margin: 28px 0 12px;

                                        button {
                                            padding: 12px 16px;
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                .program_card {
                                    @include mobile {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }

                        .col-lg-6 {
                            @include desktop {
                                max-width: 365px;
                            }
                        }

                        .empty_data_wrap {
                            padding: 30px 43px;
                            border: none;

                            .left_icon {
                                margin-right: 60px;

                                @include mobile {
                                    margin-right: 0;
                                    background-size: contain;
                                }

                                @include extra-small-mobile {
                                    display: none;
                                }
                            }

                            .right_part {
                                h6 {
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 26px;
                                    letter-spacing: 0.02em;
                                    color: #021442;
                                    margin-bottom: 8px;
                                }

                                p {
                                    font-size: 18px;
                                    line-height: 26px;
                                    align-items: center;
                                    letter-spacing: 0.02em;
                                    color: #687489;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        /*** Program Detail Page CSS ***/
        .program_detail {
            .banner {
                padding: 0 44px;

                @include desktop {
                    padding: 39px 44px 0px;
                }
                @include tablet {
                    padding: 38px 44px 0px;
                    margin: 0;
                }

                .banner_content {
                    align-items: center;

                    .program_card_img {
                        @include tablet {
                            display: none;
                        }
                    }
                }
            }

            .program_detail_content {
                padding-left: 9.77px;

                @include desktop {
                    padding-left: 12.77px;
                }
                @include tablet {
                    padding-left: 0px;
                }

                h5 {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 14px;

                    .anticon {
                        margin-right: 1.25rem;
                        @include desktop {
                            margin-right: 1.38rem;
                        }
                        @include mobile {
                            margin-right: 1.15rem;
                        }
                    }
                }

                > div {
                    &:first-child {
                        margin-bottom: 66px;
                        @include mobile {
                            margin-bottom: 44px;
                        }
                        @include extra-small-mobile {
                            margin-bottom: 42px;
                        }

                        ul {
                            padding-left: 60px;

                            li {
                                font-size: 14px;
                                line-height: 22px;
                                color: rgba(0, 0, 0, 0.8);

                                &.campaign {
                                    display: block;

                                    span {
                                        display: inline-flex;
                                        align-items: center;
                                    }
                                }
                            }
                        }
                    }
                }

                .modules {
                    margin-bottom: 0;

                    .program_wrap {
                        margin-top: 1.7rem;
                        @include mobile {
                            margin-top: 53px;
                        }

                        @include extra-small-mobile {
                            border: none;
                            margin-left: -22px;
                        }

                        .overview_info {
                            top: -65px;

                            @include mobile {
                                top: -33px;
                                left: 38px;
                                margin-top: -24px;
                            }
                        }

                        .program_body {
                            padding: 9px 24px 17px 44px;
                            @include desktop {
                                padding-left: 34.75px;
                            }
                            @include mobile {
                                padding: 22.74px 20px 10px 43px;
                            }

                            .program_content {
                                ul {
                                    margin-bottom: 0;

                                    li.module {
                                        padding: 30px 0;
                                        align-items: center;

                                        &:last-child {
                                            border-bottom: none;
                                        }

                                        @include mobile {
                                            display: block;
                                            padding: 0;
                                            .module_left {
                                                padding: 16px 0 11.25px 0;
                                            }
                                            .module_right {
                                                padding-bottom: 24.75px;

                                                > span {
                                                    margin-right: 11.07px;

                                                    .anticon {
                                                        padding-left: 0;
                                                    }
                                                }
                                            }
                                        }

                                        .module_title {
                                            font-size: 18px;
                                            line-height: 26px;
                                            display: flex;
                                            align-items: center;

                                            .ant-progress {
                                                font-size: 22px;
                                                padding-left: 10px;
                                            }

                                            .anticon:not(.anticon-lock) {
                                                font-size: 22px;
                                                padding-top: 5px;
                                            }
                                        }

                                        .module_right {
                                            margin-top: 5px;

                                            .anticon {
                                                font-size: 22px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /** Settings Page CSS ***/
        .setting_page {
            .setting-section-container {
                h5 {
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 42px;
                    letter-spacing: 0.02em;
                    color: #313232;
                    margin-bottom: 16px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                }

                .account_wrap {
                    margin-bottom: 24px;

                    button {
                        margin-top: 15px;
                    }
                }

                .setting_wrap {
                    .ant-row {
                        display: block;
                    }

                    .save_changes_btn {
                        margin-bottom: 32px;

                        .primary {
                            height: 48px;
                            border-radius: 6px;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            padding: 12px 16px;
                        }
                    }
                }

                &.delete_account {
                    padding-top: 32px;

                    .close_account_wrap {
                        p {
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }

        /*** Quiz Page CSS ***/
        .quiz_page {
            padding-left: 5px;
            @include mobile {
                padding-left: 7px;
            }

            .quiz_wrap {
                @include extra-small-mobile {
                    border: none;
                    box-shadow: none;
                }

                .quiz_wrap_inner {
                    .quiz_title {
                        margin-bottom: 36px;

                        .icon_wrap {
                            display: flex;
                            align-items: center;

                            .square {
                                margin-bottom: 0;
                                margin-right: 7.75px;
                            }
                        }

                        h6 {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 28px;
                            color: #000000;
                            margin: 8px 0;
                        }
                    }

                    .quiz_body {
                        p {
                            font-size: 18px;
                            line-height: 26px;
                            letter-spacing: 0.02em;
                            color: rgba(0, 0, 0, 0.8);
                        }

                        ol {
                            li {
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0.02em;
                                padding: 15px 12px 15px 40px;
                                display: list-item;
                                list-style: upper-alpha;
                                list-style-position: inside;

                                &.selected {
                                    font-weight: 500;
                                }
                            }
                        }
                    }

                    .attempts_wrap {
                        .summary_content {
                            margin: 25px 0 0;

                            .content_left {
                                h6 {
                                    margin-bottom: 6px;
                                }

                                .mobile-viewfeedback {
                                    display: none;
                                    @include extra-small-mobile {
                                        display: block;
                                        margin-top: 10px;
                                        margin-bottom: 20px;
                                    }
                                }
                            }

                            .content_right {
                                margin-bottom: 0;

                                .grade {
                                    > div {
                                        margin-top: -10px;
                                        @include extra-small-mobile {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .quiz_feedback_btn {
                margin: -10px auto 40px 0;
                display: flex;
                justify-content: space-between;
                width: 100%;
                @include desktop {
                    margin-top: 0;
                }

                > button {
                    border-radius: 6px;
                }
            }

            .feedback {
                border: none;
                box-shadow: none;
                background: #FAFAFA;
                @include extra-small-mobile {
                    margin-left: -35px;
                    margin-right: -35px;
                    padding-left: 40px;
                    padding-right: 54px;
                }

                .feedback_wrapper {
                    padding: 0;
                    margin: 24px auto 24px 227px;
                    @include desktop {
                        margin-left: 61px;
                    }
                    @include tablet {
                        margin-left: 53px;
                    }
                    @include mobile {
                        margin-left: 32px;
                    }
                    @include extra-small-mobile {
                        margin-left: 8px;
                    }

                    .quiz_wrap_inner {
                        margin: 30px auto 48px;
                    }

                    .feedback_msg {
                        margin-right: 18px;
                        @include tablet {
                            margin: 0 24px 40px 0;
                        }
                        @include extra-small-mobile {
                            margin: 0 24px 40px 0;
                        }

                        p {
                            padding-right: 46px;
                            @include mobile {
                                padding-right: 31px;
                            }
                        }
                    }

                    .quiz_btn {
                        @include tablet {
                            margin-left: 30px;
                        }
                        @include extra-small-mobile {
                            margin-left: 0px;
                        }

                        a {
                            button {
                                border: none;
                                outline: none;
                                box-shadow: none;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                text-align: center;
                                letter-spacing: 0.02em;
                            }

                            &:first-child {
                                button.link {
                                    color: rgba(0, 0, 0, 0.85);

                                    @include _tablet {
                                        padding-left: 0;
                                    }

                                    &:hover {
                                        color: rgba(0, 0, 0, 0.85);
                                    }

                                    &::after {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /*** Module Video page CSS ***/
        .module_page {
            padding: 0 17px;
            @include desktop {
                padding: 0 5px;
            }
            @include tablet {
                padding: 0 7px;
            }

            .module_header {
                display: flex;
                justify-content: space-between;
                @include tablet {
                    display: block;

                    .bread_wrap {
                        margin-bottom: 24px;
                    }
                }

                .quiz_btn_wrap {
                    @include tablet {
                        margin-bottom: 24px;
                    }

                    .video-sgstn {
                        display: none;
                    }

                    button {
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        letter-spacing: 0.02em;
                    }

                    @include mobile {
                        margin-bottom: 0;

                        button {
                            margin-bottom: 15px;
                        }

                        .video-sgstn {
                            display: block;
                            font-size: 12px;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .module_content {
                .ant-spin {
                    margin-top: 30px;
                }

                .module_video_frame {
                    .iframe {
                        box-shadow: 0 8px 22px -6px rgb(213 219 237 / 90%);
                        border-radius: 5px;
                        margin-top: 25px;
                        height: calc(100vh - 277px);
                        width: 100%;
                        border: none;

                        @include tablet {
                            height: calc(100vh - 341px);
                        }
                        @include extra-small-mobile {
                            height: calc(100vh - 383px);
                        }
                    }

                    .play-video-area {
                        height: 350px;
                        width: 100%;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include extra-small-mobile {
                            height: 258px;
                        }

                        .play-video {
                            border: none;
                            outline: none;
                            box-shadow: none;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            letter-spacing: .02em;
                            padding: 5px 16px;
                            width: 221px;
                            height: 34px;
                            background: var(--primary-color);
                            border-radius: 2px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

/*** Settings page Modal CSS ***/
.ant-modal-root {
    .modal_wrap {
        padding: 30px 16px;
        @include extra-small-mobile {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        h5 {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.02em;
            margin-bottom: 16px;
        }

        p.warning_txt {
            font-size: 16px;
            line-height: 24px;
            color: #DA291C;
            margin-bottom: 16px;
        }

        .btn_wrap {
            button {
                padding: 5px 16px;
            }
        }
    }

    .profile_modal {
        width: 750px;
        @include mobile {
            width: 630px !important;
        }
        @include extra-small-mobile {
            width: 469px !important;
        }

        .profile_modal_wrap {
            padding: 0 0 11px 0;
            @include extra-small-mobile {
                padding: 0 22px 11px 22px;
            }

            .avatar_wrap {
                margin-bottom: 24px;
            }

            .form_wrap {
                @include extra-small-mobile {
                    margin: auto;
                }

                .ant-form-item {
                    margin-bottom: 20px;

                    .ant-form-item-label {
                        label {
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.02em;
                            color: #000000;
                            margin-bottom: 0;
                            height: 100%;

                            &::after {
                                margin-right: 16px;
                            }
                        }
                    }

                    &:last-child {
                        .ant-form-item-control {
                            @include extra-small-mobile {
                                margin-left: 0;
                            }

                            .ant-form-item-control-input-content {
                                span {
                                    width: 100%;

                                    button {
                                        margin-top: 0;
                                        width: 100%;
                                    }
                                }
                            }

                            input {
                                padding: 15px 12px;
                                height: 54px;
                            }
                        }
                    }
                }
            }
        }
    }
}
