#root {
    .fc-blockage-page {
        background: #EBEFF1;
        font-family: Inter, sans-serif;
        min-height: 100vh;
        display: flex;
        flex-direction: column;

        .fc-err-content {
            background: url('../../assets/images/blocker-page-bg-1.svg') left top no-repeat, url('../../assets/images/blocker-page-bg-2.svg') right bottom no-repeat;
            flex-grow: 1;

            @include desktop {
                background: none;
            }

            .fc-err-container {
                padding-top: 84px;

                .fc-err-logo {
                    text-align: center;

                    img {
                        min-width: 400px;
                        height: auto;
                    }
                }

                h1 {
                    text-align: center;
                    font: normal normal bold 100px/121px Inter;
                    letter-spacing: -2.5px;
                    color: #000000;
                }

                .fc-err-body {
                    margin: 1.5rem 2rem;
                    text-align: center;
                    font-family: Inter, sans-serif;
                    color: #000000;
                    text-align: center;

                    h4 {
                        font-size: 48px;
                        letter-spacing: -1.2px;
                    }

                    p {
                        font-family: Inter, sans-serif;
                        font-size: 22px;
                        letter-spacing: -0.22px;
                        margin: 30px 0 30px 0;
                    }

                    .fc-err-btns {
                        justify-content: center;

                        .ant-btn {
                            font-family: Inter, sans-serif;
                            font-size: 22px;
                            min-width: 260px;
                            min-height: 60px;
                            border-radius: 2px;
                            border: 1px solid #464646;
                            outline: #464646;

                            &:hover {
                                box-shadow: 2px 3px 4px #464646;
                                color: #464646;
                            }

                            &.btn-primary {
                                background-color: #464646;

                                &:hover {
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ant-layout-footer.fc-err-footer {
            background-color: transparent;

            > img {
                width: 109px;
            }
        }
    }
}
