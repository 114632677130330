.program_creation_wrap {
    border-left: 3px solid #1890FF;
    display: flex;
    flex-direction: column;
    padding: 0 20px 10px;
    margin-left: -20px;
    margin-bottom: 20px;

    > .ant-space-item {
        width: 100%;

        .ant-input {
            height: 32px;
        }
    }
}

.ant-modal-content {
    background: #FFFFFF;
    box-shadow: 0px 1px 120px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    padding: 25px 10px;
}

.campaign-create-modal {
    .ant-modal-content {
        .ant-modal-header {
            .ant-modal-title {
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 35px;
                color: #303134;
                box-shadow: none;
                padding: 30px 0 20px 50px;
            }
        }

        .campaign-modal-inner {
            padding-left: 26px;

            .ant-form-item {
                label {
                    font-family: 'Roboto', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #717579;
                }

                .ant-picker {
                    .anticon-calendar {
                        color: #1890FF;
                    }
                }

                .ant-input, .ant-select-selector, .ant-picker {
                    min-height: 40px;
                    border: 1px solid #D7D7D7;
                    padding: 4px 23px;
                    border-radius: $general_btn_radius;
                }
            }
        }

        .ant-modal-footer {
            text-align: center;

            button.ant-btn-primary {
                margin-right: auto;
            }
        }
    }

    .ant-modal-title {
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        color: #303134;
    }

    .ant-modal-header, .ant-modal-footer {
        border: none;
    }

    .ant-form-item-label label {
        color: #343535;
    }

    .ant-picker {
        width: 100%;
    }

    .program_date_wrap {
        justify-content: space-between;

        .ant-space-item {
            flex-basis: 300px;

            .ant-picker-input {
                .ant-picker-suffix {
                    color: #1890FF;
                }
            }
        }
    }

    .program_creation_wrap {
        .ant-form-item-label label {
            color: #1890FF;
        }
    }

    .campaign-modal-inner {
        .ant-select-selector {
            .ant-select-arrow {
                width: 15px;
                height: 24px;
                top: 39%;

                .anticon-right {
                    width: 15px;
                    height: 24px;
                    color: rgba(0, 0, 0, 0.45);
                }
            }
        }

        .ant-form-item-margin-offset {
            margin-bottom: 0;
        }

        .ant-form-item-with-help.ant-form-item-has-error {
            margin-bottom: 20px;
        }
    }
}

.ant-modal-footer {
    text-align: center;

    .ant-btn-primary {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        height: auto;
        padding: 11px;
        border: 1px solid var(--button-color, #1890FF);
        background: var(--button-color, #1890FF);
        color: #fff;

        &:hover {
            color: var(--button-color, #1890FF);
            border: 1px solid var(--button-color, #1890FF);
            background: #fff;
        }
    }
}
