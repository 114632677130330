.contract-dash {
    .sub-section-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin: 20px 0;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .summary-content {
        color: #575757;
        display: flex;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 11px;

        @media (min-width: 1083px) {
            display: inline-flex; // tour to highlight specific panel
        }

        > div {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.06);
            border-radius: 2px;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.06);
            box-sizing: border-box;
            margin-right: 24px;
            padding: 24px;
            width: 278px;

            &.active {
                .top-divider {
                    color: #a9d3ab !important;
                }

                .focus-text {
                    .text-icon {
                        color: #a9d3ab;
                    }

                    .main-text {
                        color: #000000;
                    }
                }
            }

            .top-divider {
                border-top: 7px solid;
                border-radius: 10px;
                display: block;
                width: 100%;
                margin-bottom: 5px;
            }

            .focus-text {
                display: flex;
                align-items: center;
                padding: 16px 0;

                .text-icon {
                    font-size: 22px;
                }

                .main-text {
                    margin-left: 18px;
                    font-size: 31px;
                    line-height: 39px;
                    color: #000000;
                    font-weight: 500;
                }

                &.inactive {
                    .text-icon {
                        color: #bebebe;
                    }

                    .main-text {
                        color: #b2b2b2;
                    }
                }
            }

            .sub-text {
                > span {
                    display: block;
                }
            }

            &.subscription {
                .top-divider,
                .text-icon {
                    color: #9dc2ff;
                }
            }

            &.users {
                .top-divider,
                .text-icon {
                    color: #ffdc99;
                }

                .text-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffdc99;
                    color: #ffffff;
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                }
            }

            &.add-ons {
                .top-divider,
                .text-icon {
                    color: #bebebe;
                }

                .add-on-text-container {
                    margin-top: 10px;

                    .focus-text {
                        padding: 0;

                        .main-text {
                            color: #b2b2b2;
                            font-size: 24px;
                            line-height: 38px;
                            margin-left: 16px;
                        }
                    }
                }
            }
        }
    }

    .table-content {
        font-size: 14px;
        line-height: 22px;

        .contract_status {
            white-space: nowrap;

            .ant-badge-status-dot {
                width: 12px;
                height: 12px;
                margin-right: 5px;
            }
        }
    }
}
