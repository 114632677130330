.empty_data_wrap {
    padding: 24px 43px;
    display: flex;
    border: 1px solid #EFEFEF;
    align-items: center;

    .right_part {
        flex: 1;
    }

    .left_icon {
        background: url("/assets/images/program/banner.png") no-repeat;
        background-position: center;
        display: inline-block;
        width: 120px;
        height: 120px;
        margin-right: 46px;
    }
}