.reports-page {
    ::placeholder, .ant-select-selection-placeholder {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #949597;
    }

    .ant-breadcrumb {
        margin-bottom: 8px;

        .ant-breadcrumb-link a:hover {
            background-color: transparent;
        }
    }

    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .report-download-btn {
            margin-left: 30px;
        }
    }

    .reports_panel {
        margin: 0;
        padding: 0;

        .report-input-search {
            border-radius: 6px;
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.06);
            box-sizing: border-box;
            margin-bottom: 16px;
            padding: 16px 32px 16px 16px;

            .ant-input-search {
                max-width: 423px;
            }

            .ant-input-search-button {
                display: flex;
            }

            .anticon-search {
                font-size: 18px;
                color: var(--primary-color);
            }
        }

        .reporttype-list {
            .reporttype-items {
                li {
                    margin-bottom: 16px;

                    .reporttype-item {
                        min-height: 218px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 20px 16px;
                        border-radius: 6px;
                        background: #fff;
                        border: 1px solid rgba(0, 0, 0, 0.06);
                        box-sizing: border-box;
                        box-shadow: 5px 5px 20px rgb(0 0 0 / 6%);
                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        letter-spacing: 0.02em;
                        color: #313232;

                        h5 {
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 150%;
                        }

                        p {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 24px;
                        }

                        a.disabled {
                            color: rgba(0, 0, 0, .25);
                            text-shadow: none;
                            box-shadow: none;
                            pointer-events: none;

                            button {
                                color: #949597;
                                background: #F5F5F5;
                            }
                        }

                        .reports-create-btn {
                            width: 98px;
                            text-align: left;
                            justify-content: space-between;
                            font-family: 'Inter', sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1;
                            letter-spacing: 0.02em;
                            height: $general_admin_sm_btn_height;

                            &::after {
                                content: "\203A";
                                font-size: 24px;
                                right: 0.5em;
                                position: absolute;
                                line-height: 1;
                                top: 50%;
                                margin-top: -0.5em;
                                transition: transform .6s cubic-bezier(.19, 1, .22, 1);
                            }

                            &:hover {

                            }
                        }

                        .reports-create-btn-subtext {
                            font-size: 14px;
                            padding-top: 12px;
                        }
                    }
                }
            }
        }

        .report {
            .reports-filter {
                border-radius: 6px;
                background: #fff;
                box-sizing: border-box;
                margin: 16px 0 0;
                padding: 16px;

                .ant-space {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }

                .ant-form-item {
                    margin-bottom: 0;

                    label {
                        margin-bottom: 0;
                    }
                }

                .ant-input-search {
                    max-width: 423px;
                }
            }

            #report_table_main {
                margin-top: 16px;

                .ant-table {
                    min-height: calc(50vh - 4em);
                }

                .reports-table-content {
                    .ant-table {
                        .ant-table-tbody {
                            tr > td.ant-table-cell:nth-child(2) {
                                color: var(--primary-color);
                            }

                            .expand-icon, .collapse-icon {
                                color: #B3B3B3;
                            }
                        }

                        tbody > tr > td:first-of-type {
                            padding-right: 0;
                            padding-top: 12px;
                        }
                    }

                    .reports-table-action {
                        button {
                            padding: 0;
                            color: var(--primary-color);
                        }
                    }

                    .ant-table-expanded-row {
                        .report-details {
                            margin-left: 3em;

                            > .report-detail-item {
                                padding: 5px;
                                display: flex;

                                &.strong {
                                    font-weight: 700;
                                }

                                > span {
                                    font-family: 'Roboto', sans-serif;
                                    font-style: normal;
                                    font-size: 14px;
                                    line-height: 22px;
                                    letter-spacing: 0.02em;
                                    color: #0D2138;

                                    &:first-child {
                                        min-width: 90px;
                                    }
                                }

                                &.audience-list {
                                    div {
                                        max-width: 730px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #create_report_table_main, #update_report_table_main {
                margin-top: 16px;

                .empty_data_wrap {
                    margin-bottom: 24px;
                }

                .ant-table-content {
                    tbody {
                        tr {
                            td {
                                max-width: 400px;
                            }
                        }
                    }
                }
            }

            .schedule-reports-form {
                > .ant-space {
                    gap: 2px !important;
                }

                .ant-space-item .ant-form-item {
                    margin-bottom: 15px;
                }

                .d-flex {
                    display: flex;
                    align-items: center;
                }

                .audience-form-item {
                    max-width: 720px;
                    align-items: flex-end;

                    .audience-select {
                        .ant-select-selector {
                            min-height: 44px;
                        }
                    }
                }

                label {
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                    color: #000000;

                    &[for*="form_enable_schedule"] {
                        font-size: 24px;
                        line-height: 150%;
                        color: #313232;
                    }
                }

                .default-email {
                    display: flex;
                    margin-bottom: 15px;
                    color: var(--primary-color);
                }

                .set-schedule-date {
                    .ant-form-item {
                        width: 50%;

                        &:first-child {
                            margin-right: 22px;
                        }

                        .ant-form-item-explain-error {
                            white-space: nowrap;
                        }

                        .ant-picker {
                            width: 100%;

                            .anticon-calendar {
                                color: #1890ff;
                            }
                        }
                    }
                }

                .report-alert-msg {
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.8);
                    display: flex;
                    align-items: center;

                    .anticon-exclamation-circle {
                        margin-right: 8px;
                    }
                }

                .ant-radio-button-wrapper {
                    font-weight: 400;

                    &.ant-radio-button-wrapper-checked {
                        border: 1px solid var(--primary-color);
                        color: var(--primary-color);

                        &:not(.ant-radio-button-wrapper-disabled)::before {
                            background-color: var(--primary-color);
                            width: 0;
                        }
                    }
                }

                .submit-btns {
                    margin-top: 32px;

                    button {
                        margin-right: 16px;
                        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}

.reports-email-template {
    .ant-modal-body {
        padding: 0;
    }
}

.reports-tooltip {
    text-align: center;
    width: 200px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
}

.reports-download-dropdown {
    .ant-dropdown-menu {
        min-width: 160px;
    }
}
