.multicolor-bar {
    margin: 0;
    font-size: 12px;

    .values {
        .value {
            float: left;
            text-align: center;
            line-height: 14px;
        }
    }

    .scale {
        .graduation {
            float: left;
            text-align: center;
        }
    }

    .legends {
        text-align: center;

        .legend {
            display: inline-block;
            margin: 0 5px;
            text-align: center;

            .dot {
                font-size: 25px;
                vertical-align: middle;
            }

            .label {
                margin-left: 2px;
                vertical-align: middle;
            }
        }
    }

    .bars {
        &.sub_table {
            width: 94%;

            .module-data {
                &.completed {
                    .bar {
                        flex: 2 1;
                    }

                    .completed-value {
                        position: absolute;
                        right: 0;
                        line-height: 0.5;
                    }
                }

                &:first-of-type {
                    .bar {
                        border-bottom-left-radius: 6px;
                        border-top-left-radius: 6px;
                    }
                }

                &:last-of-type {
                    .bar {
                        border-bottom-right-radius: 6px;
                        border-top-right-radius: 6px;
                    }
                }
            }

            &.no_completed_users {
                display: flex;
                align-items: center;

                .module-data {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }

                .completed-value {
                    position: absolute;
                    right: 0;
                }
            }

            .bar {
                float: left;
                height: 8px;
            }
        }
    }

    &.main_table {
        .bars {
            .bar {
                float: left;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    white-space: nowrap;
                }

                &.fnt-wht {
                    span {
                        color: rgba(255, 255, 255, 0.9);
                    }
                }

                &.fnt-blk {
                    span {
                        color: rgba(0, 0, 0, 0.9);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .multicolor-bar {
        display: grid;
    }
}

.bar-tooltip {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    > span {
        width: 15px;
        height: 10px;
        border-radius: 2px;
    }
}
